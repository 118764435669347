<template>
<span>

	<component
		v-if="isComponentDisplay"
		:is="displayResult.component"
		v-bind="displayResult.props"
	/>
	<template v-else>

		<!-- Render cell that is actively being edited -->
		<span
			v-if="inlineSelect && column.editable && row === activeRow && column === selectedColumn"
			:style="getCellStyles( column )"
			@keydown.stop.exact.enter="$emit( 'selectRowByIndex', rowIndex + 1 )"
			@keydown.stop.shift.enter="$emit( 'selectRowByIndex', rowIndex - 1 )"
			@keydown.stop.prevent.exact.tab="$emit( 'selectColumnByIndex', colIndex, colIndex + 1, rowIndex )"
			@keydown.stop.prevent.shift.tab="$emit( 'selectColumnByIndex', colIndex, colIndex - 1, rowIndex )"
			ref="inlineEditCell"
		>
			<input v-if="column.displayFunction" type="text" :value="column.editDisplayFunction ? column.editDisplayFunction( row ) : column.displayFunction( row )" @click.stop @input="ev => debounceInlineEditField( row, column.propertyName, ev.target.value )" />
			<span v-else-if="row[ column.propertyName ] === true" class="icon-checkmark message-success" @click="inlineEditField( row, column.propertyName, false )" />
			<span v-else-if="row[ column.propertyName ] === false" class="icon-cross" style="color:#aaa" @click="inlineEditField( row, column.propertyName, true )" />
			<input v-else :value="row[ column.propertyName ]" @click.stop @input="ev => debounceInlineEditField( row, column.propertyName, ev.target.value )" />
		</span>

		<!-- Render cell -->
		<div v-else-if="column.displayFunction" :style="getCellStyles( column )" v-html="column.displayFunction( row )" :class="{'hideInactive': column.hideInactive, 'tableLink' : column.link}" />
		<span v-else :style="getCellStyles( column )">
			<span v-if='row[column.propertyName]===true' class='icon-checkmark message-success'></span>
			<span v-else-if='row[column.propertyName]===false' class='icon-cross' style='color:#aaa'></span>
			<div v-else v-html="row[column.propertyName]" />
		</span>
	</template>

</span>	
</template>



<script>
import JobControlTableWidget from '@/features/JobControl/JobControlTableWidget.vue'

export default {
	name: 'ObjectTableCell',


	components: {
		JobControlTableWidget,
	},


	props: {
		rowIndex: Number,
		colIndex: Number,

		row: {
			type: Object,
			required: true
		},

		column: {
			type: Object,
			required: true
		},

		activeRow: {
			type: Object,
			default: null
		},

		selectedColumn: {
			type: Object,
			default: null
		},

		inlineSelect: {
			type: Boolean,
			default: false
		},

		activeEditCell: {
			type: Boolean,
			default: false
		},

	},



	computed: {
		
		displayResult() {
			if( this.column.displayFunction != undefined ) {
				return this.column.displayFunction( this.row )
			}

			return this.row[ this.column.propertyName ]
		},


		isComponentDisplay() {
			return (
				this.displayResult != null &&
				typeof this.displayResult === 'object' &&
				this.displayResult.component
			);
		},
		
	},



	watch: {
		async activeEditCell( value ) {
			if( !value ) return
			await this.$nextTick()
			this.$refs.inlineEditCell.firstChild.focus()
		},
	},



	methods: {

		getCellStyles( column ) {
			const styles = {}

			if( column.maxHeight ) {
				styles.maxHeight = column.maxHeight
				styles.display = 'block'
			}
			if( column.maxWidth ) {
				styles.maxWidth = column.maxWidth
				styles.display = 'block'
			}
			if( column.scroll ) styles.overflow = 'auto'
			return styles
		},


		debounceInlineEditField( item, propertyName, newValue ) {
			if( this.inlineEditTimeoutID != null ) clearTimeout( this.inlineEditTimeoutID )
			this.inlineEditTimeoutID = setTimeout( () => { this.inlineEditField( item, propertyName, newValue ) }, 800)
		},
		
		inlineEditField( item, propertyName, newValue ) {
			this.$emit( 'inlineEdit', item, propertyName, newValue )
		},

	}

}
</script>



<style scoped>

</style>