<template>
<section>

	<div v-if="userID == null || affiliateIn == null || customerID == null" class="flex-row flex-gap-1 flex-justify-between flex-align-center mt-05">

		<SearchBox @search="str => searchString = str" :placeholder="searchPlaceholder" :styles="searchStyles" @focus="searchFocused = true" @blur="searchFocused = false" />
		
		<UserSearchDropdown
			v-if="userID == null"
			placeholder="Filter by User..."
			width="200px"
			@updateUser="user => selectUser(user)" />

		<CustomerSearchDropdown
			v-if="userID == null && affiliateIn == null && customerID == null"
			placeholder="Filter by Customer..."
			width="200px"
			@updateCustomer="cust => selectCustomer(cust)" />

		<AffiliateSearchDropdown
			v-if="userID == null && affiliateIn == null && customerID == null"
			placeholder="Filter by Affiliate..."
			width="200px"
			@update="aff => selectAffiliate(aff)" />

		<ModalMessageSearchDropdown
			placeholder="Filter by Modal Message..."
			width="200px"
			@update="modal => selectModal(modal)" />

		<div>
			<GenericTagSearchDropdown tagType="review" @update="tag => addFilterTag( tag )" ref="filterTagSearch" />
			<TagField :Source="filterTags.tags" :topMargin="false" @remove="tag => filterTags.removeTag( tag )" />
		</div>


		<!-- FILTER: timestamp / date -->
		<div>
			<v-date-picker v-model="startDate" mode="dateTime">
				<template v-slot="{ inputValue, inputEvents }">
					<input
					placeholder="Start Date"
					:value="inputValue"
					v-on="inputEvents"
					style="width: 140px"
					/>
				</template>
			</v-date-picker> - 
			<v-date-picker v-model="endDate" mode="dateTime">
				<template v-slot="{ inputValue, inputEvents }">
					<input
					placeholder="End Date"
					:value="inputValue"
					v-on="inputEvents"
					style="width: 140px"
					/>
				</template>
			</v-date-picker>
		</div>

		<!-- FILTER: Total User Time-in-Program -->
		<div>
			<div class="bold center">Time in Program:</div>
			<div class="flex-row flex-gap-05">
				<div class="nowrap"><input type="number" v-model.number="minTime" @change="setMinTime()" min="0" style="width: 5em" />&nbsp;hrs</div>
				<div>-</div>
				<div class="nowrap"><input type="number" v-model.number="maxTime" @change="setMaxTime()" min="0" style="width: 5em" />&nbsp;hrs</div>
			</div>
		</div>

		<div class="ml-2 center">
			<button class="pillButton one-line" @click="openPublicationSettings()"><span class="icon-cog" /> Settings</button>
			<div><a :href="commentsPageURL" target="_blank" class="one-line">comments page</a></div>
		</div>

	</div>

	<div class="center mt-1">{{ count ? count.toLocaleString() : count }} reviews</div>
	<div v-if="summary" class="center mt-05 bold" style="font-size: 1.5em;">
		Average: {{ Math.round((summary.star1 + summary.star2 * 2 + summary.star3 * 3 + summary.star4 * 4 + summary.star5 * 5) *100 / summary.stars) / 100 }}
	</div>
	<section class="my-1 flex-row flex-justify-center">
		<div v-if="summary" class="summaryWidget pa-1 flex-row flex-justify-between border round-05" style="width: 27em;">

			<!-- Stars -->
			<div class="mr-05" style="text-align: right;">
				<div class="nowrap">
					<span class="icon-star-full" />
					<span class="icon-star-full" />
					<span class="icon-star-full" />
					<span class="icon-star-full" />
					<span class="icon-star-full" />
				</div>
				<div class="nowrap">
					<span class="icon-star-full" />
					<span class="icon-star-full" />
					<span class="icon-star-full" />
					<span class="icon-star-full" />
				</div>
				<div class="nowrap">
					<span class="icon-star-full" />
					<span class="icon-star-full" />
					<span class="icon-star-full" />
				</div>
				<div class="nowrap">
					<span class="icon-star-full" />
					<span class="icon-star-full" />
				</div>
				<div class="nowrap">
					<span class="icon-star-full" />
				</div>
				<div v-if="summary.total - summary.stars > 0" class="nowrap">No rating</div>
			</div>

			<!-- Fractions -->
			<div class="mr-05" style="text-align:right;">
				<div class="nowrap">{{ starBarWidth(summary.star5, summary.stars) }} </div>
				<div class="nowrap">{{ starBarWidth(summary.star4, summary.stars) }} </div>
				<div class="nowrap">{{ starBarWidth(summary.star3, summary.stars) }} </div>
				<div class="nowrap">{{ starBarWidth(summary.star2, summary.stars) }} </div>
				<div class="nowrap">{{ starBarWidth(summary.star1, summary.stars) }} </div>
				<div v-if="summary.total - summary.stars > 0" class="nowrap">{{ starBarWidth(summary.total - summary.stars, summary.total) }} </div>
			</div>

			<!-- Bars -->
			<div class="mr-05">
				<div class="progBar"><div class="prog" :style="{width: starBarWidth(summary.star5, summary.stars)}" /></div>
				<div class="progBar"><div class="prog" :style="{width: starBarWidth(summary.star4, summary.stars)}" /></div>
				<div class="progBar"><div class="prog" :style="{width: starBarWidth(summary.star3, summary.stars)}" /></div>
				<div class="progBar"><div class="prog" :style="{width: starBarWidth(summary.star2, summary.stars)}" /></div>
				<div class="progBar"><div class="prog" :style="{width: starBarWidth(summary.star1, summary.stars)}" /></div>
			</div>

			<div class="mr-05" style="font-size: 0.8rem;">
				<div class="nowrap" style="margin: 0.17rem 0;">({{ summary.star5.toLocaleString() }} / {{ summary.stars.toLocaleString() }})</div>
				<div class="nowrap" style="margin: 0.17rem 0;">({{ summary.star4.toLocaleString() }} / {{ summary.stars.toLocaleString() }})</div>
				<div class="nowrap" style="margin: 0.17rem 0;">({{ summary.star3.toLocaleString() }} / {{ summary.stars.toLocaleString() }})</div>
				<div class="nowrap" style="margin: 0.17rem 0;">({{ summary.star2.toLocaleString() }} / {{ summary.stars.toLocaleString() }})</div>
				<div class="nowrap" style="margin: 0.17rem 0;">({{ summary.star1.toLocaleString() }} / {{ summary.stars.toLocaleString() }})</div>
				<div v-if="summary.total - summary.stars > 0" class="nowrap" style="margin: 0.17rem 0;">({{ (summary.total - summary.stars).toLocaleString() }} / {{ summary.total.toLocaleString() }})</div>
			</div>
			
		</div>
	</section>


	<div class="flex-row flex-justify-between flex-align-center">
		<MultiselectTools :numSelected="selectedReviews.length" :showDelete="true" @all="$refs.objectTable.selectAll()" @none="$refs.objectTable.selectNone()" @delete="$refs.deleteSelectedModal.open()">
			<span>Edit {{ selectedReviews.length }} Reviews:</span>
				<span class="link" @click="$refs.bulkAssignTagsModal.open()">Assign Tags</span>
				<div class="flex-row flex-align-center flex-gap-05">
					<span class="link" @click="publishSelected( true )">Publish</span> /
					<span class="link" @click="publishSelected( false )">Unpublish</span>
				</div>
				<div class="flex-row flex-align-center flex-gap-05">
					<span class="link" @click="featureSelected( true )">Featured</span> /
					<span class="link" @click="featureSelected( false )">Not Featured</span>
				</div>
				<div class="flex-row flex-align-center flex-gap-05">
					<span class="link" @click="superSelected( true )">Super</span> /
					<span class="link" @click="superSelected( false )">Not Super</span>
				</div>
				
				<div class="flex-row flex-align-center flex-gap-05">
					Rank: <input type="number" style="width: 4em;" v-model="multiRank" /><button class="pillButton small" @click="rankSelected()">set</button>
				</div>

				<div class="flex-row flex-align-center flex-gap-05">
					Category:
					<select v-model="multiCategory">
						<option value="program">Program</option>
						<option value="project">Project</option>
					</select>  
					<button class="pillButton small" @click="categorizeSelected()">set</button>
				</div>
		</MultiselectTools>

		<div class="flex-row flex-justify-center flex-gap-2">
			<div>
				<label>Per page: </label>
				<select v-model="pageSize">
					<option :value='25'>25</option>
					<option :value='50'>50</option>
					<option :value='100'>100</option>
					<option :value='250'>250</option>
					<option :value='500'>500</option>
				</select>
			</div>
			<span class="right">{{ count.toLocaleString() }} reviews on {{ pages }} pages</span>

			<div class="flex-row flex-gap-05">
				<span v-if="!csvPageBusy" class="icon-file-excel font-size-1-5 color-blue-40" @click="csvDownload( false )" @mouseenter="t.s($event)" @mouseleave="t.h($event)" :tooltip="`Download CSV of this page (${ reviews.length.toLocaleString() } reviews)`" />
				<span v-else class="font-size-1-5 icon-spinner4 spin1" />
				<span v-if="!csvAllBusy" class="icon-file-excel font-size-1-5 color-red-40" @click="csvDownload( true )" @mouseenter="t.s($event)" @mouseleave="t.h($event)" :tooltip="`Download CSV of all ${count.toLocaleString()} reviews`" />
				<span v-else class="font-size-1-5 icon-spinner4 spin1" />
			</div>

		</div>
	</div>

	<section v-if="!loading">
		<ObjectTable
			:Source="reviews"
			:Columns='columns'
			:SortBy='sortBy'
			:SortAsc='sortAsc'
			:multisort="true"
			:Numbered="true"
			:MultiSelect="true"
			:PageNum="page"
			:PageSize="pageSize"
			@edit="item => select(item)"
			@sort="prop => selectSortBy( prop )"
			@unsort="prop => unsortBy( prop )"
			@selectedItems="list => setSelectedReviews( list )"
			ref="objectTable"
		/>
		<Paginator v-model="page" @input="initialize()" :numPages="pages" />
	</section>


	<div v-if="!loading && !error && !reviews.length" class="NoResults">No Reviews</div>

	<section v-if='loading' class='flex justify-center'>
		<div class='icon-spinner4 spin-loader'></div>
	</section>

	<section v-if="error" class="warning">
		Failed to get records
	</section>


	<StretchModal ref="settingsModal" width="800px" padding="2em">
		<template v-if="affiliateIn" #header>{{ affiliateIn.name }} Comments Page Settings</template>
		<template v-else #header>Publish Tags</template>

		<!-- Single-affiliate mode -->
		<div v-if="affiliateIn">
			
			<div class="mt-1">
				<span class="bold">"{{ affiliateIn.name }}" Comments Settings:</span> &nbsp;&nbsp;
				<input type="checkbox" :checked="specificAffCommentsDefaults !== null" @change="setCommentTagSettingsOverride( affiliate.urlSlug, null, $event.target.checked )" /> Override default
			</div>
			<ReviewsPageSettingsWidget v-if="specificAffCommentsDefaults" :label="`${affiliateIn.name} Comments`" :settings="specificAffCommentsDefaults" @update="(showDate, showAddress) => saveReviewsPageSettings( affiliate.urlSlug, null, showDate, showAddress )" />
			
			<div class="mt-1">
				<span class="bold">"{{ affiliateIn.name }} + Any Tag" Comments Settings:</span> &nbsp;&nbsp;
				<input type="checkbox" :checked="specificAffTaggedCommentsDefaults !== null" @change="setCommentTagSettingsOverride( affiliate.urlSlug, true, $event.target.checked )" /> Override default
			</div>
			<ReviewsPageSettingsWidget v-if="specificAffTaggedCommentsDefaults" :label="`${affiliateIn.name} + Tagged Comments`" :settings="specificAffTaggedCommentsDefaults" @update="(showDate, showAddress) => saveReviewsPageSettings( affiliate.urlSlug, true, showDate, showAddress )" />
			
			<div class="mt-2 border-top" />

			<div class="mt-1 bold">"{{ affiliateIn.name }} + ________ Tag" Overrides:</div>
			
			<template v-for="tag in reviewTagSettings">
				<div v-if="tag.publish" :key="tag.tag" class="mt-1 pa-05 border round-05" style="max-width: 700px;">
					<div><b>{{ tag.publicLabel || tag.tag }}</b></div>
					<div v-if="tag.publish"><input type="checkbox" :checked="affTaggedCommentSettings[ tag.tag ] !== undefined" @change="setCommentTagSettingsOverride( affiliate.urlSlug, tag.tag, $event.target.checked )" /> Override display settings</div>
					<ReviewsPageSettingsWidget v-if="tag.publish && affTaggedCommentSettings[ tag.tag ]" :label="tag.tag" :settings="affTaggedCommentSettings[ tag.tag ]" @update="(showDate, showAddress) => saveReviewsPageSettings( affiliate.urlSlug, tag.tag, showDate, showAddress )" class="ml-4 mt-05 mb-1 font-size-0-9" />
				</div>
			</template>
		</div>

		<!-- Global mode -->
		<div v-if="!affiliateIn">
			<div class="bold">All Comments Settings:</div>
			<ReviewsPageSettingsWidget label="All Comments" :settings="allCommentsDefaults" @update="(showDate, showAddress) => saveReviewsPageSettings( null, null, showDate, showAddress )" />
			
			<div class="bold mt-1">Affiliate Comments Settings:</div>
			<ReviewsPageSettingsWidget label="Affiliate Comments" :settings="affCommentsDefaults" @update="(showDate, showAddress) => saveReviewsPageSettings( true, null, showDate, showAddress )" />
			
			<div class="bold mt-1">Tagged Comments Settings:</div>
			<ReviewsPageSettingsWidget label="Tagged Comments" :settings="taggedCommentsDefaults" @update="(showDate, showAddress) => saveReviewsPageSettings( null, true, showDate, showAddress )" />

			<div class="bold mt-1">Affiliate + Tagged Comments Settings:</div>
			<ReviewsPageSettingsWidget label="Affiliate + Tagged Comments" :settings="affTaggedCommentsDefaults" @update="(showDate, showAddress) => saveReviewsPageSettings( true, true, showDate, showAddress )" />

			<div class="mt-2 border-top" />

			<div class="mt-2 bold">Publish Tags:</div>
			<div>Publish the following tags on public-facing "Comments" pages:</div>
			
			<div v-for="tag in reviewTagSettings" :key="tag.tag" class="mt-1 pa-05 border round-05" style="max-width: 700px;">
				<div class="mt-05 flex-row flex-align-center flex-gap-1">
					<div><ToggleButton v-model="tag.publish" :sync="true" @change="toggleTag( tag )" />&nbsp;<b>{{ tag.tag }}</b></div>
					<input v-if="tag.publish" type="text" class="flex-grow" v-model="tag.publicLabel" @input="updateTagLabel( tag )" />
				</div>
				<div v-if="tag.publish"><input type="checkbox" :checked="taggedCommentSettings[ tag.tag ] !== undefined" @change="setCommentTagSettingsOverride( null, tag.tag, $event.target.checked )" /> Override display settings</div>
				<ReviewsPageSettingsWidget v-if="tag.publish && taggedCommentSettings[ tag.tag ]" :label="tag.tag" :settings="taggedCommentSettings[ tag.tag ]" @update="(showDate, showAddress) => saveReviewsPageSettings( null, tag.tag, showDate, showAddress )" class="ml-4 mt-05 mb-1 font-size-0-9" />
			</div>


		</div>
	</StretchModal>


	<StretchModal ref="bulkAssignTagsModal" :width="null" padding="2em" @close="bulkTags = []">
		<template #header>Bulk-assign Tags</template>
		<div class="flex-column flex-justify-between" style="min-height: 25em;">
			<div>
				<TagField :Source="bulkTags" @remove="tag => removeBulkTag( tag )" />
				<GenericTagSearchDropdown ref="bulkTagDropdown" tagType="review" placeholder="Create or Search Review Tags" @update="tag => addBulkTag( tag )" />
				<div class="mt-05 color-gray-50 font-size-0-8">to create a new tag, type a tag name and press <code class="round-05 bg-gray-90" style="padding: 0.1em 0.25em;">enter</code></div>
			</div>

			<div>
				<div class="flex-row flex-align-center flex-justify-end mt-1 flex-gap-1">
					<span v-if="bulkTagsLoadingProgress !== false" class="color-gray-50"><span v-if="bulkTagsLoadingProgress !== false" class="icon-spinner4 spin1" />&nbsp;{{ bulkTagsLoadingProgress }} of {{ selectedReviews.length }}</span>
					<button class="pillButton secondary" :class="{ 'disabled' : bulkTagsLoadingProgress !== false || bulkTags.length == 0 }" :disabled="bulkTagsLoadingProgress !== false || bulkTags.length == 0" @click="tagSelected( true )">Remove</button>
					<button class="pillButton" :class="{ 'disabled' : bulkTagsLoadingProgress !== false || bulkTags.length == 0 }" :disabled="bulkTagsLoadingProgress !== false || bulkTags.length == 0" @click="tagSelected()">Assign</button>
				</div>
				<div v-if="bulkTagsError" class="warning mt-1">There was an error while saving tag assignments</div>
			</div>
		</div>
	</StretchModal>


	<StretchModal ref="detailsModal" @close="deselect()" :width="null" padding="3em">
		<template #header>Review #{{ selectedItem.reviewID }}</template>
		<ReviewDetails :review="selectedItem" />
	</StretchModal>

	<ConfirmDialog :confirm="`Delete Review${ selectedReviews.length != 1 ? 's' : ''}`" ref="deleteSelectedModal" @confirm="deleteSelected()">
		<div>Are you sure you want to delete {{ selectedReviews.length }} review{{ selectedReviews.length != 1 ? 's' : '' }}?</div>
		<div class="bold font-size-1-3">This action CAN NOT be undone!</div>
	</ConfirmDialog>

</section>
</template>


<script>
import ReviewDetails from './ReviewDetails.vue'
import UserSearchDropdown from '@/features/Users/UserSearchDropdown.vue'
import CustomerSearchDropdown from '@/features/SalesManagement/Customers/CustomerSearchDropdown.vue'
import AffiliateSearchDropdown from '@/features/SalesManagement/Affiliates/AffiliateSearchDropdown.vue'
import ModalMessageSearchDropdown from '@/features/ModalMessages/ModalMessageSearchDropdown.vue'
import SearchBox from '@/components/utilities/SearchBox.vue'
import ObjectTable from '@/components/utilities/ObjectTable.vue'
import Paginator from '@/components/utilities/Paginator.vue'
import StretchModal from '@/components/utilities/StretchModal.vue'
import ConfirmDialog from '@/components/utilities/ConfirmDialog.vue'
import TagField from '@/components/utilities/TagField.vue'
import GenericTagSearchDropdown from '@/components/utilities/GenericTagSearchDropdown.vue'
import ReviewsPageSettingsWidget from './ReviewsPageSettingsWidget.vue'
import MultiselectTools from '@/components/utilities/MultiselectTools.vue'
import { ToggleButton } from 'vue-js-toggle-button'

import ReviewsAPI from '@/api/ReviewsAPI.js'
import GenericTagsAPI from '@/api/GenericTagsAPI.js'
import PaginatedRequest from '@/api/PaginatedRequest.js'
import Review from '@/features/Reviews/Review.js'
import Tooltips from '@/libraries/Tooltips/Tooltips.js'
import { powerprep_base_url } from '@/Config.js'

export default {
	name: 'ReviewsDashboard',

	components: {
		ReviewDetails,
		UserSearchDropdown,
		CustomerSearchDropdown,
		AffiliateSearchDropdown,
		ModalMessageSearchDropdown,
		SearchBox,
		ObjectTable,
		Paginator,
		StretchModal,
		ConfirmDialog,
		TagField,
		GenericTagSearchDropdown,
		ReviewsPageSettingsWidget,
		MultiselectTools,
		ToggleButton,
	},

	props: {
		modalMessageID: {
			type: Number,
			default: null
		},
		userID: {
			type: Number,
			default: null
		},
		affiliateIn: {
			type: Object,
			default: null
		},
		customerID: {
			type: Number,
			default: null
		},
	},


	data() {
		const cols = [
			{
				displayName: "Author",
				propertyName: 'authorName',
				displayFunction: function( item ) {
					return `<div>${item.userID !== null ? '<span class="icon-user mr-05 color-blue-60"></span>' : ''}${item.authorName} ${item.userID ? '(#' + item.userID + ')' : ''}</div><div class="color-gray-50">${item.authorEmail || ''}</div>`
				},
				sortable: true
			},
			{
				displayName: 'Stars',
				propertyName: 'stars',
				displayFunction: function( item ) {
					const prompt = item.starPrompt && item.starPrompt.length > 20 ? item.starPrompt.substring(0,15) + ' ...' : item.starPrompt
					var str = `<div style="font-style: italic; font-size: 0.9em; max-width: 8em;">${prompt || ''}</div>`
					for(var i=0; i < item.stars; i++) str += '<span class="icon-star-full" style="color: orange"></span>'
					return str
				},
				itemTooltip: function( item ) { return item.starPrompt && item.starPrompt.length > 20 ? item.starPrompt : null },
				sortable: true
			},
			{
				displayName: 'Body',
				propertyName: 'body',
				displayFunction: function( item ) {
					var str = item.bodyPrompt ? `<div style="font-style: italic; font-size: 0.9em;">PROMPT: ${item.bodyPrompt || ''}</div>` : ''
					if( item.title ) str = '<div style="font-weight:bold;">' + item.title + '</div>'
					if( item.body ) str += `<div class="color-gray-30 bg-white pa-1 round-05">${ item.body }</div>`
					return str
				},
				sortable: true
			},
			{
				displayName: 'TIP <span class="icon-stopwatch" />',
				propertyName: 'minutesInProgram',
				displayFunction: function( item ) {
					return item.hoursInProgramString
				},
				sortable: true
			},
		]

		if( !this.$props.affiliateIn ) cols.push({
			displayName: 'Aff',
			propertyName: 'affiliates',
			displayFunction: function( item ) {
				const affNames = item.getAffiliateNames( 15 )
				return '<div>' + affNames.join( ',</div><div>' ) + '</div>'
			},
			itemTooltip: function( item ) {
				return item.getAffiliateNames().join( ",\n" )
			},
			sortable: true
		})

		if( !this.$props.customerID ) cols.push({
			displayName: 'Cust',
			propertyName: 'customerName',
			displayFunction: function( item ) {
				if( !item.customerName ) return item.customerID
				return item.customerName.length > 15 ? item.customerName.substring(0,10) + ' ...' : item.customerName
			},
			itemTooltip: function( item ) { return item.customerName && item.customerName.length > 15 ? item.customerName : null },
			sortable: true
		})

		cols.push({
			displayName: 'Lic #',
			propertyName: 'licenseKey',
			displayFunction: function( item ) {
				if( item.licenseKey ) return '<span class="icon-checkmark" style="color: var(--pp10-green);" />'
				return null
			},
			itemTooltip: function( item ) { return item.licenseKey || null },
			sortable: true
		})

		if( !this.$props.modalMessageID ) cols.push({
			displayName: 'Modal<br>Msg',
			propertyName: 'modalMessageName',
			displayFunction: function( item ) {
				if( item.modalMessageName === null ) return item.modalMessageID ? `Deleted -- #${item.modalMessageID}` : null
				return item.modalMessageName.length > 25 ? item.modalMessageName.substring(0,20) + ' ...' : item.modalMessageName
			},
			itemTooltip: function( item ) { return item.modalMessageName && item.modalMessageName.length > 25 ? item.modalMessageName : null },
			sortable: true
		})

		cols.push({
			displayName: 'Time',
			propertyName: 'timestamp',
			displayFunction: function( item ) { return item.timestamp ? item.timestamp.toLocaleString() : '' },
			sortable: true
		})

		cols.push(
			{
				displayName: 'Tags',
				propertyname: 'tags',
				displayFunction: function( item ) { return '<div class="font-size-0-9">' + item.tags.join(', ') + '</div>' },
				style: function( item ) { return { minWidth: '100px' } },
				sortable: false
			},
			{
				displayName: 'Published',
				propertyName: 'isPublished',
				displayFunction: function( item ) { return item.isPublished ? '<span class="icon-checkmark message-success" />' : '' },
				sortable: true
			},
			{
				displayName: 'Featured',
				propertyName: 'isFeatured',
				displayFunction: function( item ) { return item.isFeatured ? '<span class="icon-checkmark message-success" />' : '' },
				sortable: true
			},
			{
				displayName: 'Super',
				propertyName: 'isSuper',
				displayFunction: function( item ) { return item.isSuper ? '<span class="icon-checkmark message-success" />' : '' },
				sortable: true
			},
			{
				displayName: 'Rank',
				propertyName: 'rank',
				sortable: true
			},
			{
				displayName: 'Category',
				propertyName: 'category',
				sortable: true
			},
		)

		return {
			reviews: [],
			summary: null,
			count: 0,
			pages: 1,

			searchString: null,
			searchFocused: false,

			selectedReviews: [],
			multiRank: null,
			multiCategory: null,

			// ReviewsPage settings
			allCommentsDefaults: { showDate: false, showAddress: false },
			affCommentsDefaults: { showDate: false, showAddress: false },
			taggedCommentsDefaults: { showDate: false, showAddress: false },
			affTaggedCommentsDefaults: { showDate: false, showAddress: false },
			taggedCommentSettings: {},

			// ReviewsPage settings: specific affiliate ($props.affiliateIn is not null)
			specificAffCommentsDefaults: null,
			specificAffTaggedCommentsDefaults: null,
			affTaggedCommentSettings: {},

			selectedItem: null,
			startDate: null,
			endDate: null,

			minTime: null,
			maxTime: null,

			loading: false,
			error: false,
			
			sortBy: [ 'timestamp' ],
			sortAsc: [ false ],
			page: 1,
			pageSize: 100,

			// CSV download
			csvPageBusy: false,
			csvAllBusy: false,


			columns: cols,

			user: null,
			affiliate: this.$props.affiliateIn || null,
			customer: null,
			modal: null,

			filterTags: new Review(), // container for tags
			bulkTags: [],
			bulkTagsLoadingProgress: false,
			bulkTagsError: false,
			reviewTagSettings: [],   // [ { tag: String, publish: Boolean, publicLabel: String }, ... ]
			tagTimeouts: {},         // { tagNameString: timeoutID, ... }
		}
	},



	created() {
		this.initialize()
	},



	computed: {
		t() { return Tooltips; },
		commentsPageURL() { return powerprep_base_url + `/comments${ this.affiliateIn ? '/' + this.affiliateIn.urlSlug : '' }` },

		start() {
			if (!this.startDate) return null;
			return Math.floor(this.startDate.getTime()/1000);
		},

		end() {
			if (!this.endDate) return null;
			return Math.floor(this.endDate.getTime()/1000);
		},

		searchStyles() {
			if( this.searchFocused ) return { minWidth: '600px' }
			else return { minWidth: '400px' }
		},

		searchPlaceholder() {
			if( this.searchFocused ) return 'Search:   | = OR;   space = AND;   "" = multi-word term (does not work with |)'
			else return 'Search: email + name + body'
		}
	},



	watch: {
		pageSize() { this.page = 1; this.initialize() },
		startDate() { this.page = 1; this.initialize() },
		endDate() { this.page = 1; this.initialize() },
		searchString() { this.page = 1; this.initialize() },
		'filterTags.tags'() { this.page = 1; this.initialize() },
		affiliateIn( value ) { this.affiliate = value; this.page = 1; this.initialize() },
	},



	methods: {

		prepareRequest() {
			const req = new PaginatedRequest( this.sortBy, this.sortAsc, this.page, this.pageSize, this.searchString )

			if( this.user ) req.userID = this.user.userID
			else if( this.userID ) req.userID = this.userID

			if( this.customer ) req.customerID = this.customer.id
			else if( this.customerID ) req.customerID = this.customerID

			if( this.affiliate ) req.affiliateID = this.affiliate.id

			if( this.modalMessageID ) req.modalMessageID = this.modalMessageID
			else if( this.modal ) req.modalMessageID = this.modal.modalMessageID

			if( this.filterTags.tags.length > 0 ) req.tags = [ ...this.filterTags.tags ]

			if( this.start !== null ) req.startTimestamp = this.start
			if( this.end !== null )   req.endTimestamp   = this.end

			if( this.minTime !== null ) req.minMinutesInProgram = Math.round(this.minTime * 60) // convert from hours to minutes
			if( this.maxTime !== null ) req.maxMinutesInProgram = Math.round(this.maxTime * 60) // convert from hours to minutes

			return req
		},


		async initialize() {

			try {
				this.loading = true
				this.error = false
								
				const req = this.prepareRequest()
				const pr = await ReviewsAPI.getReviews( req )
				this.reviews = []

				for( let review of pr.data ) this.reviews.push( Review.import(review) )
				this.pages = pr.pages
				this.count = pr.count
				this.summary = pr.summary

			} catch( e ) {
				this.reviews = []
				
				this.pages = 1
				this.count = 0
				this.error = true

				console.error( e )

			} finally {
				this.loading = false
			}
		},



		async csvDownload( allPages = true ) {
			const req = this.prepareRequest()
			if( allPages ) this.csvAllBusy = true
			else this.csvPageBusy = true

			try {
				await ReviewsAPI.getReviewsCSV( req, allPages )

			} finally {
				this.csvPageBusy = false
				this.csvAllBusy = false
			}
		},



		selectSortBy(prop) {
			const idx = this.sortBy.indexOf( prop )
			if( idx > -1 ) {
				this.sortAsc[ idx ] = !this.sortAsc[ idx ]
				this.page = 1
				this.initialize()
				return
			}
			
			this.sortBy.push( prop )
			if( prop === 'stars' || prop === 'timestamp' || prop === 'minutesInProgram' || prop === 'licenseKey' || prop === 'isPublished' || prop === 'isFeatured' || prop === 'isSuper' ) {
				this.sortAsc.push( false )
			} else {
				this.sortAsc.push( true )
			}

			this.page = 1
			this.initialize()
		},


		unsortBy( prop ) {
			const idx = this.sortBy.indexOf( prop )
			if( idx < 0 ) return

			this.sortBy.splice( idx, 1 )
			this.sortAsc.splice( idx, 1 )
			this.page = 1
			this.initialize()
		},


		select( rvw ) {
			this.selectedItem = rvw
			this.$refs.detailsModal.open()
		},

		deselect() {
			this.selectedItem = null
			this.$refs.objectTable.deselect()
		},


		setSelectedReviews( list ) {
			this.selectedReviews = []
			for( let i=0; i < list.length; i++ ) {
				if( list[i] == true ) this.selectedReviews.push( this.reviews[i] )
			}
		},



		async publishSelected( pos = true ) {
			const req = {
				reviewIDs: this.selectedReviews.map( elem => elem.reviewID ),
				isPublished: pos
			}

			await ReviewsAPI.multiEditPublication( req )
			this.selectedReviews.map( rvw => {
				rvw.isPublished = pos
			})
		},

		async superSelected( pos = true ) {
			const req = {
				reviewIDs: this.selectedReviews.map( elem => elem.reviewID ),
				isSuper: pos
			}

			await ReviewsAPI.multiEditPublication( req )
			this.selectedReviews.map( rvw => {
				rvw.isSuper = pos
			})
		},

		async featureSelected( pos = true ) {
			const req = {
				reviewIDs: this.selectedReviews.map( elem => elem.reviewID ),
				isFeatured: pos
			}

			await ReviewsAPI.multiEditPublication( req )
			this.selectedReviews.map( rvw => {
				rvw.isFeatured = pos
			})
		},

		async rankSelected() {
			const req = {
				reviewIDs: this.selectedReviews.map( elem => elem.reviewID ),
				rank: this.multiRank || 0
			}

			await ReviewsAPI.multiEditPublication( req )
			this.selectedReviews.map( rvw => {
				rvw.rank = this.multiRank
			})
			
			this.multiRank = null
		},

		async categorizeSelected() {
			const req = {
				reviewIDs: this.selectedReviews.map( elem => elem.reviewID ),
				category: this.multiCategory || ''
			}

			await ReviewsAPI.multiEditPublication( req )
			this.selectedReviews.map( rvw => {
				rvw.category = this.multiCategory
			})
			
			this.multiCategory = null
		},

		async deleteSelected() {
			const req = {
				data: this.selectedReviews.map( elem => elem.reviewID )
			}

			await ReviewsAPI.deleteReviews( req )
			this.reviews = this.reviews.filter( elem => !this.selectedReviews.includes( elem ) )
			this.selectedReviews = []
		},

		async tagSelected( remove = false ) {
			if( !this.bulkTags || !this.bulkTags.length ) return

			this.bulkTagsLoadingProgress = 0
			this.bulkTagsError = false

			try {
				for( const review of this.selectedReviews ) {
					for( const tag of this.bulkTags ) {
						if( remove ) {
							await GenericTagsAPI.removeTag( 'review', review.reviewID, tag )
							review.removeTag( tag )
						} else {
							await GenericTagsAPI.addTag( 'review', review.reviewID, tag )
							review.addTag( tag )
						}
					}
					this.bulkTagsLoadingProgress++
				}

			} catch( e ) {
				this.bulkTagsError = true
				console.error( e.message )

			} finally {
				this.bulkTagsLoadingProgress = false
			}

			this.bulkTags = []
		},


		addBulkTag( tag ) {
			if( !tag ) return
			const idx = this.bulkTags.indexOf( tag )
			if( idx < 0 ) this.bulkTags.push( tag )
			this.$refs.bulkTagDropdown.clear()
		},

		removeBulkTag( tag ) {
			const idx = this.bulkTags.indexOf( tag )
			if( idx > -1 ) this.bulkTags.splice( idx, 1 )
		},

		addFilterTag( tag ) {
			if( !tag ) return
			this.filterTags.addTag( tag )
			this.$refs.filterTagSearch.clear()
		},



		async selectUser( user = null ) {
			this.user = user
			this.page = 1
			this.initialize()
		},
	
		async selectCustomer( customer = null ) {
			this.customer = customer
			this.page = 1
			this.initialize()
		},
		
		async selectAffiliate( affiliate = null ) {
			this.affiliate = affiliate
			this.page = 1
			this.initialize()
		},

		async selectModal( modal = null ) {
			this.modal = modal
			this.page = 1
			this.initialize()
		},


		setMinTime() {
			if( this.setMinTime.timeoutID ) clearTimeout( this.setMinTime.timeoutID )

			this.setMinTime.timeoutID = setTimeout( () => {
				if( isNaN( this.minTime ) || this.minTime === '' ) this.minTime = null
				this.page = 1
				this.initialize()
				this.setMinTime.timeoutID = null
			}, 500)
		},


		setMaxTime() {
			if( this.setMaxTime.timeoutID ) clearTimeout( this.setMaxTime.timeoutID )
			
			this.setMaxTime.timeoutID = setTimeout( () => {
				if( isNaN( this.maxTime ) || this.maxTime === '' ) this.maxTime = null
				this.page = 1
				this.initialize()
				this.setMaxTime.timeoutID = null
			}, 500)
		},




		starBarWidth( num, denom ) {
			if( !denom ) return '0%'
			const width = Math.round( (num / denom) * 100 )
			return width + '%'
		},



		async openPublicationSettings() {

			// Single-affiliate mode
			var req1 = null
			if( this.affiliateIn ) {
				req1 = ReviewsAPI.getAdminReviewsPageSettings( this.affiliate.urlSlug ).then( data => {
					for( const row of data ) {
						if( typeof row.affiliateSlug == 'string' && row.reviewTag == null ) {
							this.specificAffCommentsDefaults = row
						} else if( typeof row.affiliateSlug == 'string' && row.reviewTag === true ) {
							this.specificAffTaggedCommentsDefaults = row
						} else if( typeof row.affiliateSlug == 'string' && typeof row.affiliateSlug == 'string' ) {
							if( this.affTaggedCommentSettings[ row.reviewTag ] === undefined ) this.affTaggedCommentSettings[ row.reviewTag ] = row
							else {
								this.affTaggedCommentSettings[ row.reviewTag ].showDate = row.showDate
								this.affTaggedCommentSettings[ row.reviewTag ].showAddress = row.showAddress
							}
						}
					}
				})

			} else {

				req1 = ReviewsAPI.getAdminReviewsPageSettings().then( data => {
					for( const row of data ) {
						if( row.affiliateSlug == null && row.reviewTag == null ) {
							this.allCommentsDefaults = row
						} else if( row.affiliateSlug === true && row.reviewTag == null ) {
							this.affCommentsDefaults = row
						} else if( row.affiliateSlug == null && row.reviewTag === true ) {
							this.taggedCommentsDefaults = row
						} else if( row.affiliateSlug === true && row.reviewTag === true ) {
							this.affTaggedCommentsDefaults = row
						} else if( row.affiliateSlug == null && row.reviewTag != null ) {
							if( this.taggedCommentSettings[ row.reviewTag ] === undefined ) this.taggedCommentSettings[ row.reviewTag ] = row
							else {
								this.taggedCommentSettings[ row.reviewTag ].showDate = row.showDate
								this.taggedCommentSettings[ row.reviewTag ].showAddress = row.showAddress
							}
						}
					}
				})
			}
			

			const req2 = ReviewsAPI.getTagPublicationSettings().then( data => {
				this.reviewTagSettings = data
			})

			await Promise.all( [ req1, req2 ] )
			this.$refs.settingsModal.open()
		},



		async setCommentTagSettingsOverride( affSlug, tag, value ) {

			console.debug( "setCommentTagSettingsOverride", tag, value )
			if( value === true ) {
				await this.saveReviewsPageSettings( affSlug, tag, false, false )
			}
			else {
				await ReviewsAPI.deleteReviewsPageSettings( affSlug, tag )

				if( typeof affSlug === 'string' && tag === null ) this.specificAffCommentsDefaults = null
				else if( typeof affSlug === 'string' && tag === true ) this.specificAffTaggedCommentsDefaults = null
				delete( this.taggedCommentSettings[ tag ] )
				delete( this.affTaggedCommentSettings[ tag ] )
			}

			this.$forceUpdate()
		},
		
		
		
		async saveReviewsPageSettings( affiliateSlug, reviewTag, showDate, showAddress ) {
			console.debug( "saveReviewsPageSettings", affiliateSlug, reviewTag, showDate, showAddress )

			const req = { affiliateSlug, reviewTag, showDate, showAddress }
			const res = await ReviewsAPI.setReviewsPageSettings( req )

			if( affiliateSlug === null && reviewTag === null ) {
				this.allCommentsDefaults.showDate = showDate
				this.allCommentsDefaults.showAddress = showAddress
			} else if( affiliateSlug === true && reviewTag === null ) {
				this.affCommentsDefaults.showDate = showDate
				this.affCommentsDefaults.showAddress = showAddress
			} else if( affiliateSlug === null && reviewTag === true ) {
				this.taggedCommentsDefaults.showDate = showDate
				this.taggedCommentsDefaults.showAddress = showAddress
			} else if( affiliateSlug === true && reviewTag === true ) {
				this.affTaggedCommentsDefaults.showDate = showDate
				this.affTaggedCommentsDefaults.showAddress = showAddress

			} else if( typeof affiliateSlug == 'string' && reviewTag === null ) {
				this.specificAffCommentsDefaults = { showDate, showAddress }
			} else if( typeof affiliateSlug == 'string' && reviewTag === true ) {
				this.specificAffTaggedCommentsDefaults = { showDate, showAddress }

			} else if( typeof affiliateSlug == 'string' && reviewTag !== null ) {
				// Specific affiliate tags (i.e. $props.affiliateIn != null)
				if( this.affTaggedCommentSettings[ reviewTag ] === undefined ) this.affTaggedCommentSettings[ reviewTag ] = { showDate, showAddress }
				else {
					this.affTaggedCommentSettings[ reviewTag ].showDate = showDate
					this.affTaggedCommentSettings[ reviewTag ].showAddress = showAddress
				}

			} else if( affiliateSlug === null && reviewTag !== null ) {
				// Specific tags, but no affiliate
				if( this.taggedCommentSettings[ reviewTag ] === undefined ) this.taggedCommentSettings[ reviewTag ] = { showDate, showAddress }
				else {
					this.taggedCommentSettings[ reviewTag ].showDate = showDate
					this.taggedCommentSettings[ reviewTag ].showAddress = showAddress
				}
			}
			
		},



		toggleTag( tagObj ) { if( tagObj.publish ) this.publishTag( tagObj.tag ); else this.unpublishTag( tagObj.tag ); },
		async publishTag( tag ) { await ReviewsAPI.publishTag( tag ) },
		async unpublishTag( tag ) { await ReviewsAPI.unpublishTag( tag ); delete( this.taggedCommentSettings[ tag ] ) },
		async updateTagLabel( tagObj ) {
			if( this.tagTimeouts[ tagObj.tag ] ) clearTimeout( this.tagTimeouts[ tagObj.tag ] )
			this.tagTimeouts[ tagObj.tag ] = setTimeout( () => { ReviewsAPI.setTagLabel( tagObj.tag, tagObj.publicLabel ) }, 500)
		},

	}
}
</script>



<style scoped>
.summaryWidget {
	color: #606060;
}
.progBar {
	background: #eee;
	min-width: 200px;
	height: 0.75em;
	margin: 0.4em 0;
	border-radius: 0.25em;
}
.prog {
	background: var(--ekno-blue);
	height: 100%;
	border-radius: 0.25em;
}
.icon-star-full { color: orange; }

</style>