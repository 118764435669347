import Exim from '@/models/Exim.js'

export default class LongJob extends Exim {


  jobID = null
	jobLabel = null
	workUnitsComplete = null
	workUnitsTotal = null

	pid = null
	status = null
	lastHeartbeat = null

	startTime = null
	endTime = null
	
	error = false
	complete = false
	jobReport = null
	errorReport = null


	// Optional parameters (for async job execution, not for client replies)
	executeAsUserID = null
	executable = null  // Return TRUE if complete; FALSE otherwise; THROW on job-stopping exception.
	args = []



	get percentComplete() {
		if( !this.workUnitsTotal ) return ''
		return Math.round( this.workUnitsComplete * 100 / this.workUnitsTotal ) + '%'
	}



	static import( obj ) {
		return super.import( obj, [], ['startTime', 'endTime', 'lastHeartbeat'] )
	}


}