<template>
<StretchModal padding="1em" maxWidth="600px" @close="cancel()" ref="modal">
  <template #header>Add To Blast List</template>
  <div v-if="isNewBlastList === null" class="flex-row flex-align-center flex-justify-center flex-gap-2">
    <button class="pillButton" @click="isNewBlastList = true">New List</button>
    <button class="pillButton" @click="isNewBlastList = false">Add to Existing List</button>
  </div>


  <div v-else-if="isNewBlastList === true">
    <div>List Name: <input type="text" v-model="newBlastListName" /></div>
    <div>Description:<br><textarea v-model="newBlastListDescription" /></div>
    
    <div class="right">
      <button class="pillButton flex-row flex-align-center flex-gap-05" :class="{'disabled' : busy}" :disabled="busy" @click="createBlastList()">
        <span v-if="busy" class="icon-spinner4 spin1" style="font-size: 1em; width: 1.1em; height: 1.1em;" />
        <span v-if="!busyInternal">Create List + add {{ recipientCount }} recipients</span>
        <span v-else>Creating List...</span>
      </button>
    </div>

  </div>
  

  <div v-else-if="isNewBlastList === false" style="min-height: 400px;">
    <div><BlastListSearchDropdown v-model="selectedList" suggestionMaxHeight="20em" placeholder="Select a List" /></div>
    
    <div class="right">
      <button class="pillButton flex-row flex-align-center flex-gap-05" :class="{ 'disabled' : !selectedList || busy }" :disabled="!selectedList || busy" @click="addToExistingBlastList( selectedList.id )">
        <span v-if="busy" class="icon-spinner4 spin1" style="font-size: 1em; width: 1.1em; height: 1.1em;" />
        <span v-if="!busyInternal">Add {{ recipientCount }} recipients</span>
        <span v-else>Adding to list...</span>
      </button>
    </div>

  </div>

</StretchModal>
</template>



<script>
import BlastListSearchDropdown from './BlastListSearchDropdown.vue'
import StretchModal from '@/components/utilities/StretchModal.vue'

import MessageCenterAPI from '@/api/MessageCenterAPI.js'
import BlastList from './BlastList.js'
import User from '@/features/Users/User.js'
import POC from '../../SalesManagement/POC/POC.js'

export default {
  name: 'AddToBlastListModal',


  components: {
    BlastListSearchDropdown,
    StretchModal,
  },



  props: {
    sourceList: {
      type: Array,
      required: true
    },
    secondarySourceList: {
      type: Array,
      default: null
    },
    useSecondary: {
      type: Boolean,
      default: false
    },
    busyExternal: {
      type: Boolean,
      default: false
    }
  },



  data() {
    return {
      busyInternal: false,
      isNewBlastList: null,
      newBlastListName: null,
      newBlastListDescription: null,
      selectedList: null,
    }
  },



  computed: {
    busy() { return this.busyExternal || this.busyInternal },
    recipientCount() {
			this.activeList.length
			return this.activeList.filter( elem => !( elem instanceof BlastList ) ).length
		},

    activeList() {
      if( this.secondarySourceList && this.useSecondary ) return this.secondarySourceList
      else return this.sourceList
    }
  },



  methods: {

    open() {
      this.$refs.modal.open()
    },

    cancel() {
      this.busyInternal = false
      this.isNewBlastList = null
      this.newBlastListName = null
      this.newBlastListDescription = null
      this.selectedList = null
      this.$emit('close')
    },



    async createBlastList() {
      try {
        this.busyInternal = true

        const res = await MessageCenterAPI.saveBlastList( { name: this.newBlastListName, description: this.newBlastListDescription } )
        const id = res.data.id
        await this.addToExistingBlastList( id )
  
        this.newBlastListName = null
        this.newBlastListDescription = null

      } finally {
        this.busyInternal = false
      }
		},



		async addToExistingBlastList( blastListID ) {

			const wasBusy = this.busyInternal
      const wasCursorBusy = this.$store.state.busyCursor

      this.busyInternal = true
      this.$store.state.busyCursor = true

      // Kick the rest of the function to the end of the exec stack in order 
      // to let the UI update before locking the main thread with prepareBlastListRows()
      await new Promise( resolve => setTimeout( () => resolve(), 250) )

      try {
        const rows = this.prepareBlastListRows()
        await MessageCenterAPI.saveBlastListRows( blastListID, rows )

        this.$refs.modal.close()
        this.$emit( 'blastListUpdated', blastListID )
        this.$emit('deselect')

      } finally {
        this.busyInternal = wasBusy
        this.$store.state.busyCursor = wasCursorBusy
      }
			
		},



    // DESTRUCTIVELY find and prepare all non-BlastList recipient rows (removes non-blastList items from source)
    prepareBlastListRows() {
      const tmp = []
      while( this.activeList.length ) tmp.push( this.activeList.pop() )
			
      const rows = []

			while( tmp.length > 0 ) {
        const item = tmp.pop()
				if( item instanceof User ) rows.push( { name: `${ item.firstName }`, email: item.emailAddress, phone: item.phone, userID: item.userID } )
				else if( item instanceof POC ) rows.push( { name: `${ item.firstName }`, email: item.emailAddress, phone: item.phoneNumber, pocID: item.id } )
				else if( !(item instanceof BlastList) ) rows.push( { email: item.email } ) // "string" value (it's an object so the email validator can add .valid)
        else this.activeList.push( item )
			}

			return rows
		},

  }
}
</script>