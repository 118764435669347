<template>
<section>
	<button class="pillButton StickySaveButton" @click="sendMessage()">Send to this List</button>

	<div class="flex-row flex-justify-between flex-gap-1">
		<div class="pr-1 border-right" style="width: 25%;">
			<div>Name &amp; Description</div>
			<div><input type="text" v-model="list.name" class="w-100 font-size-1-3" placeholder="Name" /></div>
			<div><textarea v-model="list.description" class="w-100" placeholder="Description" /></div>
		</div>

		<div>
			<div>Custom columns:</div>
			<div class="flex-row flex-align-center flex-gap-1">
				<TagField :Source="list.mailMergeTags" :topMargin="false" @remove="tag => showDeleteTag( tag )" />
				<button class="pillButton secondary" @click="showAddTag()"><span class="icon-plus" /> New Column</button>
			</div>
		</div>

		<SearchBox @search="str => searchString = str" />
	</div>

	<div class="flex-row flex-align-center flex-justify-between my-05">
		
		<MultiselectTools :numSelected="selected.length" :showDelete="true" @all="$refs.objectTable.selectAll()" @none="$refs.objectTable.selectNone()" @delete="$refs.confirmRemoveSelectedModal.open()">
			<span class="pillButton secondary border small flex-row flex-align-center flex-gap-05" @click="copySelectedToList()"><span class="icon-copy color-blue-60" /> copy selected to list...</span>
		</MultiselectTools>


		<!-- Centered buttons + controls -->
		<section class="flex-row flex-align-start flex-justify-center flex-gap-1">
			
			<!-- Defect Report ("Validate List")  -->
			<div>
				<div v-if="!defectReport && !enrichReport" class="pillButton secondary border flex-row flex-align-center flex-gap-05" @click="getDefectReport()">
					Clean this List
					<span v-if="defectReportBusy" class="icon-spinner4 spin1" />
				</div>
				<div v-if="defectReport" class="flex-row flex-align-center flex-justify-between color-white bg-orange-40 bold round-05 pa-1 mb-05">
					List Clean-Up Mode
					<div>
						<span class="ml-1 pa-05 color-white" @click="getDefectReport()"><span class="icon-spinner11" /></span>
						<span class="ml-05 pa-05 color-white" @click="closeDefectReport()"><span class="icon-cross" /></span>
					</div>
				</div>
				<div v-if="defectReport"><ToggleButton v-model="showDefectiveRowsOnly" :sync="true" @change="getListRows()" /> Show defective entries only</div>
				<div v-if="defectReport && showDefectiveRowsOnly" class="ml-3"><ToggleButton v-model="showDuplicates" :sync="true" @change="getListRows()" /> Show duplicates <span class="link ml-1" @click="$refs.confirmRemoveDupesModal.open()">auto-remove</span></div>
				<div v-if="defectReport && showDefectiveRowsOnly" class="ml-3"><ToggleButton v-model="showUnsubscribes" :sync="true" @change="getListRows()" /> Show unsubscribes <span class="link ml-1" @click="$refs.confirmRemoveUnsubsModal.open()">remove unsubs</span></div>
				<div v-if="defectReport && showDefectiveRowsOnly" class="ml-3"><ToggleButton v-model="showMalformed" :sync="true" @change="getListRows()" /> Show malformed <span class="link ml-1" @click="$refs.confirmRemoveMalformedModal.open()">remove malformed</span></div>
				<div v-if="defectReportError" class="warning">{{ defectReportError }}</div>
				<div v-if="reportActionError" class="warning">{{ reportActionError }}</div>
			</div>
	
			<!-- Enrich List -->
			<div v-if="!defectReport && !enrichReport">
				<div class="pillButton secondary border flex-row flex-align-center flex-gap-05" @click="enrichList()">
					Enrich this List<span v-if="enrichBusy" class="icon-spinner4 spin1" />
				</div>
			</div>
			<div v-if="enrichReport" class="center color-white bg-green bold round-05 pa-1 mb-05">
				<span>Enriched {{ enrichReport.affectedRows }} entries</span>
				<span class="ml-1 pa-05 color-white" @click="enrichReport = null"><span class="icon-cross" /></span>
			</div>
			<div v-if="enrichError" class="warning">{{ enrichError }}</div>

		</section>
		
		<!-- Right-hand controls -->
		<div class="flex-row flex-align-center flex-justify-end flex-gap-2">
			<div>{{ count ? count.toLocaleString() : count }} entries on {{ pages }} page{{ pages == 1 ? '' : 's' }}</div>
			<div>
				<label>Per page: </label>
				<select v-model="pageSize">
					<option :value='25'>25</option>
					<option :value='50'>50</option>
					<option :value='100'>100</option>
					<option :value='250'>250</option>
					<option :value='500'>500</option>
				</select>
			</div>
			<span class="icon-file-excel font-size-1-5" @click="csvDownload()" @mouseenter="t.s($event)" @mouseleave="t.h($event)" :tooltip="`Download CSV of this Blast List`" />
		</div>

	</div>


	<div v-if='!loading && !error'>

		<div v-if='items.length'>
			<ObjectTable
				:Source='items'
				:Columns='columns'
				:superColumns='superColumns'
				:Numbered='true'
				:inlineSelect='true'
				:MultiSelect="true"
				:SortBy='sortBy'
				:SortAsc='sortAsc'
				:Deletable='true'
				@inlineEdit="( item, propName, value ) => edit( item, propName, value )"
				@sort='prop => sort( prop )'
				@selectedItems="list => setSelected( list )"
				@delete='item => showDeleteSelected( item )'
				ref='objectTable'
			/>
			<Paginator v-model="page" :numPages="pages" @input="pg => toPage( pg )" />
		</div>

		<div v-else class="NoResults">No results</div>

		<div class="flex-row flex-justify-end"><button class="pillButton secondary" @click="createRow()"><span class="icon-plus" /> Add Row</button></div>

	</div>

	<div v-if="error" class="warning">Failed to get records</div>


	<ConfirmDialog ref="confirmDeleteRow" @cancel="itemToDelete = null" @confirm="deleteItem()">
		<div v-if="itemToDelete">Really delete <b>{{ itemToDelete.name || itemToDelete.email || itemToDelete.phone }}</b>?</div>
	</ConfirmDialog>

	<ConfirmDialog ref="confirmDeleteTag" @cancel="tagToDelete = null" @confirm="deleteTag()">
		<div v-if="tagToDelete">Really delete column <b>{{ tagToDelete }}</b>?</div>
	</ConfirmDialog>

	<ConfirmDialog ref="confirmAddTag" @cancel="tagToAdd = null" @confirm="addTag()">
		<div>Column Name: <input type="text" v-model="tagToAdd" /></div>
	</ConfirmDialog>

	<ConfirmDialog ref="confirmRemoveSelectedModal" :confirm="`Remove ${selected.length} Selected`" @confirm="removeSelected()">
		Remove {{ selected.length }} selected recipients from this list?<br><br>
		<strong class="color-red">This cannot be undone!</strong>
	</ConfirmDialog>

	<ConfirmDialog ref="confirmRemoveDupesModal" confirm="Remove Duplicates" @confirm="autoRemoveDupes()">
		This will remove well-matched duplicate rows<br>(rows that have the following in common):
		<ul style="text-align: left;">
			<li>name</li>
			<li>email</li>
			<li>phone</li>
			<li>all additional mail-merge column values</li>
		</ul>
		<strong class="color-red">This cannot be undone!</strong>
	</ConfirmDialog>

	<ConfirmDialog ref="confirmRemoveUnsubsModal" confirm="Remove All Unsubscribed" @confirm="removeAllUnsubs()">
		Remove from this list <b>all</b> recipients who unsubscribed<br>their email addresses (or marked as spam)?<br><br>
		<strong class="color-red">This cannot be undone!</strong>
	</ConfirmDialog>

	<ConfirmDialog ref="confirmRemoveMalformedModal" confirm="Remove All Unsubscribed" @confirm="removeAllMalformed()">
		Remove from this list <b>all</b> recipients with<br>malformed email addresses?<br><br>
		<strong class="color-red">This cannot be undone!</strong>
	</ConfirmDialog>

	<AddToBlastListModal :sourceList="toCopy" ref="addToListModal" />

</section>
</template>



<script>
import SearchBox from '@/components/utilities/SearchBox.vue'
import ObjectTable from '@/components/utilities/ObjectTable.vue'
import Paginator from '@/components/utilities/Paginator.vue'
import ConfirmDialog from '@/components/utilities/ConfirmDialog.vue'
import TagField from '@/components/utilities/TagField.vue'
import AddToBlastListModal from './AddToBlastListModal.vue'
import MultiselectTools from '@/components/utilities/MultiselectTools.vue'
import { ToggleButton } from 'vue-js-toggle-button'

import MessageCenterAPI from '@/api/MessageCenterAPI.js'
import PaginatedRequest from '@/api/PaginatedRequest.js'
import BlastList from './BlastList.js'
import BlastListRow from './BlastListRow.js'
import Tooltips from '@/libraries/Tooltips/Tooltips.js'

export default {
	name: 'BlastListDetails',


	components: {
		SearchBox,
		ObjectTable,
		Paginator,
		ConfirmDialog,
		TagField,
		AddToBlastListModal,
		MultiselectTools,
		ToggleButton,
	},


	props: {
		list: {
			type: BlastList,
			required: true
		},
	},


	
	data() {
		return {
			page: 1,
			pages: 1,
			pageSize: 100,
			count: 0,
			sortBy: null,
			sortAsc: false,
			searchString: null,

			saveTimeoutID: null,

			items: [],
			selected: [],
			toCopy: [],
			loading: false,
			error: false,

			selectedItem: null,
			itemToDelete: null,

			defectReport: null,
			defectReportBusy: false,
			defectReportError: null,
			showDefectiveRowsOnly: false,
			showDuplicates: false,
			showUnsubscribes: false,
			showMalformed: false,

			reportActionError: null,

			enrichBusy: false,
			enrichReport: null,
			enrichError: null,

			tagToAdd: null,
			tagToDelete: null,

			columns: [],
			superColumns: null
		}
	},



	computed: {
		t() { return Tooltips; },
	},



	watch: {
		list() { this.page = 1; this.initialize() },
		'list.name'( value ) {
			clearTimeout( this.saveTimeoutID )
			this.saveTimeoutID = setTimeout( this.saveBlastList, 500 )
		},
		'list.description'( value ) {
			clearTimeout( this.saveTimeoutID )
			this.saveTimeoutID = setTimeout( this.saveBlastList, 500 )
		},
		pageSize() { this.page = 1; this.getListRows() },
		searchString() { this.page = 1; this.getListRows() },
	},


	created() { this.initialize() },


	methods: {

		async initialize() {
			
			// Init columns
			this.columns = [
				{
					displayName: 'Name',
					propertyName: 'name',
					sortable: true,
					editable: true,
				},
				{
					displayName: 'User ID',
					propertyName: 'userID',
					sortable: true,
					editable: true,
				},
				{
					displayName: 'POC ID',
					propertyName: 'pocID',
					sortable: true,
					editable: true,
				},
				{
					displayName: 'E-Mail',
					propertyName: 'email',
					displayFunction: item => {
						if( !this.defectReport ) return item.email
						if( !this.defectReport.has( item.rowID ) ) return item.email
						const rpt = this.defectReport.get( item.rowID )

						const badges = []
						if( rpt.emailDuplicate ) badges.push( `<span class=" round-025 color-white bg-orange pa-025">duplicate</span>` )
						if( rpt.emailMalformed ) badges.push( `<span class=" round-025 color-white bg-purple pa-025">malformed</span>` )
						if( rpt.emailUnsubscribed ) badges.push( `<span class=" round-025 color-white bg-red pa-025">unsubscribed</span>` )
						return `<div class="flex-row flex-align-center flex-gap-05"><span class="bg-red-90">${ item.email }</span>` + badges.join('') + '</div>'
					},
					editDisplayFunction: item => { return item.email },
					sortable: true,
					editable: true,
				},
				{
					displayName: 'Phone',
					propertyName: 'phone',
					sortable: true,
					editable: true,
				},
			]

			// Close over colName:
			// Must generate each column's display function to lexically bind colName w/in the for-of loop
			//  -- otherwise, call-time value will be used, which is *always* the name of the last column.
			const generateDisplayFunction = function( colName ) {
				return function( row ) {
					const col = row.extraColumns.find( elem => elem.key == colName )
					return col ? col.value : null
				}
			}

			var superColWidth = 0

			for( var colName of this.list.mailMergeTags ) {
				superColWidth++

				this.columns.push({
					displayName: colName,
					propertyName: colName,
					displayFunction: generateDisplayFunction( colName ),
					editable: true,
				})
			}

			this.columns.push({
				displayName: 'Date Created',
				propertyName: 'dateCreated',
				displayFunction: ( item ) => { return item.dateCreated ? item.dateCreated.toLocaleString() : null },
				sortable: true,
			})

			if( superColWidth > 0 ) this.superColumns = [ { displayName: 'Default Columns', colspan: 5 }, { displayName: 'Custom Columns', colspan: superColWidth }, { displayName: '', colspan: 1 } ]
			
			await this.getListRows()
		},


		async getListRows() {
			this.items = []
			this.selected = []
			this.error = false
			this.loading = true

			try {
				let req = null
				if( this.showDefectiveRowsOnly ) {
					req = new PaginatedRequest( this.sortBy, this.sortAsc, this.page, this.pageSize, this.searchString )
					req.rowIDs = []
					for( let [key, value] of this.defectReport.entries() ) {
						if( this.showDuplicates && value.emailDuplicate ) { req.rowIDs.push( key ); continue; }
						if( this.showUnsubscribes && value.emailUnsubscribed ) { req.rowIDs.push( key ); continue; }
						if( this.showMalformed && value.emailMalformed ) { req.rowIDs.push( key ); continue; }
					}
					if( req.rowIDs.length == 0 ) return

				} else req = new PaginatedRequest( this.sortBy, this.sortAsc, this.page, this.pageSize, this.searchString )
				const pr = await MessageCenterAPI.getBlastListRows( this.list.id, req )

				this.count = pr.count
				this.pages = pr.pages

				for( var item of pr.data ) this.items.push( BlastListRow.import( item ) )

			} catch (e) {
				this.error = true
				throw e

			} finally {
				this.loading = false
			}
		},



		setSelected( list ) {
			this.selected = []
			for( let i=0; i < list.length; i++ ) {
				if( list[i] == true ) this.selected.push( this.items[i] )
			}
		},


		async copySelectedToList() {
			this.toCopy = [ ...this.selected ]
			this.$refs.addToListModal.open()
		},


		async removeSelected() {
			if( !this.selected.length ) return

			try {
				this.$store.state.busyCursor = true
				const rowIDs = []
				for( let row of this.selected ) rowIDs.push( row.rowID )
				await MessageCenterAPI.deleteBlastListRows( this.list.id, rowIDs )

			} finally {
				this.$store.state.busyCursor = false
				this.getListRows()
			}
		},



		async getDefectReport() {
			this.defectReport = null

			try {
				this.defectReportBusy = true

				const data = await MessageCenterAPI.getBlastListDefectReport( this.list.id )
				console.debug( "defect report:", data )
				
				this.defectReport = new Map()
				for( let key in data ) this.defectReport.set( parseInt( key ), data[key] )
				
				this.showDefectiveRowsOnly = true
				this.showDuplicates = true
				this.showUnsubscribes = true
				this.showMalformed = true
				this.sortBy = 'email'
				this.sortAsc = true
				this.getListRows()

			} catch( e ) {
				this.defectReportError = e.message
			} finally {
				this.defectReportBusy = false
			}
		},



		closeDefectReport() {
			this.defectReport = null
			this.defectReportBusy = false
			this.defectReportError = null
			this.showDefectiveRowsOnly = false
			this.getListRows()
		},



		async autoRemoveDupes( preflight = false ) {
			if( !this.defectReport ) return

			try {
				this.$store.state.busyCursor = true
				this.reportActionError = null

				const data = await MessageCenterAPI.deleteDuplicateEntries( this.list.id, preflight )
				if( data && data.rows ) alert( `Removed ${ data.rows.length } duplicates` )
				await this.getDefectReport()
				
			} catch( e ) {
				this.reportActionError = e.message
			} finally {
				this.$store.state.busyCursor = false
			}
		},



		async removeAllUnsubs() {
			await this.removeDefective( 'emailUnsubscribed' )
		},


		async removeAllMalformed() {
			await this.removeDefective( 'emailMalformed' )
		},



		/**
		 * 
		 * @param defectDescription string 'emailUnsubscribed' || 'emailMalformed'
		 */
		async removeDefective( defectDescription ) {
			if( !this.defectReport ) return

			try {
				this.$store.state.busyCursor = true
				this.reportActionError = null

				const rowIDs = []
				for( let [rowID, line] of this.defectReport.entries() ) {
					if( line[ defectDescription ] == true ) rowIDs.push( rowID )
				}

				if( !rowIDs.length ) return
				
				await MessageCenterAPI.deleteBlastListRows( this.list.id, rowIDs )
				this.getListRows()


			} catch( e ) {
				this.reportActionError = e.message
			} finally {
				this.$store.state.busyCursor = false
			}
		},



		async enrichList() {
			this.enrichReport = null

			try {
				this.enrichBusy = true
				const data = await MessageCenterAPI.enrichBlastList( this.list.id )
				this.enrichReport = data
				this.getListRows()

			} catch( e ) {
				this.enrichError = e.message
			} finally {
				this.enrichBusy = false
			}
		},


		
		async saveBlastList() {
			if( !this.list ) return
			await MessageCenterAPI.saveBlastList( this.list.export() )
		},



		sendMessage() {
			this.$store.state.pipes.send( 'teleport', { mode: 'messages.compose', props: { listIn: this.list }, header: `Compose Message` } )
		},


		async edit( item, propertyName, value ) {
			const defaultProps = [ 'userID', 'pocID', 'name', 'email', 'phone' ]
			
			if( defaultProps.includes( propertyName ) )	item[ propertyName ] = value
			else item.setExtraColumn( propertyName, value )

			if( !item.rowID ) {
				await MessageCenterAPI.saveBlastListRows( item.listID, [ item ] )
				this.getListRows()
				return
			}

			await MessageCenterAPI.patchBlastListRow( { rowID: item.rowID, listID: item.listID, propertyName, value } )
		},


		toPage( page ) {
			this.page = page
			this.initialize()
		},


		sort( prop ) {
			
			if( this.sortBy === prop ) {
				this.sortAsc = !this.sortAsc
				this.page = 1
				this.getListRows()
				return
			}
			
			this.sortBy = prop
			if (
				prop === 'dateCreated'
			) {
				this.sortAsc = false
			} else {
				this.sortAsc = true
			}

			this.page = 1
			this.getListRows()
		},


		csvDownload() {
			MessageCenterAPI.getBlastListCSV( this.list.id )
		},


		createRow() {
			const newRow = new BlastListRow()
			newRow.listID = this.list.id
			this.items.push( newRow )

		},


		showDeleteSelected( item ) {
			this.itemToDelete = item
			this.$refs.confirmDeleteRow.open()
		},


		async deleteItem() {
			const item = this.itemToDelete

			if( !item.rowID ) return
			await MessageCenterAPI.deleteBlastListRows( this.list.id, [ item.rowID ] )
			
			const idx = this.items.findIndex( elem => elem.rowID == item.rowID )
			if( idx < 0 ) return
			this.items.splice( idx, 1 )
			this.list.numContacts--

			this.itemToDelete = null
		},


		showAddTag() {
			this.$refs.confirmAddTag.open()
		},


		async addTag() {
			if( !this.tagToAdd ) return
			await MessageCenterAPI.addBlastListColumn( this.list.id, this.tagToAdd )
			this.list.mailMergeTags.push( this.tagToAdd )
			this.tagToAdd = null
			this.initialize()
		},


		showDeleteTag( tag ) {
			this.tagToDelete = tag
			this.$refs.confirmDeleteTag.open()
		},


		async deleteTag() {
			await MessageCenterAPI.deleteBlastListColumn( this.list.id, this.tagToDelete )
			this.list.mailMergeTags = this.list.mailMergeTags.filter( tag => tag !== this.tagToDelete )
			this.tagToDelete = null
			this.initialize()
		}

	}

}
</script>



<style scoped>

</style>