<template>
<section>

	<div class="flex-row flex-justify-between my-05">
		<SearchBox @search="str => searchString = str" />
		
		<div class="flex-row flex-justify-end flex-gap-2">
			<div>{{ count ? count.toLocaleString() : count }} contacts on {{ pages }} page{{ pages == 1 ? '' : 's' }}</div>
			<div>
				<label>Per page: </label>
				<select v-model="pageSize">
					<option :value='25'>25</option>
					<option :value='50'>50</option>
					<option :value='100'>100</option>
					<option :value='250'>250</option>
					<option :value='500'>500</option>
				</select>
			</div>
		</div>

	</div>

	<div v-if="loading" class='flex justify-center'>
		<div class='icon-spinner4 spin-loader' />
	</div>

	<div v-if='!loading && !error'>

		<div class="flex-row flex-align-center flex-justify-between">
			
			<MultiselectTools :numSelected="selectedContacts.length" :showDelete="false" @all="$refs.objectTable.selectAll()" @none="$refs.objectTable.selectNone()">
				<span class="pillButton secondary border small flex-row flex-align-center flex-gap-05" @click="composeEmail()"><span class="icon-envelop color-blue-60" /> Compose email to...</span>
				<span class="pillButton secondary border small flex-row flex-align-center flex-gap-05" @click="copySelectedToList()"><span class="icon-copy color-blue-60" /> create mailing list...</span>
			</MultiselectTools>

			<div class="flex-row flex-gap-05">
				<span v-if="selectedContacts.length" class="icon-file-excel font-size-1-5 color-green-25" @click="csvDownload( true )" @mouseenter="t.s($event)" @mouseleave="t.h($event)" :tooltip="`Download CSV of ${selectedContacts.length} selected contacts`" />
				<span v-if="!selectedContacts.length" class="icon-file-excel font-size-1-5 color-blue-40" @click="csvDownload()" @mouseenter="t.s($event)" @mouseleave="t.h($event)" :tooltip="`Download CSV of this page (${items.length} contacts)`" />
				<span v-if="!selectedContacts.length" class="icon-file-excel font-size-1-5 color-red-40" @click="csvDownload( false, true )" @mouseenter="t.s($event)" @mouseleave="t.h($event)" :tooltip="`Download CSV of all ${count} contacts`" />
			</div>
		</div>
		
		<div v-if='items.length'>
			<ObjectTable
				:Source='items'
				:Columns='columns'
				:Numbered='true'
				:PageNum="page"
				:PageSize="pageSize"
				:SortBy='sortBy'
				:SortAsc='sortAsc'
				:multisort='true'
				:MultiSelect='true'
				@selectedItems='list => multiSelect( list )'
				@edit='item => singleSelect( item )'
				@sort='prop => selectSortBy( prop )'
				@unsort='prop => unsortBy( prop )'
				ref='objectTable'
			/>
			<Paginator v-model="page" :numPages="pages" @input="pg => toPage( pg )" />
		</div>

		<div v-else class="NoResults">No results</div>
	</div>

	<div v-if="error" class="warning">Failed to get records</div>


	<StretchModal ref="contactModal" padding="1em" maxWidth="300px" @close="singleDeselect()">
		<PocDetails :contact="selectedContact" />
	</StretchModal>

	<AddToBlastListModal :sourceList="contactsToCopy" ref="addToListModal" />


</section>
</template>



<script>
import PocDetails from '@/features/SalesManagement/POC/PocDetails.vue'
import SearchBox from '@/components/utilities/SearchBox.vue'
import ObjectTable from '@/components/utilities/ObjectTable.vue'
import Paginator from '@/components/utilities/Paginator.vue'
import StretchModal from '@/components/utilities/StretchModal.vue'
import MultiselectTools from '@/components/utilities/MultiselectTools.vue'
import AddToBlastListModal from '@/features/EmailCommunication/MessageCenter/AddToBlastListModal.vue'

import SalesManagementAPI from '@/api/SalesManagementAPI.js'
import PaginatedRequest from '@/api/PaginatedRequest.js'
import POC from '@/features/SalesManagement/POC/POC.js'
import Tooltips from '@/libraries/Tooltips/Tooltips.js'

export default {
	name: 'ContactList',

	components: {
		PocDetails,
		SearchBox,
		ObjectTable,
		Paginator,
		StretchModal,
		MultiselectTools,
		AddToBlastListModal,
	},



	data() {
		return {
			page: 1,
			pages: 1,
			pageSize: 100,
			count: 0,
			sortBy: [],
			sortAsc: [],
			searchString: null,

			items: [],
			loading: false,
			error: false,

			selectedContact: null,
			selectedContacts: [],
			contactsToCopy: [],

			columns: [
				{
					displayName: 'First Name',
					propertyName: 'firstName',
					sortable: true,
				},
				{
					displayName: 'last Name',
					propertyName: 'lastName',
					sortable: true,
				},
				{
					displayName: 'Position',
					propertyName: 'position',
					sortable: true,
				},
				{
					displayName: 'E-Mail Addresses',
					propertyName: 'emails',
					displayFunction: function( item ) {
						const emails = []
						for( var e of item.emails ) emails.push( e.email )
						return emails.length > 3 ? emails.slice(0, 3).join( ',<br>' ) + `<br>...and ${emails.length - 3} more` : emails.join( ',<br>' )
					},
					sortable: true,
				},
				{
					displayName: 'Last E-Mailed',
					propertyName: 'lastEmailDate',
					displayFunction: function( item ) {
						const dates = []
						for( var e of item.emails ) dates.push( e.lastEmailDate ? e.lastEmailDate.toLocaleDateString() : null )
						return dates.length > 3 ? dates.slice(0, 3).join( ',<br>' ) + `<br>...and ${dates.length - 3} more` : dates.join( ',<br>' )
					},
					sortable: true,
				},
				{
					displayName: 'Phone Numbers',
					propertyName: 'phones',
					displayFunction: function( item ) {
						const phones = []
						for( var p of item.phones ) phones.push( p.phone )
						return phones.length > 3 ? phones.slice(0, 3).join( ',<br>' ) + `<br>...and ${phones.length - 3} more` : phones.join( ',<br>' )
					},
					sortable: true,
				},
				{
					displayName: 'Address',
					propertyName: 'addresses',
					displayFunction: function( item ) {
						var str = ''
						if( item.addresses.length > 0 ) str = item.addresses[0].addressHTML
						if( item.addresses.length > 1 ) str += `<br>...and ${ item.addresses.length -1 } more`
						return str
					},
					sortable: true,
				},
				{
					displayName: 'Affiliate',
					propertyName: 'affiliates',
					displayFunction: function( item ) {
						const orgs = []
						if( item.affiliates ) for( var aff of item.affiliates ) orgs.push( aff.name )
						// if( item.customers ) for( var cust of item.customers ) orgs.push( cust.name )
						return orgs.join( ',<br>' )
					},
					sortable: true,
				},
				{
					displayName: 'Customer',
					propertyName: 'customers',
					displayFunction: function( item ) {
						const orgs = []
						// if( item.affiliates ) for( var aff of item.affiliates ) orgs.push( aff.name )
						if( item.customers ) for( var cust of item.customers ) orgs.push( cust.name )
						return orgs.join( ',<br>' )
					},
					sortable: true,
				},
				{
					displayName: 'Date Created',
					propertyName: 'dateCreated',
					displayFunction: ( item ) => { return item.dateCreated ? item.dateCreated.toLocaleDateString() : null },
					sortable: true,
				},
				{
					displayName: 'Type / Source',
					propertyName: 'importV9',
					displayFunction: function( item ) {
						if( item.importV9 === true ) return 'imported from v9'
						else return 'v10'
					},
					sortable: true,
				}
			],
		}
	},



	created() { this.initialize() },



	computed: {
		t() { return Tooltips; },
	},
	
	
	
	watch: {
		pageSize() { this.page = 1; this.initialize() },
		searchString() { this.page = 1; this.initialize() },
	},



	methods: {

		async initialize() {
			this.items = []
			this.error = false
			this.loading = true

			try {
				const req = new PaginatedRequest( this.sortBy, this.sortAsc, this.page, this.pageSize, this.searchString )
				req.includeEmails = true
				req.includePhones = true
				req.includeAddresses = true
				req.includeAffiliates = true
				req.includeCustomers = true
				const pr = await SalesManagementAPI.getContacts( req )

				this.count = pr.count
				this.pages = pr.pages

				for( var item of pr.data ) this.items.push( POC.import( item ) )

			} catch (e) {
				this.error = true
				throw e

			} finally {
				this.loading = false
			}

		},



		
		composeEmail() {
			this.$store.state.pipes.send( 'teleport', { mode: 'messages.compose', props: { recipientsIn: [ ...this.selectedContacts ] }, header: `Message to ${ this.selectedContacts.length } Contacts` } )
		},




		singleSelect( item ) {
			this.selectedContact = item
			this.$refs.contactModal.open()
		},

		singleDeselect() {
			this.selectedContact = null
			this.$refs.objectTable.deselect()
		},


		// Multi-edit tools
		multiSelect( list ) {
			this.selectedContacts = [];
			for(let i=0; i < list.length; i++) {
				if(list[i] == true) this.selectedContacts.push( this.items[i] );
			}
		},


		
		async copySelectedToList() {
			this.contactsToCopy = [ ...this.selectedContacts ]
			this.$refs.addToListModal.open()
		},




		toPage( page ) {
			this.page = page
			this.initialize()
		},


		selectSortBy( prop ) {
			const idx = this.sortBy.indexOf( prop )
			if( idx > -1 ) {
				this.sortAsc[ idx ] = !this.sortAsc[ idx ]
				this.page = 1
				this.initialize()
				return
			}
			
			this.sortBy.push( prop )
			if (
				prop === 'dateCreated'
			) {
				this.sortAsc.push( false )
			} else {
				this.sortAsc.push( true )
			}

			this.page = 1
			this.initialize()
		},



		unsortBy( prop ) {
			const idx = this.sortBy.indexOf( prop )
			if( idx < 0 ) return

			this.sortBy.splice( idx, 1 )
			this.sortAsc.splice( idx, 1 )
			this.page = 1
			this.initialize()
		},


		csvDownload( selectedOnly = false, allPages = false ) {
			const req = new PaginatedRequest( this.sortBy, this.sortAsc, this.page, (allPages ? 0 : this.pageSize), this.searchString )
			req.includeEmails = true
			req.includePhones = true
			req.includeAddresses = true
			req.includeAffiliates = true
			req.includeCustomers = true

			if( selectedOnly ) {
				req.includeContactIDs = this.selectedContacts.map( elem => elem.id )
			}

			SalesManagementAPI.getContacts( req, true )
		}


	}


}
</script>



<style scoped>

</style>