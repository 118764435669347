<template>
<section 
	class="messageRow flex-row flex-align-center flex-justify-between pa-05 border round-05"
	:class="{ 'email' : message.deliveryMethod == 'email', 'sms' : message.deliveryMethod == 'sms', 'app' : message.deliveryMethod == 'app', 'selected' : isSelected, 'opened' : (message.deliveryMethod == 'email' && opened) || message.readTime }"
	@click.stop="$emit('open')"
>
	
	<!-- Body -->
	<div class="flex-row flex-align-center flex-justify-start" style="width: 100%; max-width: 100%;">
		<span v-if="message.deliveryMethod == 'email'" class="mr-1 icon-mail4" />
		<span v-else-if="message.deliveryMethod == 'sms'" class="mr-1 icon-mobile" />
		<span v-else-if="message.deliveryMethod == 'app'" class="mr-1 icon-bell" />

		<!-- TO -->
		<div v-if="message.deliveryMethod == 'email'" id="to" class="flex-row flex-align-center flex-gap-1 mr-1">
			<span>To:</span>
			<div class="toLines">
				<div v-for="item in message.to" :key="''+item.address+item.userID" class="toLine one-line ellipsis">
					{{ item.name || item.address }}
				</div>
			</div>
		</div>
		<div v-else-if="message.deliveryMethod == 'sms'" id="to" class="flex-row flex-align-center flex-gap-1 one-line ellipsis mr-1">
			To: {{ message.toName || message.toPhoneNumber }}
		</div>
		<div v-else-if="message.deliveryMethod == 'app'" id="to" class="flex-row flex-align-center flex-gap-1 one-line ellipsis mr-1">
			To: {{ message.to.address ? `@${ message.to.address }` : message.to.name }}
		</div>

		<!-- Preview -->
		<div v-if="hasSubject" id="subject" class="one-line ellipsis mr-05">{{ formatText( message.subject ) || '(no subject)' }}</div>
		<div v-if="message.deliveryMethod == 'email'" id="body" class="preview one-line ellipsis">-&nbsp;{{ formatText( message.body ) }}</div>
		<div v-if="message.deliveryMethod == 'sms'" id="body" class="preview one-line ellipsis">-&nbsp;{{ formatText( message.message ) }}</div>
		<div v-if="message.deliveryMethod == 'app'" id="body" class="preview one-line ellipsis">-&nbsp;{{ formatText( message.message ) }}</div>
		
	<!-- E-mail Status Badges -->
	<div v-if="message.deliveryMethod == 'email'" class="flex-row flex-align-center flex-justify-end flex-gap-05">
		<div v-for="[badgeName, addresses] in Object.entries( getDeliveryBadges( message ) )" :key="badgeName">
			<div
				class="tag statusTag font-size-0-8"
				:class="{ 'goodStatus' : badgeName === 'delivered' }"
				@mouseenter="t.s($event)"
				@mouseleave="t.h($event)"
				:tooltip="badgeName+' x '+addresses.length+':\n\n'+addresses.join( '\n' )"
			>
				{{ badgeName }}
			</div>
		</div>

		<div v-if="opened" class="icon-folder-open color-green" @mouseenter="t.s($event)" @mouseleave="t.h($event)" tooltip="Message has been opened" />
		<div v-else class="icon-folder color-orange-75" @mouseenter="t.s($event)" @mouseleave="t.h($event)" tooltip="Message is un-opened" />
	</div>
		
	<!-- App Message badges (Folder icon) -->
	<div v-if="message.deliveryMethod == 'app'" class="flex-row flex-justify-end">
		<div v-if="message.readTime" class="icon-folder-open color-green" @mouseenter="t.s($event)" @mouseleave="t.h($event)" tooltip="Message has been opened" />
		<div v-else class="icon-folder color-purple-75" @mouseenter="t.s($event)" @mouseleave="t.h($event)" tooltip="Message is un-opened" />
	</div>
	</div>


</section>
</template>



<script>
import Tooltips from '@/libraries/Tooltips/Tooltips.js'

export default {
	name: 'MessageRow',


	props: {
		message: Object,
		selectedMessage: {
			type: Object,
			default: null
		},
	},


	computed: {
		t() { return Tooltips },

		hasSubject() {
			return this.message.deliveryMethod == 'email' || this.message.deliveryMethod == 'app'
		},

		isSelected() {
			return this.selectedMessage === this.message
		},

		opened() {
			if( !this.message ) return false

			for( var recip of [ ...this.message.to, ...this.message.cc ] ) {
				if( recip.opens && recip.opens.length > 0 ) return true
			}

			return false
		}
	},


	methods: {
		formatText( text, maxLength = 250 ) {
			if( text === null || text === undefined ) return ''
			
			text = text.replace(/<[^>]+>/g, ' ');
			if( text.length > maxLength ) text = text.slice( 0, maxLength-5 ) + '...'
			return text
		},


		getDeliveryBadges() {
			const recipientList = [ ...this.message.to, ...this.message.cc, ...this.message.bcc ]
			const deliveryBadges = {}
			
			for( var to of recipientList ) {
				if( to.status ) {
					if( deliveryBadges[ to.status ] === undefined ) deliveryBadges[ to.status ] = [ to.address ]
					else deliveryBadges[ to.status ].push( to.address )
				}

				if( to.complaint ) {
					if( deliveryBadges[ to.complaint ] === undefined ) deliveryBadges[ to.complaint ] = [ to.address ]
					else deliveryBadges[ to.complaint ].push( to.address )
				}
			}

			return deliveryBadges
		}

	}
}
</script>



<style scoped>

.messageRow {
	cursor: pointer;
}

.messageRow.email {
	background-color: var(--pp10-orange-95);
	border: 3px solid var(--pp10-orange);
	font-weight: bold;
	color: var(--pp10-orange-35);
}

.messageRow.sms {
	background-color: var(--pp10-green-pastel-80);
	border: 3px solid var(--pp10-green-20);
	font-weight: bold;
	color: var(--pp10-green);
}

.messageRow.app {
	background-color: var(--pp10-purple-95);
	border: 3px solid var(--pp10-purple);
	font-weight: bold;
	color: var(--pp10-purple);
}

.messageRow.opened {
	border-width: 1px;
	font-weight: normal;
}



.messageRow.email.selected {
	border-width: 3px !important;
	background-color: var(--pp10-orange-85);
	color: var(--pp10-orange-35);

	box-shadow: 0 0 20px -8px var(--pp10-orange-30);
}

.messageRow.sms.selected {
	border-width: 3px !important;
	background-color: var(--pp10-green-85);
	color: var(--pp10-green-35);

	box-shadow: 0 0 20px -8px var(--pp10-green-30);
}

.messageRow.app.selected {
	border-width: 3px !important;
	background-color: var(--pp10-purple-85);
	color: var(--pp10-purple-35);

	box-shadow: 0 0 20px -8px var(--pp10-purple-30);
}



#to {
	width: min( 250px, 35vw );
	min-width: min( 250px, 35vw );
	max-width: min( 250px, 35vw );
	overflow: auto;
}

.toLines {
	max-height: 3em;
	overflow: auto;
	width: 100%;
}

.toLine {
	white-space: nowrap;
	width: 100%;
}

/* #subject {

} */

#body {
	flex-grow: 1;
	padding: 0;
}

#body.preview {
	color: #888;
}





.tag {
	padding: 0.25em 0.5em;
	border-radius: 1em;
}

.statusTag {
	background-color: var(--pp10-red-95);
	color: var(--pp10-red);
	border: 1px solid var(--pp10-red);
}
.statusTag.goodStatus {
	background-color: var(--pp10-green-95);
	color: var(--pp10-green);
	border: 1px solid var(--pp10-green);
}

.complaintTag {
	background-color: var(--pp10-orange-95);
	color: var(--pp10-orange-35);
	border: 1px solid var(--pp10-orange);
}
</style>