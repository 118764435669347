<template>
<section>

	<div v-if='!loading && !error'>

		<div class="flex-row flex-align-center flex-justify-between">
			
			<MultiselectTools :numSelected="selected.length" :showDelete="false" @all="$refs.objectTable.selectAll()" @none="$refs.objectTable.selectNone()">
				<span class="pillButton secondary border small flex-row flex-align-center flex-gap-05" @click="copySelectedToList()"><span class="icon-copy color-blue-60" /> create mailing list...</span>
			</MultiselectTools>

			<div class="flex-row flex-align-center flex-gap-05">
				<div class="mr-1">{{ count ? count.toLocaleString() : count }} orders on {{ pages }} pages</div>

				<div class="mr-1">
					<label>Per page: </label>
					<select v-model="pageSize">
						<option :value='25'>25</option>
						<option :value='50'>50</option>
						<option :value='100'>100</option>
						<option :value='250'>250</option>
						<option :value='500'>500</option>
					</select>
				</div>
				<span v-if="selected.length" class="icon-file-excel font-size-1-5 color-green-25" @click="getOrders( true, true )" @mouseenter="t.s($event)" @mouseleave="t.h($event)" :tooltip="`Download CSV of ${selected.length} selected orders`" />
				<span v-if="!selected.length" class="icon-file-excel font-size-1-5 color-blue-40" @click="getOrders( true )" @mouseenter="t.s($event)" @mouseleave="t.h($event)" :tooltip="`Download CSV of this page (${orders.length} orders)`" />
				<span v-if="!selected.length" class="icon-file-excel font-size-1-5 color-red-40" @click="getOrders( true, false, true )" @mouseenter="t.s($event)" @mouseleave="t.h($event)" :tooltip="`Download CSV of all ${count} orders`" />
			</div>
		</div>

		<div v-if='orders.length'>
			<ObjectTable
				:Source='orders'
				:tableStyle="getTableStyle()"
				:Columns='columns'
				:Numbered='true'
				:SortBy='sortBy'
				:SortAsc='sortAsc'
				:MultiSelect='true'
				@selectedItems='list => setSelected( list )'
				@edit='item => showOrderDetails( item )'
				@sort='prop => sort( prop )'
				ref='objectTable'
			/>
			<Paginator v-model="page" :numPages="pages" @input="pg => toPage( pg )" />
		</div>

		<div v-else class="NoResults">No orders</div>
	</div>

	<div v-if="error" class="warning">Failed to get records</div>


	<div v-if="orders.length">
		<UserDetailsModal :user="selectedUser" :show="showSelectedUser" @close="showSelectedUser = false" />
		<CustomerDetailsModal :customer="selectedCustomer" :show="showSelectedCustomer" @close="showSelectedCustomer = false" />
		<OrderDetailsModal
			:order="selectedOrder"
			:orderIndex="selectedOrderIndex"
			:numOrders="orders.length"
			@orderSaved="orderSaved"
			@next="selectNextOrder()"
			@previous="selectPreviousOrder()"
			@close="$refs.objectTable.deselect()"
			ref="selectedOrderModal"
		/>
	</div>

	<AddToBlastListModal :sourceList="usersToList" ref="addToListModal" />

</section>
</template>


<script>
import OrderDetailsModal from '@/features/orders/OrderDetailsModal.vue'
import ObjectTable from '@/components/utilities/ObjectTable.vue'
import Paginator from '@/components/utilities/Paginator.vue'
import MultiselectTools from '@/components/utilities/MultiselectTools.vue'
import AddToBlastListModal from '@/features/EmailCommunication/MessageCenter/AddToBlastListModal.vue'


import Order from '@/features/orders/Order.js'
import Customer from '@/features/SalesManagement/Customers/Customer.js'
import User from '@/features/Users/User.js'
import Tooltips from '@/libraries/Tooltips/Tooltips.js'

import PaginatedRequest from '@/api/PaginatedRequest.js'
import OrdersAPI from '@/api/OrdersAPI.js'
import UserAPI from '@/api/UserAPI.js'
import SalesManagementAPI from '@/api/SalesManagementAPI.js'
import StringUtils from '@/libraries/StringUtils'


export default {
	name: 'OrdersList',

	components: {
		UserDetailsModal: () => import('@/features/Users/UserDetailsModal.vue'),
		CustomerDetailsModal: () => import('@/features/SalesManagement/Customers/CustomerDetailsModal.vue'),
		OrderDetailsModal,
		ObjectTable,
		Paginator,
		MultiselectTools,
		AddToBlastListModal,
	},

	props: {
		userID: {
			type: Number,
			default: null
		},
		customerID: {
			type: Number,
			default: null
		},
		hasCustomer: {
			type: Boolean,
			default: null
		},
		affiliateID: {
			type: Number,
			default: null
		},
		hasAffiliate: {
			type: Boolean,
			default: null
		},
		orderState: {
			type: String,
			default: null
		},
		contractState: {
			type: [String, Boolean],
			default: null
		},
		searchString: {
			type: String,
			default: null
		},
		start: {
			type: [String, Number],
			default: null
		},
		end: {
			type: [String, Number],
			default: null
		},

		RenderLinks: Boolean
	},

	data() {
		return {
			orders: [],
			selected: [],
			usersToList: [],
			
			loading: false,
			error: null,

			selectedUser: null,
			selectedOrder: null,
			selectedOrderIndex: null,
			selectedCustomer: null,
			
			count: 0,
			page: 1,
			pages: 0,
			sortBy: 'timestamp',
			sortAsc: false,
			pageSize: 100,

			loadedAffiliates: new Map(),

			showSelectedUser: false,
			showSelectedCustomer: false,

			columns: [
				{
					displayName: 'ID',
					propertyName: 'orderID',
					sortable: true,
				},
				{
					displayName: 'User Info',
					propertyName: '',
					displayFunction: order => {
						const strs = []
						strs.push( '<div>' )
						if( order.firstName && order.lastName ) strs.push( `<span${ this.RenderLinks ? ' id="user" class="link"' : '' }>${ order.firstName } ${ order.lastName }</span>` )
						if( order.customerID && order.customerName ) strs.push( `<span${ this.RenderLinks ? ' id="customer" class="link"' : '' }>${ order.customerName }</span>`)
						strs.push( '</div>' )
						strs.push( `<div>${ order.emailAddress }</div>` )
						return strs.join('')
					},
					clickHandler: ( event, order, rowIndex ) => {
						if( event.target.id == 'user' ) this.showUserDetails( order.userID )
						else if( event.target.id == 'customer' ) this.showCustomerDetails( order.customerID )
						else this.showOrderDetails( order, rowIndex )
					}
				},
				{
					displayName: 'Affiliate / Source',
					propertyName: 'affiliateName',
					displayFunction: order => {
						const strs = []
						strs.push( order.affiliateName )
						if( order.affiliateName && order.paymentMethod ) strs.push( `<span> &mdash; </span>` )
						if( order.paymentMethod ) strs.push( `<span style="color: #888"${ order.paymentMethod.toUpperCase().includes('V9') ? ' class="v9order"' : '' }>${ StringUtils.ellipsify( order.paymentMethod, 30 ) }</span>`)
						return strs.join('')
					},
					sortByName: 'Affiliates.name',
					sortable: true,
				},
				{
					displayName: 'Price',
					propertyName: 'totalCost',
					displayFunction: order => `$${ order.totalCost }`,
					sortByName: 'total_cost',
					sortable: true
				},
				{
					displayName: 'Amt Paid',
					propertyName: 'totalPaid',
					displayFunction: order => {
						return `<span${ order.totalCost && (!order.totalPaid || order.totalPaid < order.totalCost) ? ' class="red"' : '' }>$${ order.totalPaid || '' }</span>`
					},
					sortByName: 'amountPaid',
					sortable: true
				},
				{
					displayName: 'Status',
					propertyName: 'status',
					displayFunction: order => {
						const spans = []
						if( order.isProposal ) spans.push( `<span class="proposed">Proposal</span>` )
						else if( !order.isPaid && !order.isFulfilled ) spans.push( `<span class="orange">Invoiced</span>` )
						else if( order.isPaid ? !order.isFulfilled : order.isFulfilled ) {  // LOGICAL XOR
							
							spans.push(`
							<span>
								<span${ !order.isPaid ? ' class="red"' : '' }>${ order.isPaid ? 'Paid' : 'Unpaid' }</span><br />
								<span${ !order.isFulfilled ? ' class="red"' : '' }>${ order.isFulfilled ? 'Fulfilled' : 'Unfulfilled' }</span>
							</span>`)

						} else if( order.isPaid && order.isFulfilled ) spans.push( `<span class="green">Complete</span>` )
						
						if( order.dateCanceled ) spans.push( `<span class="canceled"> (CANCELED)</span>`)
						return spans.join('')
					}
				},
				{
					displayName: 'Timestamp',
					propertyName: 'timestamp',
					displayFunction: order => {
						if( !order.timestamp ) return ''
						return order.timestamp.toLocaleString()
					},
					sortable: true
				}
			]
		}
	},


	created() {
		document.addEventListener("keydown", this.prevOrderKeyboardEvent, false)
		document.addEventListener("keydown", this.nextOrderKeyboardEvent, false)
		this.getOrders()
	},
	destroyed() {
		document.removeEventListener("keydown", this.prevOrderKeyboardEvent, false)
		document.removeEventListener("keydown", this.nextOrderKeyboardEvent, false)
	},



	computed: {
		t() { return Tooltips; },
	},



	watch: {
		customerID() { this.page = 1; this.getOrders() },
		hasCustomer() { this.page = 1; this.getOrders() },
		affiliateID() { this.page = 1; this.getOrders() },
		hasAffiliate() { this.page = 1; this.getOrders() },
		orderState() { this.page = 1; this.getOrders() },
		contractState() { this.page = 1; this.getOrders() },
		searchString() { this.page = 1; this.getOrders() },
		start() { this.page = 1; this.getOrders() },
		end() { this.page = 1; this.getOrders() },
		pageSize() { this.page = 1; this.getOrders() },
		page() { this.getOrders() },
	},



	methods: {

		showOrderDetails(order, index) {
			this.selectedOrder = order
			this.selectedOrderIndex = index
			this.$refs.selectedOrderModal.open()
		},

		async showUserDetails(userID) {
			if (!this.RenderLinks) return;

			this.selectedUser = await UserAPI.getUser(userID);
			this.showSelectedUser = true
		},

		async showCustomerDetails(customerID) {
			if (!this.RenderLinks) return;

			this.selectedCustomer = Customer.import(await SalesManagementAPI.getCustomer(customerID));
			this.showSelectedCustomer = true
		},


		async getOrders( asCSV = false, selectedOnly = false, allPages = false ) {
			this.loading = true
			this.error = null

			try {
				const req = new PaginatedRequest( this.sortBy, this.sortAsc, this.page, (allPages ? 0 : this.pageSize) )
				if( this.searchString ) req.searchString = this.searchString

				if( selectedOnly ) req.orderIDs = this.selected.map( order => order.orderID )

				req.userID = this.userID
				req.customerID = this.customerID
				req.hasCustomer = this.hasCustomer
				req.affiliateID = this.affiliateID
				req.hasAffiliate = this.hasAffiliate
				req.start = this.start
				req.end = this.end
				req.csv = asCSV

				req.orderState = this.orderState
				if(this.contractState == null) req.isContract = null
				if(this.contractState == false) req.isContract = false
				if(this.contractState == 'isContract') req.isContract = true
				if(this.contractState == 'isExecutedContract') req.isExecutedContract = true
				if(this.contractState == 'isActiveContract') req.isActiveContract = true
				if(this.contractState == 'isExpiredContract') req.isExpiredContract = true
				if(this.contractState == 'isPendingContract') req.isPendingContract = true

				const res = await OrdersAPI.getOrders( req, asCSV )
				if( asCSV ) return
				
				this.orders = []

				for(let obj of res.data) {
					let order = Order.import(obj)
					this.orders.push(order)
				} // for res.orders

				this.pages = res.pages
				this.count = res.count

			} catch( e ) {
				this.error = e.message
				throw e
			} finally {
				this.loading = false
			}
			
		},

		orderSaved(newOrder) {
			for(let i=0; i<this.orders.length; i++) {
				if(this.orders[i].orderID !== newOrder.orderID) continue;
				this.orders[i] = newOrder
				this.selectedOrder = newOrder
				return true;
			}
			return false;
		},

		async deleteOrder(orderID) {
			let res = confirm(`Are you sure you want to delete order #${orderID}?`);
			if(!res) return;
			await OrdersAPI.deleteOrder(orderID);
			await this.getOrders();
		},



		selectNextOrder() {
			if(this.selectedOrderIndex + 1 >= this.orders.length) return
			this.selectedOrder = this.orders[++this.selectedOrderIndex]
		},


		selectPreviousOrder() {
			if(this.selectedOrderIndex <= 0) return
			this.selectedOrder = this.orders[--this.selectedOrderIndex]
		},


		prevOrderKeyboardEvent(e) {
			if ((window.navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)  && e.keyCode == 37) {
				e.preventDefault();
				this.selectPreviousOrder();
			}
		},

		nextOrderKeyboardEvent(e) {
			if ((window.navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)  && e.keyCode == 39) {
				e.preventDefault();
				this.selectNextOrder();
			}
		},


	
		confirmClose(event, modalRef) {
			if( !modalRef.changesMade ) return

			const yes = confirm("You have unsaved changes that will be lost.  \nAre you sure you want to close this window?")
			if(!yes) event.stop()
		},




		// Multi-edit tools
		setSelected(list) {
			this.selected = [];
			for(let i=0; i < list.length; i++) {
				if(list[i] == true) this.selected.push( this.orders[i] );
			}
		},


		async copySelectedToList() {
			this.usersToList = []
			console.debug( this.selected )
			const userIDs = this.selected.map( order => order.userID ).filter( value => value != null )
			
			const res = await UserAPI.getUsersByID( userIDs )
			console.debug( res )

			this.usersToList = [ ...res.data.map( obj => User.import( obj ) ) ]
			this.$refs.addToListModal.open()
		},


		getTableStyle() {
			return `
			table * { font-size: 0.8rem; }
			.red {
				color: red;
				font-weight: bold;
			}

			.proposed { color: gray; }
			.orange { color: orange; }
			.green { color: green; }
			.canceled { color: purple; }
			.v9order { font-weight: bold; color: var(--ekno-blue) !important; }`
		},





		toPage( page ) {
			this.page = page
			this.getOrders()
		},


		sort( prop ) {
			
			if( this.sortBy === prop ) {
				this.sortAsc = !this.sortAsc
				this.page = 1
				this.getOrders()
				return
			}
			
			this.sortBy = prop
			if (
				prop === 'total_cost' ||
				prop === 'amountPaid' ||
				prop === 'timestamp'
			) {
				this.sortAsc = false
			} else {
				this.sortAsc = true
			}

			this.page = 1
			this.getOrders()
		},


	}
}
</script>



<style scoped>

</style>
