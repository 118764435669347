<template>
<section>
  <div class="flex-row flex-align-center flex-justify-end py-05">
    <button class='button' @click='newProduct'>New Product</button>
  </div>


  <div v-if='products.length'>
    <ObjectTable
      :Source="products"
      :Columns='columns'
      :Duplicatable='true'
      :Deletable='true'
      @edit='editProduct'
      @duplicate='duplicateProduct'
      @delete='deleteProduct'
      ref="objectTable"
    />
  </div>
  <div v-else class='NoResults'>No Results</div>


  <StretchModal ref="detailsModal" :clickToClose="false" :padding="'1em'" @close="$refs.objectTable.deselect()">
    <template #header>Product Details</template>
    <ProductDetails v-if="currentProduct" :Product="currentProduct" :EditMode='editMode' @added='updateProduct' />
  </StretchModal>

  <ConfirmDialog :show="productToDelete !== null" @cancel="productToDelete = null" @confirm="confirmDelete()">
    <div>Are you sure you want to delete this product?</div>
    <div style="font-size: 1.25em; font-weight: bold; margin: 0.25em 0;">{{ productToDelete ? `${productToDelete.name} (${productToDelete.sku})` : '' }}</div>
    <div><strong>This action CANNOT be undone!</strong></div>
  </ConfirmDialog>

</section>
</template>

<script>
import StretchModal from '@/components/utilities/StretchModal.vue'
import ConfirmDialog from '@/components/utilities/ConfirmDialog.vue'
import ObjectTable from '@/components/utilities/ObjectTable.vue'
import ProductDetails from './ProductDetails.vue'

import ProductsAPI from '@/api/ProductsAPI.js'
import Product from '@/models/Product.js'

export default {
  name: 'ProductsList',

  components: {
    StretchModal,
    ConfirmDialog,
    ObjectTable,
    ProductDetails,
  },

  data() {
    return {
      currentProduct: null,
      productToDelete: null,
      editMode: false,

      columns: [
        {
          displayName: 'SKU',
          propertyName: 'sku',
        },
        {
          displayName: 'Name',
          propertyName: 'name',
        },
        {
          displayName: 'Type',
          propertyName: 'productType',
          displayFunction: item => item.upgradableFrom.length ? 'Add-on' : 'Base'
        },
        {
          displayName: '$ Opts',
          propertyName: 'pricingOptionCount',
          displayFunction: item => item.pricingOptions.length
        },
        {
          displayName: 'Show',
          propertyName: 'showInStore',
        }
      ]
    }
  },

  async created() {
    try {
			this.$store.state.Products.objects = await ProductsAPI.getProducts()
		} catch (e) {
			console.error(e)
			alert( "An error occurred while loading courses" )
		}
  },

  computed: {
    products() {
      return this.productsList.objects;
    },

    productsList() {
      return this.$store.state.Products;
    }
  },

  methods: {
    editProduct(product) {
      this.currentProduct = product;
      this.editMode = true;
      this.$refs.detailsModal.open()
    },

    duplicateProduct(product) {
      let copy = Product.import(product.export());
      copy.sku = '';
      this.currentProduct = copy;
      this.editMode = false;
      this.$refs.detailsModal.open()
    },

    deleteProduct(product) {
      this.productToDelete = product
    },

    async confirmDelete() {
      const product = this.productToDelete
      try {
        await ProductsAPI.deleteProduct( product )
        this.$store.state.Products.remove( product )
      } catch (e) {
        console.error(e);
        alert("Error communicating with server; changes not saved.");
      }
      this.productToDelete = null
    },

    updateProduct() {

    },

    newProduct() {
      this.currentProduct = new Product();
      this.editMode = false;
      this.$refs.detailsModal.open()
    },

  }
}
</script>
