<template>
<section>
	<div class="flex-row flex-justify-between mt-05 mb-1">
		
		<div class="flex-row flex-align-center flex-justify-start flex-gap-05">
			<SearchBox placeholder="search (ctrl + /)" :autoSearch="true" @search="str => search(str)" ref="search" />
			<select v-model="selectedTag">
				<option :value="null">Select a tag</option>
				<option :value="false">No Tag</option>
				<option v-for="tag in availableTags" :key="tag" :value="tag">{{ tag }}</option>
			</select>
		</div>

		<div class="flex-row flex-align-center flex-gap-05 flex-wrap">
			<span class="icon-filter" :class="{ 'color-green' : hasUrlSlug !== null || hasLandingPageTemplate !== null || hasShortcutURL !== null }" />
			<select v-model="hasUrlSlug">
				<option :value="null">Do not filter on URL Slug</option>
				<option :value="true">Has URL Slug</option>
				<option :value="false">No URL Slug</option>
			</select>

			<select v-model="hasLandingPageTemplate">
				<option :value="null">Do not filter on Template</option>
				<option :value="true">Has Landing Page Template</option>
				<option :value="false">No Landing Page Template</option>
			</select>

			<select v-model="hasShortcutURL">
				<option :value="null">Do not filter on Shortcut URL</option>
				<option :value="true">Has Shortcut URL</option>
				<option :value="false">No Shortcut URL</option>
			</select>
		</div>
		
		<div class="flex-row flex-align-center flex-gap-2">
			<div>{{ count ? count.toLocaleString() : count }} affiliates on {{ pages }} pages</div>
			<div>
				<label>Per page: </label>
				<select v-model="pageSize">
					<option :value='25'>25</option>
					<option :value='50'>50</option>
					<option :value='100'>100</option>
					<option :value='250'>250</option>
					<option :value='500'>500</option>
				</select>
			</div>
			<button class="button inline-block" @click="newItem">New Affiliate</button>
		</div>

	</div>

	<div class='flex justify-center' v-if='showSpinner'>
		<div class='icon-spinner4 spin-loader'></div>
	</div>

	<div v-if='!showSpinner'>

		<div v-if='items.length'>
			<ObjectTable
				class='font-small'
				:Source='items'
				:Columns='columns'
				:Numbered='false'
				:SortBy='sortBy'
				:SortAsc='sortAsc'
				:multisort='true'
				Deletable
				@delete='deleteItem'
				@edit="editItem"
				@sort='prop => selectSortBy( prop )'
				@unsort='prop => unsortBy( prop )'
				ref="objectTable"
			/>
			<Paginator v-model="page" :numPages="pages" @input="pg => toPage( pg )" />
		</div>
		<div v-else class="NoResults">No results</div>

	</div>

	<StretchModal ref="detailsModal" :clickToClose="false" @close="$refs.objectTable.deselect()" minHeight="95vh">
		<template #header>Affiliate #{{ currentItem.id }}: {{ currentItem.name }} <span class="font-size-0-8 color-gray-50"> - press ESC to close</span></template>
		<AffiliateDetails v-if="currentItem" :itemIn="currentItem" :isNew="isNew" @itemAdded="itemAdded" @itemEdited="itemEdited" @close="$refs.detailsModal.close()" />
	</StretchModal>

</section>
</template>



<script>
import ObjectTable from '@/components/utilities/ObjectTable.vue'
import Paginator from '@/components/utilities/Paginator.vue'
import AffiliateDetails from './AffiliateDetails.vue'
import StretchModal from '@/components/utilities/StretchModal.vue'
import SearchBox from '@/components/utilities/SearchBox.vue'

import SalesManagementAPI from '@/api/SalesManagementAPI.js'
import GenericTagsAPI from '@/api/GenericTagsAPI.js'
import PaginatedRequest from '@/api/PaginatedRequest.js'
import LandingPage from '../LandingPages/LandingPage.js'
import Affiliate from './Affiliate.js'


export default {
	name: 'AffiliateList',


	components: {
		ObjectTable,
		Paginator,
		AffiliateDetails,
		StretchModal,
		SearchBox,
	},

	data() {
		return {
			items: [],
			currentItem: null,
			columns: [
				{
					displayName: 'id',
					propertyName: 'id',
					sortable: true
				},
				{
					displayName: 'Name',
					propertyName: 'name',
					sortable: true
				},
				{
					displayName: 'Tags',
					propertyName: 'tags',
					displayFunction: item => {
						return item.tags.join(', ')
					},
					maxWidth: '7vw',
					maxHeight: '4.5em',
					scroll: true,
				},
				{
					displayName: '# Orders',
					propertyName: 'orderCount',
					sortable: true
				},
				{
					displayName: 'Most Recent Order',
					propertyName: 'recentOrderTimestamp',
					displayFunction: item => {
						if( item.recentOrderTimestamp === null || item.recentOrderTimestamp === undefined ) return null
						const d = new Date( item.recentOrderTimestamp * 1000 )
						return d.toLocaleDateString()
					},
					sortable: true,
				},
				{
					displayName: 'URL Slug',
					propertyName: 'urlSlug',
					sortable: true,
				},
				{
					displayName: 'Landing Page Template',
					propertyName: 'landingPageTemplateLabel',
					sortable: true
				},
				{
					displayName: 'Shortcut URLs',
					propertyName: 'shortcutURLs',
					displayFunction: item => {
						if( !item.shortcutURLs ) return null
						
						const urls = []
						const showNumURLs = 2
						const numURLs = item.shortcutURLs.length

						// Show the first few URLs, along with "+X more..." if needed
						for( let i=0; i < showNumURLs && i < numURLs; i++ ) urls.push( `<div class="one-line">${item.shortcutURLs[i].getFQDN( true )}</div>` )
						if( numURLs > showNumURLs ) urls.push( `<div style="color: var(--ekno-blue); font-weight: bold;">+${ numURLs - showNumURLs } more...</div>`)
						return urls.join('')
					},

					itemTooltip: item => {
						if( !item.shortcutURLs || item.shortcutURLs.length <= 2 ) return ''
						const urls = []
						for( const l of item.shortcutURLs ) urls.push( l.getFQDN( true ) )
						return urls.join( "\n" )
					},
					sortable: true,
					maxWidth: '17vw',
					scroll: true
				},
				{
					displayName: 'V9 Coupon Code',
					propertyName: 'couponCode',
					sortable: true
				},
				{
					displayName: '# Contacts',
					propertyName: 'contactCount',
					sortable: true
				},
				{
					displayName: 'Date Created',
					propertyName: 'dateCreated',
					displayFunction: function( item ) { return item.dateCreated ? item.dateCreated.toLocaleDateString() : '' },
					sortable: true
				}
			],

			availableTags: [],
			selectedTag: null,
			hasUrlSlug: null,
			hasLandingPageTemplate: null,
			hasShortcutURL: null,

			searchString: null,
			sortBy: [ 'orderCount' ],
			sortAsc: [ false ],
			count: 0,
			page: 1,
			pageSize: 100,
			pages: null,
			
			showSpinner: false,
			isNew: false,

			landingPages: new Map(),
		}
	},



	async created() {
		this.getAffiliates()
		await this.getAffiliateTags()
		this.getLandingPages()
	},


	mounted() {
		window.addEventListener('keyup', this.focusSearchBox)
	},
	destroyed() {
			window.removeEventListener('keyup', this.focusSearchBox)
	},


	watch: {
		pageSize() { this.page = 1; this.getAffiliates() },
		selectedTag() { this.page = 1; this.getAffiliates() },
		hasUrlSlug() { this.page = 1; this.getAffiliates() },
		hasLandingPageTemplate() { this.page = 1; this.getAffiliates() },
		hasShortcutURL() { this.page = 1; this.getAffiliates() },
	},



	methods: {

		async getAffiliateTags() {
			this.availableTags = await GenericTagsAPI.getTags( 'affiliate' )
		},



		async getAffiliates() {
			this.showSpinner = true
			this.items = []
			const req = new PaginatedRequest( this.sortBy, this.sortAsc, this.page, this.pageSize )
			req.searchString = this.searchString
			req.includeRoutes = true
			req.hasUrlSlug = this.hasUrlSlug
			req.hasLandingPageTemplate = this.hasLandingPageTemplate
			req.hasShortcutURL = this.hasShortcutURL
			
			if( this.selectedTag === null ) req.tags = null
			else if( this.selectedTag === false ) req.tags = false
			else req.tags = [ this.selectedTag ]
			
			let res = await SalesManagementAPI.getAffiliates( req )
			
			this.count = res.data.count
			this.pages = res.data.pages
			for(let item of res.data.data) this.items.push( Affiliate.import(item) )
			this.showSpinner = false
		},


		async getLandingPages() {
			this.landingPages.clear()
			const res = await SalesManagementAPI.getLandingPages()
			for(let item of res) {
				const lp = LandingPage.import( item )
				this.landingPages.set( lp.id, lp )
			}
			if( this.$refs.objectTable ) this.$refs.objectTable.$forceUpdate()
		},



		focusSearchBox( event ) {
			if( event.key !== '/' ) return
			this.$refs.search.focus()
		},



		search( str ) {
			this.searchString = str ? str : null
			this.getAffiliates()
		},



		async editItem(item) {
			this.isNew = false
			const data = await SalesManagementAPI.getAffiliateByID(item.id)
			this.currentItem = Affiliate.import(data)
			this.$refs.detailsModal.open()
		},

		async deleteItem(item) {
			let yes = confirm(`Really delete Affiliate '${item.name}' (${item.urlSlug})?`)
			if(!yes) return

			await SalesManagementAPI.deleteAffiliate( item.id ).catch( e => {
				console.error( e.message )
				alert( `FAILED to delete affiliate #${item.id} ${item.name}:\n\n${e.message}` )
				throw e
			})

			let index = 0
			for(let obj of this.items) {
				if(obj.id == item.id) break
				index++
			}
			this.items.splice(index, 1)
			if(!item.id) return

		},


		newItem() {
			this.isNew = true
			this.currentItem = new Affiliate()
			this.$refs.detailsModal.open()
		},


		itemAdded(addedItem) {
			this.currentItem = addedItem
			this.items.push(addedItem)
			this.isNew = false
		},


		itemEdited(changedItem) {
			this.currentItem = changedItem

			for(let i=0; i<this.items.length; i++) {
				if(this.items[i].id != changedItem.id) continue
				this.$set(this.items, i, changedItem)
				return
			}
		},


		toPage( page ) {
			this.page = page
			this.getAffiliates()
		},


		selectSortBy(prop) {
			const idx = this.sortBy.indexOf( prop )
			if( idx > -1 ) {
				this.sortAsc[ idx ] = !this.sortAsc[ idx ]
				this.page = 1
				this.getAffiliates()
				return
			}
			
			this.sortBy.push( prop )
			if (
				prop === 'orderCount' || prop === 'recentOrderTimestamp'
			) {
				this.sortAsc.push( false )
			} else {
				this.sortAsc.push( true )
			}

			this.page = 1
			this.getAffiliates()
		},


		unsortBy( prop ) {
			const idx = this.sortBy.indexOf( prop )
			if( idx < 0 ) return

			this.sortBy.splice( idx, 1 )
			this.sortAsc.splice( idx, 1 )
			this.page = 1
			this.getAffiliates()
		},
	},



}
</script>



<style scoped>

</style>
