import Exim from '@/models/Exim.js'
import Message from './Message.js'


class MessageBlast extends Exim {


	messageBlastID = null     // If used to load/save a draft or sent message
	recipients = []           // array<Recipient> - could be a user (via userID), POC (via pocID), BlastList (via listID) or any email address: [ { name: string, address: string, userID: int|null, pocID: int|null }, ... ]
	message = { subject: null, htmlBody: null, plainBody: null }
	
	// Delivery methods
	deliverEmail = false
	deliverSMS = false
	deliverApp = false
	autoPopApp = false
	
	overrideDeliveryPreferences = true
	
	// For in-app Notifications
	courseID = null
	
	// Send settings
	useMailMerge = false
	bccAdmins = false
	hourlySendRate = null


	// Sender info (optional)
	fromID = null        // userID of sender
	fromName = null
	fromEmail = null     // reply-to


	// Actual email "FROM" address
	fromEmailUsername = null  // i.e. "PowerPrep 10" or "Bob Smith" or "Alice Jones" or "Do Not Reply"
	fromEmailMailbox = null   // i.e. "accounts" or "Bob" or "Alice" or "noreply" | domain is set in Config.php, i.e. @powerprep10.eknowledge.com


	// Used for drafts / sent messages
	createdTimestamp = null
	modifiedTimestamp = null
	sentTimestamp = null
	creatorID = null
	ownerID = null
	creatorName = null
	ownerName = null


	// Scheduled-send information
	scheduledTime = null

	// LongJob
	job = null           // for job-control widgets
	jobID = null
	jobComplete = null
	jobError = null
	jobStartTime = null
	jobEndTime = null


	// Sent-message statistics
	deliveryStats = null

	// Message-send job (LongJob)



	isPropertyChanged( property, value ) {
		
		if( property === 'message' ) {
			if( this.message.subject !== value.subject || this.message.htmlBody !== value.htmlBody || this.message.plainBody !== value.plainBody ) return true
			else return false
		}

		if( this[ property ] !== undefined && this[ property ] === value ) return false
		return true
	}
	


	isDefault() {
		if( this.messageBlastID != null ) return false
		if( this.recipients.length > 0 ) return false
		if( this.message.subject || this.message.htmlBody || this.message.plainBody ) return false
		if( this.deliverEmail ) return false
		if( this.deliverSMS ) return false
		if( this.deliverApp ) return false
		if( !this.overrideDeliveryPreferences ) return false
		if( this.courseID ) return false
		if( this.useMailMerge ) return false
		// if( this.fromID ) return false
		if( this.fromName ) return false
		if( this.fromEmail ) return false
		if( this.fromEmailUsername ) return false
		if( this.fromEmailMailbox ) return false

		return true
	}



	static import( obj ) {
		const item = super.import( obj, [ 'recipients', 'message' ], [ 'createdTimestamp', 'modifiedTimestamp', 'sentTimestamp', 'scheduledTime', 'jobStartTime', 'jobEndTime' ] )

		if( obj.message ) item.message = Message.import( obj.message )
		if( obj.recipients ) {
			for( const r of obj.recipients ) item.recipients.push( r )
		}

		return item
	}


}

export default MessageBlast