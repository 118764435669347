import API from "./API";
// import PaginatedRequest from './PaginatedRequest.js'
import PaginatedResponse from './PaginatedResponse.js'


class SubscriptionAPI extends API {

	static controllerName = 'SubscriptionAPI'

	/**
	 * Get a PaginatedResponse representing a set of subscriptions
	 * @param {PaginatedRequest} req 
	 * @returns res
	 */
	static async getSubscriptions( req ) {
		const res = await this.newPost( 'getSubscriptions', [], req )
		return PaginatedResponse.import( res.data )
	}


	static async getSubscription( subscriptionID ) {
		const res = await this.newGet( 'getSubscription', [ subscriptionID ] )
		return res
	}


	static async getSubscriptionLifetimePaid( subscriptionID ) {
		const res = await this.newGet( 'getSubscriptionLifetimePaid', [ subscriptionID ] )
		return res
	}


	static async updateSubscriptionStatus( licenseKey, status ) {
		return await this.newGet( 'updateSubscriptionStatus', [ licenseKey, status ] )
	}

}

export default SubscriptionAPI;
