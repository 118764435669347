import API from '@/api/API.js'
import PaginatedResponse from '@/api/PaginatedResponse.js'



export default class MessageCenterAPI extends API {

	static controllerName = 'MessageCenterAPI';


	static getAvailableMergeTags( req ) {
		return this.newPost( 'getAvailableMergeTags', [], req )
	}


	static validateRecipients( req ) {
		return this.newPost( 'validateRecipients', [], req )
	}


	static getPreflightReport( req ) {
		return this.newPost( 'getPreflightReport', [], req )
	}


	static getMailMergePreviews( req ) {
		return this.newPost( 'getMailMergePreviews', [], req )
	}


	static send( req ) {
		return this.newPost( 'send', [], req )
	}


	static schedule( req ) {
		return this.newPost( 'schedule', [], req )
	}


	static getScheduled( req ) {
		return this.newPost( 'getScheduled', [], req )
	}


	static deleteScheduled( messageBlastID ) {
		return this.newGet( 'deleteScheduled', [ messageBlastID ] )
	}


	static getSentMessageSources( req ) {
		return this.newPost( 'getSentMessageSources', [], req )
	}


	// ----------------------------------------------------------------
	// Sent Messages (MessageBlasts / "Campaigns")
	// ----------------------------------------------------------------

	static async getMessageBlasts( req ) {
		const res = await this.newPost( 'getMessageBlasts', [], req )
		return PaginatedResponse.import( res.data )
	}

	static getMessageBlast( messageBlastID ) {
		return this.newGet( 'getMessageBlast', [ messageBlastID ] )
	}

	static saveMessageBlast( messageBlast ) {
		return this.newPost( 'saveMessageBlast', [], messageBlast )
	}

	static patchMessageBlast( req ) {
		return this.newPost( 'patchMessageBlast', [], req )
	}

	static deleteMessageBlast( messageBlastID ) {
		return this.newGet( 'deleteMessageBlast', [ messageBlastID ] )
	}


	// ----------------------------------------------------------------
	// MessageBlast Details
	// ----------------------------------------------------------------

	static async getMessageBlastEmailRecipients( messageBlastID, req ) {
		const res = await this.newPost( 'getMessageBlastEmailRecipients', [ messageBlastID ], req )
		return PaginatedResponse.import( res.data )
	}

	static async getMessageBlastSMSRecipients( messageBlastID, req ) {
		const res = await this.newPost( 'getMessageBlastSMSRecipients', [ messageBlastID ], req )
		return PaginatedResponse.import( res.data )
	}

	static async getMessageBlastAppRecipients( messageBlastID, req ) {
		const res = await this.newPost( 'getMessageBlastAppRecipients', [ messageBlastID ], req )
		return PaginatedResponse.import( res.data )
	}

	
	// ----------------------------------------------------------------
	// Sent Messages (individual / merged)
	// ----------------------------------------------------------------

	static async getSentEmails( req ) {
		const res = await this.newPost( 'getSentEmails', [], req )
		return PaginatedResponse.import( res.data )
	}

	static async getSentSMS( req ) {
		const res = await this.newPost( 'getSentSMS', [], req )
		return PaginatedResponse.import( res.data )
	}

	static async getSentApp( req ) {
		const res = await this.newPost( 'getSentApp', [], req )
		return PaginatedResponse.import( res.data )
	}

	static async getTimeBasedDeliveryStatistics( startTime = null, endTime = null ) {
		return this.newPost( 'getTimeBasedDeliveryStatistics', [], { startTime, endTime } )
	}

	static async getMessageDeliveryStatistics( req ) {
		return this.newPost( 'getMessageDeliveryStatistics', [], req )
	}



	// ----------------------------------------------------------------
	// Contact "Blast" Lists
	// ----------------------------------------------------------------

	static async getBlastLists( req ) {
		const res = await this.newPost( 'getBlastLists', [], req )
		return PaginatedResponse.import( res.data )
	}
	
	static async getBlastList( id ) {
		return this.newPost( 'getBlastList', [ id ] )
	}

	static async saveBlastList( list ) {
		return this.newPost( 'saveBlastList', [], list )
	}

	static async addBlastListColumn( listID, colName ) {
		return this.newPost( 'addBlastListColumn', [ listID ], { column: colName } )
	}

	static async deleteBlastListColumn( listID, colName ) {
		return this.newPost( 'deleteBlastListColumn', [ listID ], { column: colName } )
	}

	static async deleteBlastList( listID ) {
		return this.newGet( 'deleteBlastList', [ listID ] )
	}


	static async getBlastListCSV( listID ) {
		const res = await this.newPost( 'getBlastListCSV', [ listID ], null, 'blob' )
		this.processResponseAsFileDownload( res.data )
	}

	static async uploadBlastListCSV( fileContents, listName, listDescription ) {
		return this.newPost( 'uploadBlastListCSV', [], { file: fileContents, name: listName, description: listDescription } )
	}
	
	static async getBlastListRows( listID, req ) {
		const res = await this.newPost( 'getBlastListRows', [ listID ], req )
		return PaginatedResponse.import( res.data )
	}

	static async saveBlastListRows( listID, rows ) {
		return this.newPost( 'saveBlastListRows', [ listID ], rows )
	}

	static async patchBlastListRow( req ) {
		return this.newPost( 'patchBlastListRow', [], req )
	}

	static async deleteBlastListRows( listID, rowIDs ) {
		return this.newPost( 'deleteBlastListRows', [ listID ], { rowIDs } )
	}

	static async deleteDuplicateEntries( listID, preflight = false ) {
		return this.newGet( 'deleteDuplicateEntries', [ listID, preflight ] )
	}

	static async getBlastListDefectReport( listID ) {
		return this.newGet( 'getBlastListDefectReport', [ listID ] )
	}

	static async enrichBlastList( listID ) {
		return this.newGet( 'enrichBlastList', [ listID ] )
	}

	static async combineBlastLists( req ) {
		return this.newPost( 'combineBlastLists', [], req )
	}



	// ----------------------------------------------------------------
	// Job Control
	// ----------------------------------------------------------------

	static async getMessageBlastJobs( req ) {
		return this.newPost( 'getMessageBlastJobs', [], req )
	}



	// ----------------------------------------------------------------
	// Message Templates
	// ----------------------------------------------------------------
	
	static async getMessageTemplates( req ) {
		const res = await this.newPost( 'getMessageTemplates', [], req )
		return PaginatedResponse.import( res.data )
	}


	static async saveMessageTemplate( tmp ) {
		return this.newPost( 'saveMessageTemplate', [], tmp )
	}


	static async deleteMessageTemplate( id ) {
		return this.newGet( 'deleteMessageTemplate', [ id ] )
	}



	// ----------------------------------------------------------------
	// Unsubscribe List
	// ----------------------------------------------------------------

	static async getUnsubscribeList( req ) {
		const res = await this.newPost( 'getUnsubscribeList', [], req )
		return PaginatedResponse.import( res.data )
	}

	static async deleteUnsubscribeRecord( req ) {
		return this.newPost( 'deleteUnsubscribeRecord', [], req )
	}

	
}