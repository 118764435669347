<template>
<section>
	<div class="py-05 flex-row flex-justify-between flex-align-center" style="position: sticky; top: 0; background: white;">
		<div>
			<SearchBox placeholder="Search Name + Address..." @search="search" />
			&nbsp;
			<label>Per page: </label>
			<select v-model="pageSize">
				<option :value='25'>25</option>
				<option :value='50'>50</option>
				<option :value='100'>100</option>
				<option :value='250'>250</option>
				<option :value='500'>500</option>
			</select>
		</div>

		<div class="flex-row flex-align-center">
			<div class="mr-2">{{ count ? count.toLocaleString() : count }} customers on {{ pages }} pages</div>
			<button class='button' @click='newCustomer'>New Customer</button>
		</div>
	</div>

	<div class="flex-row flex-justify-center mb-1">
		<div style="border-right: 1px solid #ccc;">
			<p class="center link" @click="$refs.contractsList.open()">Active Contracts</p>
			<div class="card clickable" @click="$refs.contractsList.open()">
				<div style="font-size: 20pt;">${{dollarsUnderContract}}</div>
				<div>Under Contract</div>
			</div>
			<div class="card clickable" @click="$refs.contractsList.open()">
				<div style="font-size: 20pt;">{{numContracts}}</div>
				<div>Active Contracts</div>
			</div>
		</div>
		<div style="border-right: 1px solid #ccc;">
			<p class="center link" @click="$refs.oipList.open()">Orders In-Process</p>
			<div class="card clickable" @click="$refs.oipList.open()">
				<div style="font-size: 20pt;">${{dollarsInProcess}}</div>
				<div>In Process</div>
			</div>
			<div class="card clickable" @click="$refs.oipList.open()">
				<div style="font-size: 20pt;">{{numOrdersInProcess}}</div>
				<div>Orders in Process</div>
			</div>
		</div>
		<div>
			<p class="center">Licenses</p>
			<div class="card">
				<div style="font-size: 20pt;">{{licensesCreated}}</div>
				<div>Active Licenses</div>
			</div>
			<div class="card">
				<div style="font-size: 20pt;">{{licensesAssigned}}</div>
				<div>Assigned Licenses</div>
			</div>
		</div>
	</div>
	<div class='font-small'>

		<ButtonModal modalTitle="Active Contracts" :showButton="false" ref="contractsList">
			<OrdersList
				:contractState="'isActiveContract'"
				:RenderLinks='true' />
		</ButtonModal>

		<ButtonModal modalTitle="Orders In-Process" :showButton="false" ref="oipList">
			<OrdersList
				:orderState="'incomplete'"
				:RenderLinks='true' />
		</ButtonModal>

		<ObjectTable
			class='font-small'
			ref="objectTable"
			:Source='customers'
			:Columns='columns'
			:PageNum='page'
			:Numbered='true'
			:MultiSelect='false'
			:PageSize='pageSize'
			:SortBy='sortBy'
			:SortAsc='sortAsc'
			Deletable
			@delete='deleteCustomer'
			@edit="showDetails"
			@sort="selectSortBy"
		/>

		<CustomerDetailsModal :customer="currentCustomer" :isNew="isNew" :show="currentCustomer !== null" @itemAdded="itemAdded" @itemEdited="itemEdited" @close="hideDetails()" />

		<p v-if="!customers.length" style="text-align:center;">No customers</p>
		<Paginator v-if="customers.length" v-model="page" :numPages="pages" />
	</div>
</section>
</template>

<script>
import Customer from './Customer.js'
import SalesManagementAPI from '@/api/SalesManagementAPI.js'
import OrdersAPI from '@/api/OrdersAPI.js'
import CustomerListRequest from '@/api/CustomerListRequest.js'

import ObjectTable from '@/components/utilities/ObjectTable.vue'
import ButtonModal from '@/components/utilities/ButtonModal.vue'
import OrdersList from '@/features/orders/OrdersList.vue'
import CustomerDetailsModal from './CustomerDetailsModal.vue'
import Paginator from '@/components/utilities/Paginator.vue'
import SearchBox from '@/components/utilities/SearchBox.vue';

export default {
	name: 'Customer',

	components: {
		ButtonModal,
		ObjectTable,
		OrdersList,
		CustomerDetailsModal,
		Paginator,
		SearchBox,
	},



	data() {
		return {
			customers: [],
			currentCustomer: null,

			dollarsUnderContract: '--',
			numContracts: '--',
			numOrdersInProcess: '--',
			dollarsInProcess: '--',
			licensesCreated: '--',
			licensesAssigned: '--',

			count: 0,
			page: 1,
			pageSize: 50,
			pages: 1,

			isNew: false,

			searchString: null,
			sortBy: 'nextActionDate',
			sortAsc: true,

			columns: [
				{
					displayName: 'ID',
					propertyName: 'id',
					sortable: true
				},
				{
					displayName: 'Name',
					propertyName: 'name',
					displayFunction: item => `<div style="color: #444; font-size: 1.1em;">${item.name}</div><div style="color: #777;">${item.city}, ${item.state}</div>`,
					sortable: true
				},
				{
					displayName: 'Initial Status',
					propertyName: 'initialStatusString',
					sortByName: 'initialStatus',
					sortable: true,
					style: function(cust) {
						if(cust.initialStatus === null) return { textAlign: 'center' }
						return {
							backgroundColor: cust.constructor.getStatusBGColor(cust.initialStatus), 
							color: cust.constructor.getStatusFGColor(cust.initialStatus),
							textAlign: 'center', 
							fontWeight: 'bold',
							borderBottom: '1px solid white', 
							borderRight: '1px solid white'
						}
					}
				},
				{
					displayName: 'Renewal Status',
					propertyName: 'renewStatusString',
					sortByName: 'renewStatus',
					sortable: true,
					style: function(cust) {
						if(cust.renewStatus === null) return { textAlign: 'center' }
						return {
							backgroundColor: cust.constructor.getStatusBGColor(cust.renewStatus), 
							color: cust.constructor.getStatusFGColor(cust.renewStatus),
							textAlign: 'center', 
							fontWeight: 'bold',
							borderBottom: '1px solid white', 
						}
					}
				},
				// {
				// 	displayName: 'old',
				// 	propertyName: 'oldContractCount',
				// 	sortable: true
				// },
				// {
				// 	displayName: 'new',
				// 	propertyName: 'newContractCount',
				// 	sortable: true
				// },
				// {
				// 	displayName: 'inv',
				// 	propertyName: 'newInvoicedCount',
				// 	sortable: true
				// },
				// {
				// 	displayName: 'PO',
				// 	propertyName: 'newPOReceivedCount',
				// 	sortable: true
				// },
				// {
				// 	displayName: 'Paid',
				// 	propertyName: 'newPaidCount',
				// 	sortable: true
				// },
				{
					displayName: 'Lic',
					propertyName: 'licenses',
					sortByName: 'assignedLicenseCount',
					displayFunction: item => {
						if(!item.assignedLicenseCount && !item.activeLicenseCount) return ''
						return `${item.assignedLicenseCount || 0} / ${item.activeLicenseCount || 0}`
					},
					sortable: true
				},
				{
					displayName: 'OIP',
					propertyName: 'ordersInProcess',
					sortByName: 'inProcessOrderCount',
					sortable: true
				},
				{
					displayName: 'Conv',
					propertyName: 'confirmedOrderCount',
					sortByName: 'confirmedOrderCount',
					sortable: true
				},
				{
					displayName: 'Next Action',
					propertyName: 'nextActionDate',
					sortable: true,
					displayFunction: item => `<div><b>${item.nextActionName || ''}</b><br>${item.nextActionDateString || ''}</div>`,
					style: function(cust) {
						if (cust.nextActionPastDue) return { background: '#faa', color: 'darkred' }
						else if (cust.needsAttention()) return { background: '#fda', padding: '3px' }
					}
				},
				{
					displayName: 'Customer Since',
					propertyName: 'createdDateString',
					sortByName: 'dateCreated',
					sortable: true
				},
			],

		}
	},



	created() {
		this.getCustomers();
		this.getContractStatistics();
	},



	watch: {
		page() { this.getCustomers() },
		pageSize() { this.getCustomers() },
	},



	methods: {
		showDetails(customer) {
			this.isNew = false;
			this.currentCustomer = customer;
		},
		hideDetails() {
			this.currentCustomer = null
			this.isNew = false
			this.getCustomers()
		},

		newCustomer() {
			this.isNew = true;
			this.currentCustomer = new Customer();
		},


		search(searchStr = '') {
			this.searchString = searchStr !== '' ? searchStr : null
			this.getCustomers()
		},


		async getCustomers() {
			let req = new CustomerListRequest(this.sortBy, this.sortAsc, this.page, this.pageSize, this.searchString, true, true, true)
			const data = await SalesManagementAPI.getCustomers(req);
			if(!data || !data.data) return;

			this.customers = [];
			for(let item of data.data) this.customers.push( Customer.import(item) );
			this.pages = data.pages;
			this.count = data.count;
		},

		async getContractStatistics() {
			const data = await OrdersAPI.getContractStatistics()
			this.numContracts = data.numContracts
			this.dollarsUnderContract = data.contractDollars
			this.numOrdersInProcess = data.numOrdersInProcess
			this.dollarsInProcess = data.oipDollars
			this.licensesCreated = data.licensesCreated
			this.licensesAssigned = data.licensesAssigned
		},


		async deleteCustomer(customer) {
			const yes = confirm(`Are you sure you want to delete customer '${customer.name}'?`);
			if(!yes) return;

			await SalesManagementAPI.deleteCustomer(customer.id);
			this.getCustomers();
		},


		itemAdded(addedItem) {
			this.currentCustomer = addedItem;
			this.customers.push(addedItem);
			this.isNew = false;
		},


		itemEdited(changedItem) {
			this.currentCustomer = changedItem;

			for(let i=0; i<this.customers.length; i++) {
				if(this.customers[i].id != changedItem.id) continue;
				this.$set(this.customers, i, changedItem)
				return;
			}
		},



		selectSortBy(prop) {

			if (prop == this.sortBy) {
				this.sortAsc = !this.sortAsc
				this.getCustomers();
				return
			}

			this.sortBy = prop;
			this.sortAsc = true;

			if (this.sortBy === 'activeLicenseCount' || this.sortBy === 'assignedLicenseCount' || this.sortBy === 'inProcessOrderCount' || this.sortBy === 'confirmedOrderCount') {
				this.sortAsc = false;
			} else this.sortAsc = true;

			this.getCustomers();
		}


	}

}
</script>

<style scoped>
.card {
	margin: 1em;
	padding: 0.5em;
	border-radius: 0.5em;
	border: 1px solid #ccc;
	box-shadow: 0 10px 8px -10px #ccc;
	font-size: 11pt;
	color: #666;
	text-align: center;
	min-width: 150px;

	transition: border 0.25s, box-shadow 0.25s;
}
.card.clickable:hover {
	border: 1px solid var(--ekno-blue);
	box-shadow: 0 10px 8px -10px var(--ekno-blue);
	transition: border 0.25s, box-shadow 0.25s;
}
</style>
