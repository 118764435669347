<template>
	<div id="dropdown" :class="{'browse' : browse}" :style="{ width: width }" ref="dropdown">
		<div class="flex-row flex-align-stretch">

			<div v-if="showSettingsButton" @click.stop="$emit('toggleSettings')" id="settingsButton" class="flex-column flex-justify-center">
				<span class="icon-menu" />
			</div>

			<div v-if="filterIcon" class="flex-column flex-justify-center ma-05 color-green"><span class="icon-filter" /></div>
			
			<div v-if="selectedItems.length" class="flex-row flex-gap-05 flex-grow mr-05" style="max-width: 75%; max-height: 3em; overflow: auto;">
				<TagField :Source="selectedItems" :displayFunction="getTagDisplay" :topMargin="false" fontSize="10pt" @remove="item => $emit('remove', item)" />
			</div>

			<input
				type="text"
				class="w-100 round-05 pr-05"
				style="margin-left: 0.25em; font-size: inherit;"
				:style="{ maxWidth: selectedItems.length ? '300px' : '100%' }"
				:placeholder="disabled ? disabledMessage : placeholder"
				@input="search"
				@keyup.enter.stop="confirmSearch()"
				:value="displayValue"
				:disabled="disabled"
				@keydown.tab.stop="focusSuggestions()"
				@keydown.down.stop="focusSuggestions()"
				@keydown.esc="closeSuggestions()"
				@click.stop="toggleBrowse()"
				tabindex="0"
				ref="input"
			/>
			<span v-if="displayValue" class="flex-row flex-align-center"><span class="icon-cross font-size-0-8 color-red-30" @click.stop="clear()" /></span>
			<span v-if="waiting || busy" class="flex-row flex-align-center mr-05"><span class="icon-spinner4 spin-loader-15" /></span>
			<div v-else-if="browseIcon && !disabled" id="drawerButton" class="flex-column flex-justify-center" :class="{ 'browse' : browse }" @click.stop="toggleBrowse()">
				<span :class="{ 'arrow-up' : browse, 'arrow-down' : !browse }" />
			</div>
		</div>
		<div v-if="showSuggestions || flatListMode" id="suggestionsWrapper" :class="{ 'above' : dropUp, 'flatList' : flatListMode }" @mouseenter="cancelCloseSuggestions()" @mouseleave="toggleBrowse( true )">
			<div v-if="$refs.dropdown" id="suggestions" :style="{ width: $refs.dropdown.offsetWidth + 'px', maxHeight: suggestionMaxHeight ?? '50vh' }" ref="suggestions" @keyup.esc.stop="closeSuggestions()">
				<div
					class="suggestion"
					v-for="(result,idx) in searchResults"
					:key="result[ObjKey]"
					:tabindex="idx+1"
					@click="selectObj(result)"
					@keyup.enter.stop="selectObj( result )"
					@keydown.up.stop="focusPrevSuggestion( $event )"
					@keydown.down.stop="focusNextSuggestion( $event, idx+1 )"
					v-html="DisplayFunction(result)"
				/>
				
				<div v-if="waiting || busy" class="my-05 flex-row flex-align-center flex-justify-center"><span class="icon-spinner4 spin-loader" /></div>
				<div v-else-if="searchResults.length == 0" class="italic color-gray-60 ma-1">No suggestions found</div>
				
				<div v-if="pages > 1" id="pages" class="flex-row flex-justify-center flex-align-center flex-gap-05 sticky-bottom border-top">
					<span v-if="page > 1" class="pageArrow icon-arrow-left2" @click.stop="prev()" />
					<span>{{ page }} of {{ pages }}</span>
					<span v-if="page < pages" class="pageArrow icon-arrow-right2" @click.stop="next()" />
				</div>
			</div>
		</div>
	</div>
</template>



<script>
import TagField from '@/components/utilities/TagField.vue'

export default {
	name: "search-dropdown",


	components: {
    TagField
  },


	props: {
		showSettingsButton: {
			type: Boolean,
      default: false
		},
		
		selectedItems: {
			type: Array,
      default: () => []
		},

		externalResults: {
			type: Array,
			default: () => [],
		},

		placeholder: {
			type: String,
			default: 'Type to search...'
		},

		InitialValue: String,
		width: String,

		filterIcon: {
			type: Boolean,
			default: false
		},

		browseIcon: {
			type: Boolean,
			default: false
		},
		pages: {
			type: Number,
			default: 1
		},

		busy: {
			type: Boolean,
			default: false
		},

		suggestionMaxHeight: {
			type: String,
			default: null
		},

		flatListMode: {
			type: Boolean,
			default: false
		},

		disabled: {
			type: Boolean,
			default: false
		},

		dropUp: {
			type: Boolean,
			default: false
		},

		disabledMessage: {
			type: String,
			default: 'Search disabled'
		},

		// function that determines what to display.  Takes an object as an argument.
		DisplayFunction: {
			type: Function,
			required: true
		},

		// If provided, overrides DisplayFunction for the currently-selected item (i.e. after selecting from the suggestions dropdown list).
		selectDisplayFunction: {
			type: Function,
			default: null
		},

		// If provided, overrides DisplayFunction for multi-select tags (selectedItems).
		tagDisplayFunction: {
			type: Function,
      default: null
		},
		ObjKey: String,
	},


	data() {
		return {
			selectedObj: null,
			showSuggestions: false,
			searching: false,
			waiting: false,
			searchResults: () => [],
			
			searchTerm: "",
			page: 1,
			browse: false,
			closeSuggestionsTimeoutID: null,
		}
	},


	computed: {
		displayValue() {
			if (this.searching) return this.searchTerm;
			else if (this.selectedObj && !this.selectedItems.length) {
				if( this.selectDisplayFunction ) return this.selectDisplayFunction( this.selectedObj )
				else return this.DisplayFunction(this.selectedObj);
			}
			return this.InitialValue;
		},
	},


	watch: {
		InitialValue() {
			this.searching = false;
			this.selectedObj = null;
		},

		externalResults() {
			// console.debug("externalResults changed!");
			this.searchResults = this.externalResults;
			if ( this.searchTerm || this.browse )
				this.showSuggestions = true;
			else this.showSuggestions = false;

			this.waiting = false
		},

		selectedItems( items ) {
			if( !items.length ) return
			this.selectedObj = null
		}
	},



	methods: {

		confirmSearch() {
			let value = this.$refs.input.value
			if( value === '' || value === null || value === undefined ) return
			this.$emit( "confirm", value )
			this.showSuggestions = false
			this.searchResults = []
			this.browse = false
		},


		selectObj(obj) {
			this.selectedObj = obj;
			if( obj != null ) this.$emit("selected", obj);
			else this.$emit("none");

			// Guard for embedded style
			if( this.flatListMode ) return

			this.showSuggestions = false;
			this.searchResults = [];
			this.searchTerm = "";
			this.searching = false;
			this.browse = false;
		},


		getTagDisplay( item ) {
			return this.tagDisplayFunction ? this.tagDisplayFunction( item ) : this.DisplayFunction( item )
		},


		// Called externally
		clear() {
			this.page = 1
			this.selectObj( null )
		},
		focus() {
			this.$refs.input.focus()
		},


		toggleBrowse( delay = false ) {
			// this.page = 1

			if( this.browse && delay ) {
				
				this.closeSuggestionsTimeoutID = setTimeout( () => {
					this.browse = false
					this.showSuggestions = false
				}, 500 )

			} else if( this.browse ) {
				this.browse = false
				this.showSuggestions = false

			} else {
				this.browse = true
				this.showSuggestions = true
				this.$emit('search', this.searchTerm, this.page)
			}
		},

		cancelCloseSuggestions() {
			this.browse = true
			clearTimeout( this.closeSuggestionsTimeoutID )
			this.closeSuggestionsTimeoutID = null
		},


		prev() { this.$emit("search", this.searchTerm, --this.page) },
		next() { this.$emit("search", this.searchTerm, ++this.page) },


		/* This fetches the top 25 results from the server. 
		Actual API fetches should be taken care of external to this component. */
		async search(event) {
			clearTimeout(this.search.timeoutID)
			let searchTerm = event.target.value
			this.searchTerm = searchTerm

			this.searching = true
			this.waiting = true
			if (!this.searchTerm) {
				this.page = 1
				this.showSuggestions = false
				this.$emit("none")
			}

			this.page = 1
			this.search.timeoutID = setTimeout( () => { this.$emit("search", this.searchTerm, this.page) }, 500 )
		},


		toggleSuggestions() {
			if( this.showSuggestions ) this.closeSuggestions()
			else this.focusSuggestions()
		},


		closeSuggestions() {
			this.showSuggestions = false
			this.browse = false
			this.$refs.input.focus()
		},


		focusSuggestions() {
			if( !this.showSuggestions ) {
				if( this.searchResults.length > 0 ) {
					this.showSuggestions = true
					this.browse = true
				} else this.toggleBrowse()
				return
			}

			if( !this.$refs.suggestions.firstChild ) return
			this.$refs.suggestions.firstChild.focus()
		},

		focusPrevSuggestion( event ) {
			if( !event.target.previousSibling ) return
			event.target.previousSibling.focus()
		},

		focusNextSuggestion( event, nextIndex ) {
			if( !event.target.nextSibling || nextIndex >= this.searchResults.length ) return
			event.target.nextSibling.focus()
		}

	},
};
</script>


<style scoped>

#settingsButton, #drawerButton {
	padding: 0 0.25em;
	cursor: pointer;
	background-color: white;
}

#settingsButton {
	border-right: 1px solid #ccc;
	border-top-left-radius: 0.5em;
	border-bottom-left-radius: 0.5em;
}

#settingsButton:hover {
  background-color: var(--ekno-blue-95);
}

#drawerButton {
	border-top-right-radius: 0.5em;
	border-bottom-right-radius: 0.5em;
	background-color: var(--pp10-gray-95);
}

#drawerButton:hover {
  background-color: var(--ekno-blue-95);
}

#drawerButton:not(:hover) .arrow-down {
	border-top-color: gray;
}
#drawerButton:not(:hover) .arrow-up {
	border-bottom-color: gray;
}

input[type="text"] {
	border: none;
	min-height: 2em;
}
input[type="text"]:focus {
	outline: none;

}

#dropdown {
	position: relative;
	border: 1px solid #ccc;
	border-radius: 0.5em;
	background: white;
	transition: border-color 0.3s;
}
#dropdown:hover, #dropdown:focus {
	border: 1px solid var(--ekno-blue);
	transition: border-color 0.3s;
}
#suggestionsWrapper {
	position: absolute;
	min-width: 300px;
	right: -35px;
	padding: 1px 35px 35px 35px;
	z-index: 3; /* Must be greater than 2, to be above ToggleButtons; must be greater than 1, to be above ObjectTable sticky <th>'s */
}
#suggestionsWrapper.above {
	bottom: 0;
}
#suggestions {
	min-width: 230px;
	font-size: 1rem;
	border: 1px solid #ccc;
	border-radius: 0.5em;
	z-index: 1;
	box-shadow: 3px 3px 10px #aaa;
	max-height: 50vh;
	overflow: auto;
	background-color: #f4f4f4;
}
#suggestionsWrapper.flatList #suggestions {
	box-shadow: none;
}

.suggestion {
	padding: 0.5em;
	text-align: left;
}

.suggestion:hover, .suggestion:focus {
	background-color: var(--color-highlight);
	cursor: pointer;
}

#suggestions #pages {
	background: #f4f4f4;
}

#dropdown.browse, #dropdown.browse input {
	background-color: lightblue;
	transition: background-color 0.25s;
}
.icon.browse {
	color: var(--ekno-blue);
}

.tag {
	white-space: nowrap;
	max-width: 100%;
	font-size: 0.8rem;
}

.tag .body {
	overflow: hidden;
	white-space: nowrap;
	background-color: lightblue;
	padding: 0.25em 0.5em;
	border-top-left-radius: 0.25em;
	border-bottom-left-radius: 0.25em;
}

.tag .remove {
	
	background-color: var(--ekno-blue);
	color: white;
	padding: 0.25em;
	border-top-right-radius: 0.25em;
	border-bottom-right-radius: 0.25em;
}

.pageArrow {
	border: 1px solid #ccc;
	border-radius: 0.5em;
	padding: 0.75em;
	margin: 0.25em;
}

.pageArrow:hover {
	color: var(--ekno-blue);
	border-color: var(--ekno-blue);
	background: white;
}
</style>
