<template>
  <SearchDropdown
    :placeholder="placeholder"
    :DisplayFunction="displayFunction"
    :InitialValue="initialValue || displayFunction(blastList)"
    :ObjKey="'id'"
    :width="width"
    :browseIcon="true"
    :pages="pages"
    :externalResults="items"
    :dropUp="dropUp"
    :busy="busy"
    :suggestionMaxHeight="suggestionMaxHeight"
    :flatListMode="flat"
    @selected="item => select(item)"
    @search="(txt, pg) => getList(txt, pg)"
    @none="select(null)"
    ref="search"
  />
</template>


<script>
import MessageCenterAPI from '@/api/MessageCenterAPI.js'
import PaginatedRequest from '@/api/PaginatedRequest.js'
import BlastList from './BlastList.js'
import SearchDropdown from '@/components/utilities/SearchDropdown.vue'

export default {
  name: 'BlastListSearchDropdown',

  components: {
    SearchDropdown,
  },


  props: {
    blastList: {
      type: BlastList,
      default: null
    },

    width: {
      type: String,
      default: '50%'
    },

    placeholder: {
      type: String,
      default: 'Search Blast Lists'
    },

    suggestionMaxHeight: {
			type: String,
			default: null
		},

    flat: {
      type: Boolean,
      default: false
    },

    initialValue: {
      type: String,
      default: null
    },

		dropUp: {
			type: Boolean,
			default: false
		},

    pageSize: {
      type: Number,
      default: 25
    },

  },


  model: {
    prop: 'blastList',
    event: 'update'
  },
  

  data() {
    return {
      items: [],
      pages: 1,
      busy: false,
    }
  },


  mounted() {
    this.getList()
  },


  methods: {

    select(item) {
      this.$emit('update', item);
    },

    clear() { this.$refs.search.clear() },

    displayFunction(obj) {
      if(!obj) return '';
      return `${ obj.name } (${ obj.numContacts } contacts)`
    },

    async getList( searchTerm, page = 1 ) {
      this.items = []
      this.busy = true
      
      try {
        const req = new PaginatedRequest( 'dateCreated', false )
        req.page = page
        req.pageSize = this.pageSize
        req.searchString = searchTerm ? searchTerm : null
        const pr = await MessageCenterAPI.getBlastLists( req )
  
        if(!pr) return
        this.pages = pr.pages
        for(let item of pr.data) this.items.push( BlastList.import( item ) )

      } finally {
        this.busy = false
      }
    },
  }


}
</script>
