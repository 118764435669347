<template>
<section class="flex-row flex-align-center flex-justify-start flex-gap-025">
	<div class="font-size-0-8 py-025 px-05 round-1 border" :style="statusBadgeStyle">{{ statusLabel }} {{ currentJob.percentComplete }}</div>
	<div v-if="canStop" class="font-size-1-5 flex-row flex-align-center"><span id="stopButton" class="icon-stop" @click.stop="stopJob()" /></div>
	<div v-if="currentJob.status == 'interrupting'" class="icon-spinner4 spin1 color-blue font-size-0-9" />
</section>
</template>



<script>
import LongJobAPI from '@/api/LongJobAPI.js'
import MessageCenterAPI from '@/api/MessageCenterAPI.js'
import LongJob from '@/features/JobControl/LongJob.js'

export default {
	name: 'JobControlTableWidget',

	props: {
		job: Object,
		messageBlastID: Number,
	},


	data() {
		return {
			innerJob: null,
			loading: false,
			error: null,
			timeoutID: null,
		}
	},


	created() {
		if( this.job.status == 'waiting' || (this.job.status == 'running' && this.job.pid) || this.job.status == 'interrupting' ) this.loadJobLoop()
	},
	beforeDestroy() { clearTimeout( this.timeoutID ) },


	computed: {
		currentJob() { return this.innerJob ?? this.job },

		statusLabel() {
			if( this.currentJob.status == 'running' && this.currentJob.pid == null ) return 'dead'
			return this.currentJob.status
		},

		canStop() {
			const status = this.currentJob.status == 'waiting' || this.currentJob.status == 'running'
			return status && this.currentJob.pid
		},

		statusBadgeStyle() {
			const style = {}
			if( this.currentJob.status == 'waiting' ) {
				style.color = 'var(--ekno-blue)'
				style.backgroundColor = 'var(--ekno-blue-95)'
				style.borderColor = 'var(--ekno-blue)'

			} else if( this.currentJob.status == 'running' ) {
				if( !this.currentJob.pid ) {
					style.color = 'var(--pp10-red)'
					style.backgroundColor = 'var(--pp10-red-95)'
					style.borderColor = 'var(--ekno-red)'
				} else {
					style.color = 'var(--pp10-green)'
					style.backgroundColor = 'var(--pp10-green-95)'
					style.borderColor = 'var(--ekno-green)'
				}
			} else if( this.currentJob.status == 'completed' ) {
				style.color = 'var(--pp10-green)'
				style.backgroundColor = 'var(--pp10-green-95)'
				style.borderColor = 'var(--ekno-green)'
			} else if( this.currentJob.status == 'failed' ) {
				style.color = 'var(--pp10-red)'
				style.backgroundColor = 'var(--pp10-red-95)'
				style.borderColor = 'var(--ekno-red)'
			} else if( this.currentJob.status == 'interrupting' ) {
				style.color = 'var(--pp10-purple)'
				style.backgroundColor = 'var(--pp10-purple-95)'
				style.borderColor = 'var(--ekno-purple)'
			} else if( this.currentJob.status == 'interrupted' ) {
				style.color = 'var(--pp10-orange-50)'
				style.backgroundColor = 'var(--pp10-orange-95)'
				style.borderColor = 'var(--ekno-orange-50)'
			}

			return style
		}
	},



	methods: {

		async stopJob() {
			await LongJobAPI.stop( this.currentJob.jobID )
			this.loadJobLoop()
		},


		async loadJobLoop() {
			clearTimeout( this.timeoutID )
			await this.loadJob()
			if( this.currentJob.status != 'waiting' && (this.currentJob.status != 'running' || !this.currentJob.pid) && this.currentJob.status != 'interrupting' ) return
			this.timeoutID = setTimeout( this.loadJobLoop, 2000 )			
		},

		
		async loadJob() {
			
			this.loading = true
			this.error = null

			try {
				const req = { messageBlastIDs: [ this.messageBlastID ] }
				const res = await MessageCenterAPI.getMessageBlastJobs( req )
				
				if( res.data[ this.messageBlastID ] ) {
					this.innerJob = LongJob.import( res.data[ this.messageBlastID ] )
				}

			} catch( e ) {
				this.error = e.message
				console.error(e)

			} finally {
				this.loading = false
			}

		}
	}
}
</script>



<style scoped>
#stopButton {
	color: var(--pp10-gray-40);
}
#stopButton:hover {
	color: var(--pp10-red);
	transition: color 0.3s;
}
#stopButton:active {
	color: var(--pp10-red-30);
	transition: color 0s;
}
</style>