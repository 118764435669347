<template>
  <div id="tagField" class="flex-row flex-justify-start flex-wrap" :class="{ 'mt-05' : topMargin }">
    <div v-for="(item,idx) in Source" :key="getDisplay(item)" class="flex-row flex-align-center">
      <span class="tag flex-row flex-gap-05 flex-align-stretch" :class="{ 'removable' : removable }" :style="getStyle( item )" @click="click( item )">
        <span class="name">{{ getDisplay(item) }}</span>
        <span v-if="removable" class="removeTag flex-row flex-align-center" @click.stop="$emit('remove', item)"><span class="icon-cross" /></span>
      </span>
      <span v-if="joinHTML && idx < Source.length - 1" v-html="'&nbsp;'+joinHTML+'&nbsp;'" />
    </div>
  </div>
</template>

<script>
import { isString } from '@tiptap/core'
export default {
  name: "TagField",

  props: {
    Source: Array,

    // a string path in dot notation leading to the property to display
    // (e.g. 'attributes.name')
    DisplayPath: {
      type: String,
      default: null
    },

    displayFunction: {
      type: Function,
      default: null
    },

    styleFunction: {
      type: Function,
      default: () => {}
    },

    clickableFunction: {
      type: Function,
      default: () => false
    },

    topMargin: {
      type: Boolean,
      default: true
    },

    fontSize: {
      type: String,
      default: '1em'
    },

    // HTML that goes between the tags (kind of like Array.join("x"))
    joinHTML: {
      type: String,
      default: null
    },

    removable: {
      type: Boolean,
      default: true
    },
  },

  methods: {
    getDisplay(item) {
      
      // Item is a string, not an object
      if( isString( item ) ) return item

      // Use the displayFunction
      if( this.displayFunction ) return this.displayFunction(item)

      // No path or function - use whole object
      if( !this.DisplayPath ) return item


      // Use the displayPath
      let path = this.DisplayPath.split(".")
      let display = item;
      for (let step of path) {
        display = display[step];
      }
      return display;
    },

    getStyle( item ) {
      const style = { ...this.styleFunction( item ), fontSize: this.fontSize }
      if( this.clickableFunction( item ) ) style.cursor = "pointer"
      return style
    },

    click( item ) {
      if( !this.clickableFunction( item ) ) return
      this.$emit( 'click', item )
    }
  }
}
</script>

<style scoped>

.tag{
  color: white;
  background-color: #4E98FF;
  border: white 1px solid;
  padding-right:0;
  border-radius: 0.25em;

  margin: 0.125em;
}

.tag:first-child {
  margin-left: 0;
}

.tag .name {
  padding: 0.2em;
}

.tag.removable .name {
  padding-right: 0;
}

.tag .removeTag {
  padding: 0.2em;
  background-color: var(--ekno-blue);
  border-radius: 0 0.25em 0.25em 0;
}
.removeTag:hover {
  background-color: var(--ekno-blue-35);
}
</style>
