import { render, staticRenderFns } from "./GeneralUserDetails.vue?vue&type=template&id=adf743fa"
import script from "./GeneralUserDetails.vue?vue&type=script&lang=js"
export * from "./GeneralUserDetails.vue?vue&type=script&lang=js"
import style0 from "./GeneralUserDetails.vue?vue&type=style&index=0&id=adf743fa&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports