import Exim from '@/models/Exim.js'


export default class DeliveryStats extends Exim {

	
	messageBlastID = null
	email = null
	sms = null
	app = null



	static import( obj ) {
		const item = super.import( obj, [ 'email', 'sms', 'app' ] )
		if( obj.email ) item.email = DeliveryStatsEmail.import( obj.email )
		if( obj.sms ) item.sms = DeliveryStatsSMS.import( obj.sms )
		if( obj.app ) item.app = DeliveryStatsApp.import( obj.app )
		return item
	}

}



class Stats extends Exim {

	s( propName ) {
		if( !this[ propName ] ) return null
		return this[ propName ].toLocaleString()
	}

}



export class DeliveryStatsEmail extends Stats {

	// Delivery stats
	sent = null
	delivered = null
	undelivered = null
	softBounced = null
	hardBounced = null
	rejected = null

	// User action stats
	opens = null
	uniqueOpens = null
	unsubscribes = null
	complaints = null


	get deliveryRate() {
		if( !this.sent || ! this.delivered ) return null
		return Math.round( this.delivered * 100 / this.sent ) + '%'
	}

	get openRate() {
		if( !this.delivered || !this.uniqueOpens ) return null
		return Math.round( this.uniqueOpens * 100 / this.delivered ) + '%'
	}

	get bounceRate() {
		if( !this.hardBounced || !this.sent ) return null
		return Math.round( this.hardBounced * 100 / this.sent ) + '%'
	}

	get unsubRate() {
		if( !this.unsubscribes || !this.delivered ) return null
		return Math.round( this.unsubscribes * 100 / this.delivered ) + '%'
	}

	get complaintRate() {
		if( !this.complaints || !this.delivered ) return null
		return Math.round( this.complaints * 100 / this.delivered ) + '%'
	}

}



export class DeliveryStatsSMS extends Stats {

	sent = null

}



export class DeliveryStatsApp extends Stats {

	sent = null
	opens = null

	get openRate() {
		if( !this.sent || !this.opens ) return null
		return Math.round( this.opens * 100 / this.sent ) + '%'
	}

}