<template>
<section v-if="newSubscription !== null">

	<div id="TopBar" class="flex-row flex-justify-between flex-align-center" :class="statusColor">
		<p style="font-size: 14pt; margin: 0;">Status:
			<select v-model="newSubscription.status" @change="updateStatus()">
				<option value="active">Active</option>
				<option value="paused">Paused</option>
				<option value="canceled">Canceled</option>
				<option value="expired">Expired</option>
			</select>
		</p>
		<button class='button mr-1 right' @click='save()' :disabled='!changesMade'> Save Changes</button>
	</div>


	<section class="ma-1">

		<section class="flex-row flex-gap-15 mb-1 px-1">
			<div class="flex-column" style="width: 50%">
				<div>{{ newSubscription.firstName }} {{ newSubscription.lastName }}</div>
				<div>License #: {{ newSubscription.licenseKey }}</div>
				<div>Billing Start: {{ newSubscription.startTimestamp.toLocaleString() }}</div>
				<div v-if="newSubscription.resumeTimestamp > newSubscription.startTimestamp">Renewed: {{ newSubscription.resumeTimestamp.toLocaleString() }}</div>
			</div>
			<div class="flex-column" style="width: 50%">
				<div>{{ Math.moneyFormat(newSubscription.dividedPrice) }} {{ frequencyString }}</div>
				<div>Last billed: {{ newSubscription.previousBillingTimestamp ? newSubscription.previousBillingTimestamp.toLocaleString() : 'N/A' }}</div>
				<div>Next bill: {{ newSubscription.nextBillingTimestamp ? newSubscription.nextBillingTimestamp.toLocaleString() : 'N/A' }}</div>
			</div>
		</section>

		<section class="flex-row flex-gap-1 mb-3 px-1">
			<div class="flex-column flex-gap-15" style="width: 50%">
				<fieldset style="flex-grow: 1">
					<legend>Billing Occurrences ({{ newSubscription.completedOccurrences }} of {{ newSubscription.totalOccurrences }}): {{ Math.moneyFormat( totalPaid ) }}</legend>
					<div v-if="newSubscription.orderIDs.length">
						<div v-for="orderID in newSubscription.orderIDs" :key="orderID">
							Order #{{ orderID }}
						</div>
					</div>
					<div v-else class="center ma-1" style="color: #777; font-weight: bold; font-size: 14pt;">No transactions yet!</div>
				</fieldset>
			</div>
			<div class="flex-column flex-gap-15" style="width: 50%">
				<fieldset style="flex-grow: 1">
					<legend>Itemized Price</legend>
					<div v-for="(item, sku) in newSubscription.subscriptionObject" :key="sku">
						<div>{{ sku }}<span v-if="item.pricingOptionID"> (PO #{{ item.pricingOptionID }})</span>: {{ Math.moneyFormat( item.price ) }}
							<span v-if="item.licenseLength !== undefined && item.lengthUnit !== undefined">
								per {{ item.licenseLength }} {{ item.lengthUnit }}
							</span>
						</div>
					</div>
					<div class="mt-05 pt-05" style="border-top: 1px solid #ccc;">
						Total: {{ Math.moneyFormat(newSubscription.dividedPrice) }} {{ frequencyString }}
					</div>
				</fieldset>
			</div>
		</section>

	</section>

</section>
<section v-else class="flex-row flex-justify-center ma-1"><div class="icon-spinner4 spin-loader-15" /></section>
</template>



<script>
import SubscriptionAPI from '@/api/SubscriptionAPI.js'
import Subscription from '@/models/Subscription.js'

export default {
	name: 'SubscriptionDetails',


	components: {

	},


	props: {
		subscription: Object,
	},


	data() {
		return {
			newSubscription: null,
			totalPaid: null,       // lifetime total paid
		}
	},


	computed: {
		changesMade() {
			return false
		},
		
		statusColor() {
			if( !this.newSubscription ) return null
			if( this.newSubscription.status == 'active' ) return 'statusGreen'
			if( this.newSubscription.status == 'paused' ) return 'statusOrange'
			if( this.newSubscription.status == 'canceled' ) return 'statusCanceled'
			if( this.newSubscription.status == 'expired' ) return 'statusRed'
			else return 'statusRed'
		},

		frequencyString() {
			const unit = this.newSubscription.frequency == 1 ? this.newSubscription.frequencySingularUnit : this.newSubscription.frequencyUnit
			return `every ${ this.newSubscription.frequency } ${ unit }`
		}
	},



	created() { this.initialize() },



	methods: {
		async initialize() {
			const data = await SubscriptionAPI.getSubscription( this.subscription.subscriptionID )
			this.newSubscription = Subscription.import( data )
			this.getTotalPaid()
		},

		async getTotalPaid() {
			const data = await SubscriptionAPI.getSubscriptionLifetimePaid( this.subscription.subscriptionID )
			this.totalPaid = data.total
		},

		save() {

		},

		updateStatus() {
			SubscriptionAPI.updateSubscriptionStatus( this.newSubscription.licenseKey, this.newSubscription.status )
		}


	}

}
</script>



<style scoped>
#TopBar {
  padding: 0.75em 0 0.75em 0.75em;
  margin-bottom: 1.5em;
}

.statusRed { background-color: pink;}
.statusOrange { background-color: orange;}
.statusGreen { background-color: lightgreen;}
.statusCanceled { background-color: purple; color: white !important; }
</style>