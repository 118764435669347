<template>
<section class="flex-row flex-align-stretch flex-gap-05">

	<div class="flex-column flex-justify-end">
		<div v-if="numSelected > 0" class="font-size-0-9 color-gray-40">{{ numSelected }} selected</div>
		<div class="flex-row flex-align-center flex-gap-05 font-size-0-8">
			<button class="pillButton small secondary faded border" @click="$emit('all')">{{ numSelected == 0 ? 'Select' : '' }} All</button>
			<button v-if="numSelected > 0" class="pillButton small secondary faded border" @click="$emit('none')">None</button>
		</div>
	</div>
	
	
	<div id="editOptions" class="ml-2 flex-row flex-align-center flex-gap-05 bg-gray-90 border round-05 transition-all-0-3" :class="{ 'show' : numSelected > 0 }">
		<slot></slot>
		<span v-if="numSelected > 0 && showDelete" id="deleteButton" class="icon-bin2 py-05 px-1 bg-red-40 color-white border-none round-05 transition-all-0-3" @click="$emit('delete')" />
	</div>
</section>
</template>

<script>
export default {
	name: 'MultiselectTools',

	props: {
		numSelected: {
			type: Number,
			default: 0
		},
		showDelete: {
			type: Boolean,
			default: false
		},
	},
}
</script>

<style scoped>
#editOptions {
	opacity: 0;
	max-width: 0;

	min-height: 2em;
	height: 2em;
	max-height: 2em;
	
	padding: 0.5em 0;
	margin: 0;
	overflow: hidden;
	box-sizing: content-box;
	position: relative;
}
#editOptions.show {
	opacity: 1;
	max-width: 80vw;
	padding: 0.5em 1em;
	overflow: auto;
}
#deleteButton:hover {
	background: var(--pp10-red-30);
}
</style>