<template>
<section style="display:inline-block;">
	<form class='search-bar flex-row flex-align-center flex-justify-between w-100' @submit.prevent>
		<input
			type="text"
			:placeholder="placeholder"
			v-model="searchString"
			:style="styles"
			@focus='$emit("focus")'
			@blur='$emit("blur")'
			@keypress='handleInput(searchString, $event)'
			@paste='handleInput(searchString, "paste")'
			@input='handleInput(searchString, "input")'
			ref="input"
		/>
		<span @click='search(searchString)' class="icon-search button-search" />
	</form>
</section>
</template>



<script>
import CodeUtils from '@/libraries/CodeUtils.js'
export default {
	name: 'SearchBox',


	props: {
		placeholder: {
			type: String,
			default: "Search..."
		},
		
		styles: {
			type: Object,
			default: () => { return {} }
		},

		autoSearch: {
			type: Boolean,
			default: false
		},

		autoSearchDelay: {
			type: Number,
			default: 500
		},
	},


	data() {
		return {
			searchString: '',
		}
	},


	methods: {

		handleInput( searchString, event ) {

			
			if( event?.key == "Enter" ) {
				CodeUtils.cancelDebounce( 'searchbox-search' )
				this.search( searchString )
				return
			}

			if( !this.autoSearch ) return

			CodeUtils.debounce( 'searchbox-search', () => {
				this.$emit( 'search', searchString )
			}, this.autoSearchDelay )
		},

		search( searchString ) {
			this.$emit( "search", searchString )
		},


		// Externally-called "focus" - i.e. from hotkey press
		focus() { this.$refs.input.focus(); this.$refs.input.select() },
	}


}
</script>

<style scoped>
.search-bar {
	border: 1px solid #ddd;
	border-radius: 5px;
	white-space: nowrap;
	padding: 0;
}
.search-bar input {
	border: none;
	font-size: 12pt;
	margin: .25em;
	transition: all 0.5s;
	width: calc( 100% - 2em );
}
</style>
