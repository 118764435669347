<template>
<section>

	<div class="flex-row flex-justify-between my-05">
		<SearchBox @search="str => searchString = str" />
		
		<div class="flex-row flex-justify-end flex-gap-2">
			<div>{{ count ? count.toLocaleString() : count }} entries on {{ pages }} page{{ pages == 1 ? '' : 's' }}</div>
			<div>
				<label>Per page: </label>
				<select v-model="pageSize">
					<option :value='25'>25</option>
					<option :value='50'>50</option>
					<option :value='100'>100</option>
					<option :value='250'>250</option>
					<option :value='500'>500</option>
				</select>
			</div>
		</div>

	</div>


	<div v-if="statsError" class="warning">Failed to get delivery statistics: {{ statsError }}</div>
	<div v-if="jobsError" class="warning">Failed to get mail-sending job details: {{ jobsError }}</div>


	<div v-if='!loading && !error'>

		<div v-if='items.length'>
			<ObjectTable
				:Source='items'
				:Columns='columns'
				:Numbered='true'
				:SortBy='sortBy'
				:SortAsc='sortAsc'
				:Deletable='!showSent'
				@edit='item => select( item )'
				@sort='prop => sort( prop )'
				@delete='item => showDeleteSelected( item )'
				ref='objectTable'
			/>
			<Paginator v-model="page" :numPages="pages" @input="pg => toPage( pg )" />
		</div>

		<div v-else class="NoResults">No results</div>

	</div>

	<div v-if="error" class="warning">Failed to get records: {{ error }}</div>

	<!-- Selected MessageBlast (Details) -->
	<StretchModal ref="messageDetailsModal" padding="1em" maxWidth="min( 100vw, 900px )" @close="deselect()">
		<template #header v-if="selectedItem">{{ selectedItem.sentTimestamp === null ? 'Draft' : 'Email Blast' }}</template>
		
		<div class="flex-row flex-align-center flex-gap-05">
			<strong>To:</strong>
			<div class='toField bg-blue-90 round-05 px-025 flex-row w-100'>
				<TagField
					v-if="selectedItem && selectedRecipients.length"
					:Source='selectedRecipients'
					:displayFunction="function( item ) { return item.stringValue ?? item.email }"
					:styleFunction="recipientTagStyles"
					:removable="false"
					style="margin: 0; max-height: 15em; overflow: auto; width: 100%;"
					ref="recipientTagField"
				/>
			</div>
		</div>
		
		<div v-if="selectedItem" class="mt-1"><b>Subject:</b> {{ selectedItem.message.subject }}</div>
		<div v-if="selectedItem" class="mt-1 border-top" v-html="selectedItem.message.htmlBody" />
		
		<div v-if="selectedItem" class="mt-2 pt-05 flex-row flex-align-center flex-justify-between sticky-bottom border-top">
			<div class="color-gray-50 font-size-0-8">
				<div>created {{ selectedItem.createdTimestamp.toLocaleString() }}</div>
				<div>edited {{ selectedItem.modifiedTimestamp.toLocaleString() }}</div>
			</div>
			<div class="color-gray-50 font-size-0-8">
				<div>owned by: {{ selectedItem.ownerName }}</div>
				<div>created by: {{ selectedItem.creatorName }}</div>
			</div>

			<div class="flex-row flex-align-center flex-justify-end flex-gap-05">
				<button v-if="selectedItem.job" class="pillButton secondary small" @click="getJobReport(selectedItem.job.jobID)">job report</button>
				<button class="pillButton secondary small blue" @click="$refs.messageRecipientsModal.open()">recipients</button>
				<button class="pillButton" @click="useMessage( selectedItem )"> {{ selectedItem.sentTimestamp === null ? 'Edit / use this draft' : 'Create new draft from this message' }}</button>
			</div>
		</div>
	</StretchModal>


	<!-- Job Report Details -->
	<StretchModal ref="messageBlastLogsModal" padding="1em" @close="$refs.objectTable.deselect()">
		<template #header>Job Report</template>
		<section v-if="selectedJob">
			<div class="flex-row flex-align-start flex-justify-center flex-gap-2">
				<div class="text-right">
					<div>Job ID: {{ selectedJob.jobID }}</div>
					<div>Label: {{ selectedJob.jobLabel }}</div>
					<div>Status: {{ selectedJob.status }}</div>
					<div>Complete: {{ selectedJob.complete }}</div>
					<div>Has Error: {{ selectedJob.error }}</div>
					
				</div>
				<div>
					<div>UserID: {{ selectedJob.executeAsUserID }}</div>
					<div>Start time: {{ selectedJob.startTime?.toLocaleString() }}</div>
					<div>End time: {{ selectedJob.endTime?.toLocaleString() }}</div>
					<div>Work Units: {{ selectedJob.workUnitsComplete }} / {{ selectedJob.workUnitsTotal }}</div>

				</div>
			</div>
			
			<div v-if="selectedJob.jobReport" id="sendReport">
				<div v-for="row in selectedJob.jobReport" :key="row"><code>{{ row }}</code></div>
			</div>
			<div v-if="selectedJob.errorReport" id="errorReport">
				<div class="mb-1">Errors:</div>
				<div v-for="row in selectedJob.errorReport" :key="row"><code>{{ row }}</code></div>
			</div>
		</section>
	</StretchModal>


	<!-- Actual Recipients -->
	<StretchModal ref="messageRecipientsModal" padding="1em">
		<template #header>Message Recipients</template>
		<MessageRecipientLists v-if="selectedItem" :messageBlastID="selectedItem.messageBlastID" />
	</StretchModal>


	<ConfirmDialog ref="confirmDeleteRow" @cancel="itemToDelete = null" @confirm="deleteItem()">
		<div v-if="itemToDelete">Really delete <b>&ldquo;{{ itemToDelete.message.subject || StrUtils.ellipsify( itemToDelete.message.plainBody, 60 ) }}&rdquo;</b>?</div>
	</ConfirmDialog>

</section>
</template>



<script>
import SearchBox from '@/components/utilities/SearchBox.vue'
import ObjectTable from '@/components/utilities/ObjectTable.vue'
import Paginator from '@/components/utilities/Paginator.vue'
import StretchModal from '@/components/utilities/StretchModal.vue'
import ConfirmDialog from '@/components/utilities/ConfirmDialog.vue'
import TagField from '@/components/utilities/TagField.vue'
import MessageRecipientLists from './MessageRecipientLists.vue'

import MessageCenterAPI from '@/api/MessageCenterAPI.js'
import LongJobAPI from '@/api/LongJobAPI.js'
import PaginatedRequest from '@/api/PaginatedRequest.js'

import MessageBlast from './MessageBlast.js'
import BlastList from './BlastList.js'
import DeliveryStats from './DeliveryStats.js'
import LongJob from '@/features/JobControl/LongJob.js'
import POC from '@/features/SalesManagement/POC/POC.js'
import PocEmail from '@/features/SalesManagement/POC/PocEmail.js'
import User from '@/features/Users/User.js'
import CodeUtils from '@/libraries/CodeUtils.js'
import StringUtils from '@/libraries/StringUtils.js'

export default {
	name: 'MessageList',


	components: {
		SearchBox,
		ObjectTable,
		Paginator,
		StretchModal,
		ConfirmDialog,
		TagField,
		MessageRecipientLists,
	},


	props: {
		showDrafts: {
			type: Boolean,
			default: false
		},
		showSent: {
			type: Boolean,
			default: false
		},
	},

	
	data() {
		return {
			page: 1,
			pages: 1,
			pageSize: 100,
			count: 0,
			sortBy: 'modifiedTimestamp',
			sortAsc: false,
			searchString: null,

			items: [],
			loading: false,
			error: null,

			statsLoading: false,
			statsError: null,

			jobsLoading: false,
			jobsError: null,
			selectedJob: null,

			selectedItem: null,
			selectedRecipients: [],
			itemToDelete: null,

			columns: [],
			initialColumns: [
				{
					displayName: 'Sent',
					displayFunction: function( item ) {
						if( item.sentTimestamp != null ) return '<span class="icon-checkmark pa-05 bg-green-30 color-white" />'
						else return '<span class="icon-cross pa-05 bg-red-40 color-white" />'
					}
				},
				{
					displayName: 'Subject',
					propertyName: 'message.subject',
					displayFunction: function( item ) { return item.message.subject }
				},
				{
					displayName: 'Preview',
					propertyName: 'message.plainBody',
					displayFunction: function( item ) { return `<span class="color-gray-50">${ StringUtils.ellipsify( item.message.plainBody, 100 ) }</span>` }
				},
				// {
				// 	displayName: 'Creator',
				// 	propertyName: 'creatorName',
				// 	sortable: true,
				// },
				// {
				// 	displayName: 'Owner',
				// 	propertyName: 'ownerName',
				// 	sortable: true,
				// },
				{
					displayName: 'Recipients',
					propertyName: 'recipients',
					displayFunction: function( item ) {
						var recipCount = 0
						var listCount = 0
						for( const r of item.recipients ) {
							if( r.listID != null ) listCount++
							else recipCount++
						}
						const recipStr = `${ recipCount } individuals`
						const listStr = `${ listCount } lists`
						if( recipCount && listCount ) return `${ recipStr } & ${ listStr }`
						if( recipCount ) return recipStr
						if( listCount ) return listStr
						return null
					},
				},
				{
					displayName: 'Job Status',
					displayFunction: item => {
						if( !item.job ) return null
						return { component: 'JobControlTableWidget', props: { job: item.job, messageBlastID: item.messageBlastID } }
					},
					clickHandler: ( event, item, rowIndex ) => {
						if( !item.job ) return
						this.getJobReport( item.job.jobID )
					}
				},
			],
		}
	},



	computed: {
		StrUtils() { return StringUtils },
	},




	watch: {
		showDrafts() { this.initColumns(); this.page = 1; CodeUtils.debounce( 'initMessageList', this.initialize, 100 ) },
		showSent() { this.initColumns(); this.page = 1; CodeUtils.debounce( 'initMessageList', this.initialize, 100 ) },
		pageSize() { this.page = 1; this.initialize() },
		searchString() { this.page = 1; this.initialize() },
	},


	created() {
		this.initColumns()
		this.initialize()
	},


	methods: {

		initColumns() {
			this.columns = [ ...this.initialColumns ]
			
			if( this.showDrafts ) {
				this.columns.push({
					displayName: 'Date Created',
					propertyName: 'createdTimestamp',
					displayFunction: function( item ) { return item.createdTimestamp ? item.createdTimestamp.toLocaleString() : null },
					sortable: true,
				},{
					displayName: 'Date Modified',
					propertyName: 'modifiedTimestamp',
					displayFunction: function( item ) { return item.modifiedTimestamp ? item.modifiedTimestamp.toLocaleString() : null },
					sortable: true,
				})
				this.sortBy = 'modifiedTimestamp'
			}

			if( this.showSent ) {
				
				const getStyle = (value, isGood = true, color = 'black', bgColor = null) => {
					const style = { textAlign: 'center' }
					if( value !== null && value > 0 ) {
						style.color = isGood ? 'var(--pp10-green-30)' : isGood == false ? 'var(--pp10-red-40)' : color
						style.fontWeight = '500'
					} else style.color = 'var(--pp10-gray-70)'
					if( bgColor ) style.backgroundColor = bgColor
					return style
				}

				this.columns.push({
					displayName: 'Emails<br>Sent',
					propertyName: 'deliveryStats.email.sent',
					displayFunction: item => item?.deliveryStats?.email?.s('sent') ?? '--',
					style: item => { return getStyle( item?.deliveryStats?.email?.sent, true, null, 'var(--pp10-green-pastel-90)' ) },
					tooltip: 'Emails sent',
					tooltipPosition: 'top',
				},{
					displayName: 'Delivery<br>Rate',
					propertyName: 'deliveryStats.email.delivered',
					displayFunction: item => item?.deliveryStats?.email?.deliveryRate ?? '--%',
					style: item => { return getStyle( item?.deliveryStats?.email?.delivered, true, null, 'var(--pp10-green-pastel-90)' ) },
					tooltip: 'Email Delivery Rate',
					tooltipPosition: 'top',
				},{
					displayName: 'Open<br>Rate',
					propertyName: 'deliveryStats.email.opens',
					displayFunction: item => `${ item?.deliveryStats?.email?.openRate ?? '--%' } (${ item?.deliveryStats?.email?.s('uniqueOpens') ?? '--' } | ${ item?.deliveryStats?.email?.s('opens') ?? '--' })`,
					style: item => { return getStyle( item?.deliveryStats?.email?.uniqueOpens + item?.deliveryStats?.email?.opens, true, null, 'var(--pp10-green-pastel-90)' ) },
					tooltip: 'OpenRate% (unique opens | total opens)',
					tooltipPosition: 'top',
				},
				// {
				// 	displayName: 'Emails<br>Undelivered',
				// 	propertyName: 'deliveryStats.email.undelivered',
				// 	displayFunction: item => item?.deliveryStats?.email?.undelivered ?? '--',
				// 	style: item => { return getStyle( item?.deliveryStats?.email?.undelivered, null, 'var(--pp10-orange-45)', 'var(--pp10-orange-95)' ) },
				// 	tooltip: 'Emails not (yet) delivered',
				// 	tooltipPosition: 'top',
				// },
				{
					displayName: 'Bounce<br>Rate',
					propertyName: 'deliveryStats.email.hardBounced',
					displayFunction: item => `${ item?.deliveryStats?.email?.bounceRate ?? '--%' } (${ item?.deliveryStats?.email?.s('softBounced') ?? '--' } | ${ item?.deliveryStats?.email?.s('hardBounced') ?? '--' } | ${ item?.deliveryStats?.email?.s('rejected') ?? '--' })`,
					style: item => { return getStyle( item?.deliveryStats?.email?.softBounced + item?.deliveryStats?.email?.hardBounced + item?.deliveryStats?.email?.rejected, null, 'var(--pp10-orange-45)', 'var(--pp10-orange-95)' ) },
					tooltip: 'bounceRate% (soft | hard | rejects)',
					tooltipPosition: 'top',
				},{
					displayName: 'Emails<br>Unsubs',
					propertyName: 'deliveryStats.email.unsubscribes',
					displayFunction: item => item?.deliveryStats?.email?.s('unsubscribes') ?? '--',
					style: item => { return getStyle( item?.deliveryStats?.email?.unsubscribes, null, 'var(--pp10-orange-45)', 'var(--pp10-orange-95)' ) },
					tooltip: 'Email unsubscribes',
					tooltipPosition: 'top',
				},{
					displayName: 'Marked<br>Spam',
					propertyName: 'deliveryStats.email.complaints',
					displayFunction: item => `${ item?.deliveryStats?.email?.complaintRate ?? '--%' } (${ item?.deliveryStats?.email?.s('complaints') ?? '--' })`,
					style: item => { return getStyle( item?.deliveryStats?.email?.complaints, false, null, 'var(--pp10-red-90)' ) },
					tooltip: 'Email complaints (marked spam)',
					tooltipPosition: 'top',
				},{
					displayName: 'SMS',
					propertyName: 'deliveryStats.sms.sent',
					displayFunction: item => item?.deliveryStats?.sms?.s('sent') ?? '--',
					style: item => { return getStyle( item?.deliveryStats?.sms?.sent, null, 'var(--ekno-blue-30)', 'var(--ekno-blue-90)' ) },
					tooltip: 'SMS messages sent',
					tooltipPosition: 'top',
				},{
					displayName: 'App',
					propertyName: 'deliveryStats.app.sent',
					displayFunction: item => `${ item?.deliveryStats?.app?.openRate ?? '--$' } (${ item?.deliveryStats?.app?.s('opens') ?? '--' } / ${ item?.deliveryStats?.app?.s('sent') ?? '--' })`,
					style: item => { return getStyle( item?.deliveryStats?.app?.sent + item?.deliveryStats?.app?.opens, null, 'var(--pp10-purple-30)', 'var(--pp10-purple-95)' ) },
					tooltip: 'In-App notifications OpenRate% (opened / sent)',
					tooltipPosition: 'top',
				},
				{
					displayName: 'Date Sent',
					propertyName: 'sentTimestamp',
					displayFunction: function( item ) { return item.sentTimestamp ? item.sentTimestamp.toLocaleString() : null },
					sortable: true,
				})
				this.sortBy = 'sentTimestamp'
			}
		},



		async initialize() {

			this.items = []
			this.error = null
			this.loading = true

			try {
				const req = new PaginatedRequest( this.sortBy, this.sortAsc, this.page, this.pageSize, this.searchString )
				req.includeUnsent = this.showDrafts
				req.includeSent = this.showSent
				const pr = await MessageCenterAPI.getMessageBlasts( req )

				this.count = pr.count
				this.pages = pr.pages

				for( var item of pr.data ) this.items.push( MessageBlast.import( item ) )
				
				this.initJobs()
				if( this.showSent ) {
					this.initStats()
				}

			} catch (e) {
				this.error = e
				throw e

			} finally {
				this.loading = false
			}
		},



		async initStats() {
			this.statsLoading = true
			this.statsError = null
			
			try {
				const req = { messageBlastIDs: this.items.map( item => item.messageBlastID ), includeEmailStats: true, includeSMSStats: true, includeAppStats: true }
				const res = await MessageCenterAPI.getMessageDeliveryStatistics( req )

				for( let item of this.items ) {
					if( res.data[ item.messageBlastID ] ) item.deliveryStats = DeliveryStats.import( res.data[ item.messageBlastID ] )
				}

			} catch( e ) {
				this.statsError = '' + e.message
				console.error(e)

			} finally {
				this.statsLoading = false
			}
		},



		async initJobs() {

			this.jobsLoading = true
			this.jobsError = null

			try {
				const req = { messageBlastIDs: this.items.map( item => item.messageBlastID ) }
				const res = await MessageCenterAPI.getMessageBlastJobs( req )

				for( let item of this.items ) {
					if( res.data[ item.messageBlastID ] ) {
						item.job = LongJob.import( res.data[ item.messageBlastID ] )
					}
				}

			} catch( e ) {
				this.jobsError = e.message
				console.error(e)

			} finally {
				this.jobsLoading = false
			}
		},



		async getJobReport( jobID ) {
			this.jobReport = null
			this.jobErrorReport = null

			this.$refs.messageBlastLogsModal.open()
			this.selectedJob = LongJob.import( await LongJobAPI.getJobProgress( jobID ) )
		},



		async select( item ) {
			this.selectedItem = item
			this.$refs.messageDetailsModal.open()

			// Initialize recipient list
			this.selectedRecipients = []
			for( const r of this.selectedItem.recipients ) {
				let row = null
				if( r.userID != null ) {
					row = new User()
					row.userID = r.userID
					row.firstName = r.name
					row.emailAddress = r.address
					
				} else if( r.pocID != null ) {
					row = new POC()
					row.id = r.pocID
					row.firstName = r.name
					
					const email = new PocEmail()
					email.email = r.address
					email.pocID = r.pocID
					row.emails.push( email )
					
				} else if( r.listID != null ) {
					const res = await MessageCenterAPI.getBlastList( r.listID )
					row = BlastList.import( res.data )
					
				} else if( r.address != null ) {
					row = { email: r.address }
					
				} else console.error("Invalid recipient in draft:", r, draft)
				
				this.selectedRecipients.push( row )
			}

		},

		deselect() {
			this.selectedItem = null
			this.$refs.objectTable.deselect()
		},
		
		
		useMessage( msg ) {
			this.$store.state.pipes.send( 'teleport', { mode: 'messages.compose', props: { draftIn: msg }, header: `Compose Message` } )
		},



		toPage( page ) {
			this.page = page
			this.initialize()
		},


		sort( prop ) {
			
			if( this.sortBy === prop ) {
				this.sortAsc = !this.sortAsc
				this.page = 1
				
				this.initialize()
				return
			}
			
			this.sortBy = prop
			if (
				prop === 'createdTimestamp' ||
				prop === 'modifiedTimestamp' ||
				prop === 'sentTimestamp'
			) {
				this.sortAsc = false
			} else {
				this.sortAsc = true
			}

			this.page = 1
			this.initialize()
		},


		showDeleteSelected( item ) {
			if( item.sentTimestamp !== null ) return

			this.itemToDelete = item
			this.$refs.confirmDeleteRow.open()
		},


		async deleteItem() {
			const item = this.itemToDelete

			if( item.sentTimestamp !== null ) return
			if( !item.messageBlastID ) return

			await MessageCenterAPI.deleteMessageBlast( item.messageBlastID )
			
			const idx = this.items.findIndex( elem => elem.messageBlastID == item.messageBlastID )
			if( idx < 0 ) return
			
			this.items.splice( idx, 1 )
			this.itemToDelete = null
		},



		rowStyle( row ) {
			const style = {}
			if( !row.sentTimestamp ) {
				style.fontWeight = 'bold'
				style.backgroundColor = 'var(--pp10-red-95)'
			} else {
				style.backgroundColor = 'var(--pp10-green-95)'
			}
			return style
		},



		recipientTagStyles( item ) {
			const style = {}
			if( item instanceof BlastList ) style.backgroundColor = 'var(--pp10-orange)'
			if( item.valid === false ) style.backgroundColor = 'var(--pp10-red)'
			return style
		},

		showTagDetails( item ) {
			if( item instanceof BlastList ) {
				this.selectedBlastList = item
				this.$refs.listDetailsModal.open()
			
			} else if( item instanceof POC ) {
				this.selectedRecipient = item
				this.$refs.pocDetailsModal.open()

			} else if( item instanceof User ) {
				this.selectedRecipient = item
				this.$refs.userDetailsModal.open()

			}
		},


	}

}
</script>



<style scoped>
.toField {
	max-height: 10em;
	padding: 0.25em;
	overflow: auto;
}

#sendReport, #errorReport {
	margin-top: 2em;
	padding: 1em;
	border-radius: 1em;

	max-height: 25em;
	overflow: auto;
}
#sendReport{
	background: var(--pp10-green-pastel-80);
}
#errorReport {
	background: var(--pp10-red-90);
}

</style>