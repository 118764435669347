<template>
<section>
  <section v-if="!loading">
    <ObjectTable
      :Columns="columns"
      :Source="items"
      :Numbered="true"
      :Deletable="true"
      :SortBy="sortBy"
      :SortAsc="sortAsc"
      :PageNum="page"
      :PageSize="pageSize"
      @edit="item => selectedItem = item"
      @sort="prop => sort(prop)"
      @delete="item => showDeleteSelected(item)"
      class="mt-1"
      ref="objectTable"
    />
    <Paginator v-model="page" @input="initialize()" :numPages="pages" />
  </section>

  <div v-if="!loading && !error && !items.length" class="NoResults">No Records</div>

  <section v-if='loading' class='flex justify-center'>
    <div class='icon-spinner4 spin-loader'></div>
  </section>

  <section v-if="error" class="warning">
    Failed to get records
  </section>


  
  <!-- <ScheduledRegistrationListModal :selectedItem="selectedItem" @close="deselect()" /> -->
  
  <ConfirmDialog ref="confirmDelete" @cancel="deselect()" @confirm="deleteItem()">
    <div v-if="itemToDelete">
      Un-schedule message to <strong>{{ itemToDelete.recipients.length }}</strong>?
    </div>
  </ConfirmDialog>

</section>
</template>



<script>
// import ScheduledRegistrationListModal from './ScheduledRegistrationListModal.vue'
import ObjectTable from '@/components/utilities/ObjectTable.vue'
import Paginator from '@/components/utilities/Paginator.vue'
import ConfirmDialog from '@/components/utilities/ConfirmDialog.vue'

import MessageCenterAPI from '../../../api/MessageCenterAPI.js'
import MessageBlast from './MessageBlast.js'
import PaginatedRequest from '@/api/PaginatedRequest.js'
import StringUtils from '@/libraries/StringUtils.js'

export default {
  name: 'ScheduledMessageList',

  components: {
    // ScheduledRegistrationListModal,
    ObjectTable,
    Paginator,
    ConfirmDialog,
  },


  data() {
    return {
      items: [],
      selectedItem: null,
      itemToDelete: null,

      page: 1,
      pages: 0,
      pageSize: 100,

      sortBy: 'scheduledTime',
      sortAsc: false,

      loading: false,
      error: false,

      columns: [
        {
          displayName: 'Message',
          propertyName: '',
          displayFunction: function( item ) {
            const from = item.fromName || item.fromEmail
            const toCount = item.recipients.length
            const subject = StringUtils.ellipsify( item.message.subject || '', 50 )
            const message = StringUtils.ellipsify( item.message.htmlBody || item.message.plainBody || '', 200 )

            return `<div>From: <b>${ from }</b> | To: <b>${ toCount } recipients</b></div><div>subject: <b>${ subject }</b></div><div><div class="mt-05 color-gray-50">${ message }</div>`
          }
          // sortable: true
        },
        {
          displayName: 'Scheduled To Send',
          propertyName: 'scheduledTime',
          displayFunction: function( item ) {
            if( !item.scheduledTime ) return ''
            return item.scheduledTime.toLocaleString()
          },
          sortable: true
        },
        {
          displayName: 'status',
          propertyName: 'status',
          displayFunction: function( item ) {
            const status = item.jobComplete && !item.jobError ? true : ( item.jobID ? false : null )
            if( status ) return `<span class='icon-checkmark message-success' />`
            else if( status === false ) return `<span class='icon-cross' style='color:#aaa' />`
          },
          itemTooltip: function( item ) {
            if( item.jobComplete && ! item.jobError ) return 'Message sent'
            else if( item.jobError ) return 'Error while sending message'
          }
        },
        {
          displayName: 'Date Sent',
          propertyName: 'endTime',
          displayFunction: function( item ) {
            if( !item.jobEndTime ) return ''
            return item.jobEndTime.toLocaleString()
          },
          sortable: true
        }
      ],

    }
  },


  created() { this.initialize() },


  computed: {
    itemToDeleteScheduledDate() {
      if( !this.itemToDelete ) return ''
      if( !this.itemToDelete.scheduledTime ) return ''
      return this.itemToDelete.scheduledTime.toLocaleDateString()
    }
  },


  methods: {
    async initialize() {

      this.loading = true
      this.error = false
      
      this.items = []
      this.selectedItem = null
      this.itemToDelete = null

      try {
        var req = new PaginatedRequest( this.sortBy, this.sortAsc, this.page, this.pageSize )
        const res = await MessageCenterAPI.getScheduled( req )
        console.log( res )

        for( let item of res.data.data ) this.items.push( MessageBlast.import( item ) )
        this.pages = res.pages

      } catch( err ) {
        this.error = true
        console.error( err )
      } finally {
        this.loading = false
      }
    },

    sort( propName ) {
      if( this.sortBy === propName ) {
        this.sortAsc = !this.sortAsc
        this.initialize()
        return
      }

      this.sortBy = propName
      if( this.sortBy === 'scheduledDate' ) this.sortAsc = false
      else this.sortAsc = true
      
      this.initialize()
    },

    deselect() {
      this.selectedItem = null
      this.itemToDelete = null
      this.$refs.objectTable.deselect()
    },

    showDeleteSelected( item ) {
      this.itemToDelete = item
      this.$refs.confirmDelete.open()
    },

    async deleteItem() {
      if( !this.itemToDelete ) return

      try {
        await MessageCenterAPI.deleteScheduled( this.itemToDelete.messageBlastID )
      } finally {
        this.initialize()
      }
    }

  }
}
</script>



<style scoped>

</style>