<template>
<section>

	<section class="center mb-1">
		<SearchBox style="min-width: min( 100%, 500px );" @search="str => searchString = str" />
	</section>


	<section class="flex-row flex-align-start flex-justify-center flex-gap-1">
	
		<!-- Email Recipients -->
		<section class="flex-column flex-align-center bg-orange-95 pa-05 round-1 overflow" style="max-height: 75vh;">
			<h1 class="center">Email</h1>
			<div class="mb-1">{{ emailRecipients.length }} recipient{{ emailRecipients.length != 1 ? 's' : '' }}</div>
			<div v-for="entry in emailRecipients" :key="entry.vueID" class="flex-row flex-align-center flex-justify-between flex-gap-05 mb-05 w-100">
				<MessageRecipient :recipient="entry" />

				<!-- Badges -->
				<div class="flex-row flex-align-center flex-justify-end flex-gap-05">
					<div class="tag statusTag font-size-0-8" :class="{ 'goodStatus' : entry.status == 'delivered' }">{{ entry.status || 'delivery status unknown' }}</div>
					<div v-if="entry.complaint" class="tag complaintTag font-size-0-8">complaint: {{ entry.complaint }}</div>
					<div v-if="entry.opens && entry.opens.length > 0" class="icon-folder-open color-green" @mouseenter="t.s($event)" @mouseleave="t.h($event)" :tooltip="`Opened ${entry.opens.length} times`" />
					<div v-else-if="entry.status == 'delivered'" class="icon-folder color-orange-75" @mouseenter="t.s($event)" @mouseleave="t.h($event)" tooltip="Message is un-opened" />
				</div>

			</div>
			<div v-if="emailBusy"><span class="icon-spinner4 spin1 font-size-2" /></div>
		</section>
		

		<!-- SMS Recipients -->
		<section class="flex-column flex-align-center bg-green-95 pa-05 round-1 overflow" style="max-height: 75vh; min-width: min(300px, 33%);">
			<h1 class="center">SMS</h1>
			<div class="mb-1">{{ smsRecipients.length }} recipient{{ smsRecipients.length != 1 ? 's' : '' }}</div>
			<div v-for="entry in smsRecipients" :key="entry.vueID" class="flex-row flex-align-center flex-wrap flex-gap-025 mb-05">
				<MessageRecipient :recipient="entry" />
			</div>
			<div v-if="smsBusy"><span class="icon-spinner4 spin1 font-size-2" /></div>
		</section>
		

		<!-- App Recipients -->
		<section class="flex-column flex-align-center bg-purple-95 pa-05 round-1 overflow" style="max-height: 75vh; min-width: min(300px, 33%);">
			<h1 class="center">In-App</h1>
			<div class="mb-1">{{ appRecipients.length }} recipient{{ appRecipients.length != 1 ? 's' : '' }}</div>
			<div v-for="entry in appRecipients" :key="entry.vueID" class="flex-row flex-align-center flex-justify-between flex-wrap flex-gap-05 mb-05">
				<MessageRecipient :recipient="entry" />
				<div v-if="entry.opens && entry.opens.length > 0" class="icon-folder-open color-green" @mouseenter="t.s($event)" @mouseleave="t.h($event)" :tooltip="`Opened ${entry.opens.length} times`" />
				<div v-else class="icon-folder color-orange-75" @mouseenter="t.s($event)" @mouseleave="t.h($event)" tooltip="Message is un-opened" />
			</div>
			<div v-if="appBusy"><span class="icon-spinner4 spin1 font-size-2" /></div>
		</section>
	
	</section>
	
</section>
</template>



<script>
import MessageRecipient from './MessageRecipient.vue'
import SearchBox from '@/components/utilities/SearchBox.vue'

import MessageCenterAPI from '@/api/MessageCenterAPI.js'
import PaginatedRequest from '@/api/PaginatedRequest.js'
import Tooltips from '@/libraries/Tooltips/Tooltips.js'
import CodeUtils from '@/libraries/CodeUtils.js'

export default {
	name: 'MessageSentList',


	components: {
		MessageRecipient,
		SearchBox,
	},

	
	props: {
		messageBlastID: Number,
	},


	data() {
		return {

			emailRecipients: [],
			emailPages: 0,
			emailCount: 0,
			emailPage: 1,
			emailBusy: false,
			emailError: null,
			
			smsRecipients: [],
			smsPages: 0,
			smsCount: 0,
			smsPage: 1,
			smsBusy: false,
			smsError: null,

			appRecipients: [],
			appPages: 0,
			appCount: 0,
			appPage: 1,
			appBusy: false,
			appError: null,

			sortBy: null,
			sortAsc: true,
			pageSize: 400,
			searchString: null, 



		}
	},


	created() { this.initialize() },


	computed: {
		t() { return Tooltips },
	},


	watch: {
		messageBlastID() { this.initialize() },
		searchString() { this.initialize() },
	},


	methods: {

		initialize() {
			this.initEmailRecipients()
			this.initSMSRecipients()
			this.initAppRecipients()
		},


		async initEmailRecipients( nextPage = false ) {
			this.emailBusy = true
			if( nextPage ) this.emailPage++
			else this.emailRecipients = []

			const req = new PaginatedRequest( this.sortBy, this.sortAsc, this.emailPage, this.pageSize, this.searchString )
			
			try {
				const pr = await MessageCenterAPI.getMessageBlastEmailRecipients( this.messageBlastID, req )
				this.emailPages = pr.pages
				this.emailCount = pr.count
				for( let item of pr.data ) this.emailRecipients.push( CodeUtils.addVueID(item) )

				if( this.emailPage < this.emailPages ) setTimeout( () => this.initEmailRecipients( true ), 250 )

			} catch( e ) {
				this.emailError = e
			} finally {
				this.emailBusy = false
			}
		},


		async initSMSRecipients( nextPage = false ) {
			this.smsBusy = true
			if( nextPage ) this.smsPage++
			else this.smsRecipients = []

			const req = new PaginatedRequest( this.sortBy, this.sortAsc, this.smsPage, this.pageSize, this.searchString )
			
			try {
				const pr = await MessageCenterAPI.getMessageBlastSMSRecipients( this.messageBlastID, req )
				this.smsPages = pr.pages
				this.smsCount = pr.count
				for( let item of pr.data ) this.smsRecipients.push( CodeUtils.addVueID(item) )

				if( this.smsPage < this.smsPages ) setTimeout( () => this.initSMSRecipients( true ), 250 )

			} catch( e ) {
				this.smsError = e
			} finally {
				this.smsBusy = false
			}
		},


		async initAppRecipients( nextPage = false ) {
			this.appBusy = true
			if( nextPage ) this.appPage++
			else this.appRecipients = []

			const req = new PaginatedRequest( this.sortBy, this.sortAsc, this.appPage, this.pageSize, this.searchString )
			
			try {
				const pr = await MessageCenterAPI.getMessageBlastAppRecipients( this.messageBlastID, req )
				this.appPages = pr.pages
				this.appCount = pr.count
				for( let item of pr.data ) this.appRecipients.push( CodeUtils.addVueID(item) )

				if( this.appPage < this.appPages ) setTimeout( () => this.initAppRecipients( true ), 250 )

			} catch( e ) {
				this.appError = e
			} finally {
				this.appBusy = false
			}
		},

	}
}
</script>

<style scoped>
.previewTitle {
  background-color: var(--pp10-purple);
  color: white;
  font-weight: bold;
  padding: 0.5em 1em;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
}

.tag {
	padding: 0.25em 0.5em;
	border-radius: 1em;
}

.statusTag {
	background-color: var(--pp10-red-95);
	color: var(--pp10-red);
	border: 1px solid var(--pp10-red);
}
.statusTag.goodStatus {
	background-color: var(--pp10-green-95);
	color: var(--pp10-green);
	border: 1px solid var(--pp10-green);
}

.complaintTag {
	background-color: var(--pp10-orange-95);
	color: var(--pp10-orange-35);
	border: 1px solid var(--pp10-orange);
}

.smsBubble {
  background: var(--ekno-blue);
  color: white;
  padding: 0.5em;
  margin: 3em 1em 1em 3.5em;
  border-radius: 0.5em;
  min-width: 200px;
  max-width: 250px;
}
</style>