class CodeUtils {


  static timeouts = new Map() // maps "user-provided-timeout-name" => timeoutID
  static vueIDCounter = 0

  static debounce( name, callback, timeout = 500 ) {
    if( this.timeouts.has( name ) ) clearTimeout( this.timeouts.get( name ) )
    const timeoutID = setTimeout( callback, timeout )
    this.timeouts.set( name, timeoutID )
  }

  static cancelDebounce( name ) {
    if( this.timeouts.has( name ) ) clearTimeout( this.timeouts.get( name ) )
  }


  static addVueID( obj ) {
    obj.vueID = ++CodeUtils.vueIDCounter
    return obj
  }
  
}

export default CodeUtils