<template>
<section>

	<div class="flex-row flex-align-center flex-justify-end my-05">
		<button class="button" @click="newScreen">New Login Screen</button>
	</div>

	<ObjectTable
		:Source="screens.objects"
		:Columns='columns'
		:Deletable="true"
		@edit="editScreen"
		@delete="deleteScreen"
		ref="objectTable" />


	<StretchModal ref="detailsModal" :clickToClose="false" :padding="'1em'" @close="$refs.objectTable.deselect()">
		<template #header>Login Screen Details</template>
		<ScreenDetails :LoginScreen="currentScreen" @add='scr => addScreen( scr )' @edit="scr => screens.updateElement( 'id', scr )" @close="$refs.detailsModal.close()" />
	</StretchModal>

	<ConfirmDialog :show="itemToDelete !== null" @cancel="itemToDelete = null" @confirm="confirmDelete()">
		<div>Are you sure you want to delete this login screen?</div>
		<div style="font-size: 1.25em; font-weight: bold; margin: 0.25em 0;">{{ itemToDelete ? itemToDelete.title : '' }}</div>
		<div><strong>This action CANNOT be undone!</strong></div>
	</ConfirmDialog>
</section>
</template>

<script>
import SettingsAPI from "@/api/SettingsAPI.js"
import LoginScreen from "@/models/LoginScreen.js"
import OrderedSet from "@/libraries/OrderedSet.js"

import ScreenDetails from "./ScreenDetails.vue"
import StretchModal from "@/components/utilities/StretchModal.vue"
import ConfirmDialog from '@/components/utilities/ConfirmDialog.vue'
import ObjectTable from "./utilities/ObjectTable.vue"

export default {
	name: "screens-list",

	data() {
		return {
			displayNames: ['Priority', 'Title'],
			propertyNames: ['priority', 'title'],
			columns: [
				{
					displayName: 'Priority',
					propertyName: 'priority',
					displayFunction: item => {
						if (item.priority == 1) return 'High';
						else if (item.priority == 2) return 'Medium';
						else if (item.priority == 3) return 'Low';
					}
				},
				{
					displayName: 'Title',
					propertyName: 'title'
				}
			],

			screens: new OrderedSet(),
			currentScreen: null,
			itemToDelete: null,
		}
	},

	async created() {
		try {
			this.screens.objects = await SettingsAPI.getLoginScreens();
		} catch (e) {
			alert("An error occurred while loading login screens")
			console.error(e);
		}
	},


	methods: {
		addScreen(loginScreen) {
			this.currentScreen = loginScreen
			this.screens.push( loginScreen )
		},

		newScreen() {
			this.currentScreen = new LoginScreen();
			this.$refs.detailsModal.open()
		},

		editScreen(loginScreen) {
			this.currentScreen = loginScreen;
			this.$refs.detailsModal.open()
		},

		deleteScreen(loginScreen) { this.itemToDelete = loginScreen },

		async confirmDelete() {
			const loginScreen = this.itemToDelete
			try {
				await SettingsAPI.deleteLoginScreen(loginScreen);
				this.screens.remove( loginScreen )
			} catch (e) {
				console.error(e);
				alert("Error communicating with server; changes not saved.");
			}
			this.itemToDelete = null
		},
	},

	components: {
		ScreenDetails,
		StretchModal,
		ConfirmDialog,
		ObjectTable,
	}
}
</script>
