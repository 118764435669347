<template>
  <div class="home flex flex-grow">
    <SideTray :open="openSideTray" ref="sideTray" />

    <section id="Page">
      <div id="Header">
        <div class="flex-row flex-justify-start flex-align-center flex-gap-1">
          <div class='menu-toggle flex justify-center align-center icon-menu' @click='openSideTray = !openSideTray' />
          <h1>{{ componentHeader }}</h1>
        </div>

        <div class="flex-row flex-gap-2">

          <div class="flex-row flex-gap-1">
            <div v-if="hasAccess( 'site_admin:events:view' )"><b style="font-size: 2em;">{{recentExceptions}}</b> Errors</div>
            <div v-if="hasAccess( 'site_admin:users:view:statistics' )">
              {{dailyLogins}}<br>
              <span class="link" @click="showSignedInUsers()">Users signed in</span>: {{ numUsersLoggedIn }}
            </div>
          </div>
        </div>

      </div>

      <div id="Body" :style="{ maxWidth: openSideTray ? 'calc(100vw - 210px)' : 'calc(100vw - 50px)'}">
        <component :is='currentComponent' v-bind='componentProps' />
      </div>
    </section>

  </div>
</template>

<script>
import SideTray from '@/components/SideTray.vue'
import SiteDashboard from '@/components/SiteDashboard.vue'
import UsersList from '@/features/Users/UsersList.vue'     // If UsersList is after CoursesList, WebPack throws a circular dependency fit.
import CoursesList from '@/features/Courses/CoursesList.vue'
import LoginList from '@/features/LoginTracking/LoginList.vue'
import UserSessionList from '@/features/LoginTracking/UserSessionList.vue'
import UserTimeList from '@/features/LoginTracking/UserTimeList.vue'
import LicensesList from '@/features/Licenses/LicensesList.vue'
import StatisticsDashboard from '@/features/StudentStatistics/StatisticsDashboard.vue'
import EknoStatBuilder from '@/features/StudentStatistics/EknoStat/EknoStatBuilder.vue'
import EScoreList from '@/features/StudentStatistics/eScores/EScoreList.vue'
import QuotesList from '@/components/QuotesList.vue'
import ScreensList from '@/components/ScreensList.vue'
import EmailTemplateList from '@/components/EmailTemplateList.vue'
import RolesList from '@/features/Roles/RolesList.vue'
import FeaturesList from '@/features/Roles/FeaturesList.vue'
import FeedbackList from '@/components/FeedbackList.vue'
import ReviewsDashboard from '@/features/Reviews/ReviewsDashboard.vue'
import SuperReviewsDashboard from '@/features/Reviews/SuperReviewsDashboard.vue'
import EducatorReviewsDashboard from '@/features/Reviews/EducatorReviewsDashboard.vue'
import MediaLibrary from '@/components/MediaLibrary.vue'
import MessageCenter from '@/features/EmailCommunication/MessageCenter/MessageCenter.vue'
import ComposeNotification from '@/features/EmailCommunication/MessageCenter/ComposeNotification.vue'
import MessageList from '@/features/EmailCommunication/MessageCenter/MessageList.vue'
import BlastListList from '@/features/EmailCommunication/MessageCenter/BlastListList.vue'
import MessageTemplateList from '@/features/EmailCommunication/MessageCenter/MessageTemplateList.vue'
import SentMessages from '@/features/EmailCommunication/MessageCenter/SentMessages.vue'
import UnsubscribeList from '@/features/EmailCommunication/MessageCenter/UnsubscribeList.vue'
import ScheduledMessageList from '@/features/EmailCommunication/MessageCenter/ScheduledMessageList.vue'
import ScheduledRegistrationSummaryList from '@/features/EmailCommunication/ScheduledRegistrationEmails/ScheduledRegistrationSummaryList.vue'
import ModalMessageList from '@/features/ModalMessages/ModalMessageList.vue'
import ModalMessageViews from '@/features/ModalMessages/ModalMessageViews.vue'
import ModalMessageActions from '@/features/ModalMessages/ModalMessageActions.vue'
import ModalMessageResponses from '@/features/ModalMessages/ModalMessageResponses.vue'
import CollegeSettings from '@/components/CollegeSettings.vue'
import OrdersPane from '@/features/orders/OrdersPane.vue'
import OrderSearchPane from '@/features/orders/OrderSearchPane.vue'
import CreateOrderPane from '@/features/orders/CreateOrderPane.vue'
import TransactionList from '@/features/orders/Transactions/TransactionList.vue'
import SubscriptionsList from '@/features/orders/SubscriptionsList.vue'
import AffiliateList from '@/features/SalesManagement/Affiliates/AffiliateList.vue'
import LandingPageList from '@/features/SalesManagement/LandingPages/LandingPageList.vue'
import StorefrontsList from '@/features/Storefronts/StorefrontsList.vue'
import CustomerList from '@/features/SalesManagement/Customers/CustomerList.vue'
import ContactList from '@/features/SalesManagement/POC/ContactList.vue'
import ProductsList from '@/features/Products/ProductsList.vue'
import CouponsList from '@/features/SalesManagement/CouponCodes/CouponsList.vue'
import ProxyPane from '@/features/Proxy/ProxyPane.vue'
import SalesRepresentativeList from '@/features/SalesManagement/SalesRepresentatives/SalesRepresentativeList.vue'
import DemoPane from '@/features/SalesManagement/DemoPane.vue'
import MetricsDashboard from '@/features/EventLogging/MetricsDashboard.vue'
import EventLog from '@/features/EventLogging/EventLog.vue'
import ToolsPane from '@/components/ToolsPane.vue'

import UserAPI from '@/api/UserAPI.js'
import EventMetricsAPI from '@/api/EventMetricsAPI.js'

export default {
  name: 'home',

  components: {
    SideTray,
    SiteDashboard,
    CoursesList,
    UsersList,
    LoginList,
    UserSessionList,
    UserTimeList,
    LicensesList,
    StatisticsDashboard,
    EknoStatBuilder,
    EScoreList,
    QuotesList,
    ScreensList,
    EmailTemplateList,
    RolesList,
    FeaturesList,
    FeedbackList,
    ReviewsDashboard,
    SuperReviewsDashboard,
    EducatorReviewsDashboard,
    ScheduledRegistrationSummaryList,
    MessageCenter,
    ComposeNotification,
    MessageList,
    BlastListList,
    MessageTemplateList,
    SentMessages,
    UnsubscribeList,
    ModalMessageList,
    ModalMessageViews,
    ModalMessageActions,
    ModalMessageResponses,
    MediaLibrary,
    CollegeSettings,
    OrdersPane,
    OrderSearchPane,
    CreateOrderPane,
    TransactionList,
    SubscriptionsList,
    AffiliateList,
    LandingPageList,
    StorefrontsList,
    CustomerList,
    ContactList,
    ProductsList,
    CouponsList,
    SalesRepresentativeList,
    DemoPane,
    ProxyPane,
    MetricsDashboard,
    EventLog,
    ToolsPane,
  },

  data() {
    return {
      currentComponent: null,
      componentHeader: null,
      componentProps: null,
      
      recentExceptions: '--',
      recentExceptionPoll: null,

      loggedInPoll: 0,
      filterLoggedIn: 0,
      openSideTray: true,
      numUsersLoggedIn: 0,
      dailyLogins: [],
    }
  },

  computed: {

    currentCourse() {
      return this.$store.state.CurrentCourse;
    },

    currentUser() {
      return this.$store.state.CurrentUser;
    },

  },

  async created() {
    this.setupPipes()
    if( this.hasAccess( 'site_admin:events:view' ) ) this.startExceptionPoll()
    if( this.hasAccess( 'site_admin:users:view:statistics' ) ) this.startLoggedInPoll()
  },

  mounted() { this.$store.state.pipes.send( 'teleport', { mode: 'dashboard', header: 'Dashboard' } ) },


  destroyed() {
    if( this.recentExceptionPoll ) clearTimeout(this.recentExceptionPoll)
    if( this.loggedInPoll ) clearTimeout(this.loggedInPoll);
  },



  methods: {

    hasAccess( privilege ) { return this.$store.state.userRoles.hasAccess( privilege ) },


    // Listen for "teleport" events (NOT Vue's "teleport") -- jump to a different page in the app, potentially with custom props and header
    setupPipes() {
      this.$store.state.pipes.listen( 'teleport', ( { mode, props, header } ) => {
        this.selectMode( mode, props, header )
      })
    },


    async startExceptionPoll() {
      const poll = async () => {
        this.recentExceptions = await EventMetricsAPI.getRecentExceptionCount()
        this.recentExceptionPoll = setTimeout(poll, 60000)
      }
      this.recentExceptionPoll = setTimeout(poll, 0)
    },

    async startLoggedInPoll() {
      const poll = async () => {
        this.numUsersLoggedIn = await UserAPI.getNumUsersLoggedIn() || 0;
        this.dailyLogins = await UserAPI.getDailyUniqueUsers()
        this.loggedInPoll = setTimeout(poll, 60000);
      };

      this.loggedInPoll = setTimeout(poll, 0);
    },

    showSignedInUsers() {
      this.currentComponent = UsersList;
      this.componentHeader = 'Signed-in Users';
      this.componentProps = {
        Deletable: true,
        showLoggedIn: ++this.filterLoggedIn,
      };

      this.$refs.sideTray.mode = ''

    },



    selectMode( mode, props = null, header = null ) {
      this.componentProps = props
      
      switch (mode) {
        case 'dashboard':
          this.currentComponent = SiteDashboard;
          this.componentHeader = header || 'Dashboard';
          break;
        case 'courses':
          this.currentComponent = CoursesList;
          this.componentHeader = header || 'Courses';
          break;
        case 'users':
          this.currentComponent = UsersList;
          this.componentHeader = header || 'Users';
          this.componentProps = {
            Deletable: true,
            showLoggedIn: 0,
          };
          break;
        case 'users.logins':
          this.currentComponent = LoginList;
          this.componentHeader = header || 'Login Attempts';
          break;
        case 'users.sessions':
          this.currentComponent = UserSessionList;
          this.componentHeader = header || 'Study Sessions';
          break;
        case 'users.usertime':
          this.currentComponent = UserTimeList;
          this.componentHeader = header || 'User Time in Program';
          break;
        case 'signedInList':
          this.showSignedInUsers();
          break;
        case 'licenses':
          this.currentComponent = LicensesList;
          this.componentHeader = header || 'Licenses';
          this.componentProps = {
            StudentStatistics: true
          }
          break;
        case 'stats':
          this.currentComponent = StatisticsDashboard;
          this.componentHeader = header || 'Student Statistics';
          break;
        case 'stats.eknostatbuilder':
          this.currentComponent = EknoStatBuilder;
          this.componentHeader = header || 'E-Score Report Builder';
          break;
        case 'stats.escores':
          this.currentComponent = EScoreList;
          this.componentHeader = header || 'Effort Score Report';
          break;
        case 'library':
          this.currentComponent = MediaLibrary;
          this.componentHeader = header || 'Media Library';
          break;
        case 'messages':
          this.currentComponent = MessageCenter;
          this.componentHeader = header || 'Message Center';
          break;
        case 'messages.messagecenter':
          this.currentComponent = MessageCenter;
          this.componentHeader = header || 'Message Center';
          break;
        case 'messages.compose':
          this.currentComponent = ComposeNotification;
          this.componentHeader = header || 'Compose Message';
          break;
        case 'messages.drafts':
          this.currentComponent = MessageList;
          this.componentHeader = header || 'Drafts';
          this.componentProps = { showDrafts: true };
          break;
        case 'messages.blasts':
          this.currentComponent = MessageList;
          this.componentHeader = header || 'Sent Message Blasts';
          this.componentProps = { showSent: true };
          break;
        case 'messages.sentmessages':
          this.currentComponent = SentMessages;
          this.componentHeader = header || 'Sent Messages';
          break;
        case 'messages.newtemplates':
          this.currentComponent = MessageTemplateList;
          this.componentHeader = header || 'Message Templates';
          break;
        case 'messages.blastlists':
          this.currentComponent = BlastListList;
          this.componentHeader = header || 'Contact Lists';
          break;
        case 'messages.unsubscribes':
          this.currentComponent = UnsubscribeList;
          this.componentHeader = header || 'Unsubscribed Recipients';
          break;
        case 'messages.scheduled':
          this.currentComponent = ScheduledMessageList;
          this.componentHeader = header || 'Scheduled Messages';
          break;
        case 'messages.scheduledRegistration':
          this.currentComponent = ScheduledRegistrationSummaryList;
          this.componentHeader = header || 'Scheduled Registration Emails';
          break;
        case 'messages.templates':
          this.currentComponent = EmailTemplateList;
          this.componentHeader = header || 'Email Templates';
          break;
        case 'modalmessages':
          this.currentComponent = ModalMessageList;
          this.componentHeader = header || 'Modal Messages';
          break;
        case 'modalmessages.views':
          this.currentComponent = ModalMessageViews;
          this.componentHeader = header || 'Modal Message Views';
          break;
        case 'modalmessages.actions':
          this.currentComponent = ModalMessageActions;
          this.componentHeader = header || 'Modal Message Actions';
          break;
        case 'modalmessages.responses':
          this.currentComponent = ModalMessageResponses;
          this.componentHeader = header || 'Modal Message Responses';
          break;
        case 'bugreports':
          this.currentComponent = FeedbackList;
          this.componentHeader = header || 'Bug Reports';
          break;
        case 'reviews':
          this.currentComponent = ReviewsDashboard;
          this.componentHeader = header || 'Customer Reviews';
          break;
        case 'reviews.super':
          this.currentComponent = SuperReviewsDashboard;
          this.componentHeader = header || 'Super Reviews';
          break;
        case 'reviews.educator':
          this.currentComponent = EducatorReviewsDashboard;
          this.componentHeader = header || 'Educator Reviews';
          break;
        case 'orders':
          this.currentComponent = OrderSearchPane;
          this.componentHeader = header || 'All Orders';
          break;
        case 'orders.summary':
          this.currentComponent = OrdersPane;
          this.componentHeader = header || 'Order Summary';
          this.componentProps = { mode: 'summary' };
          break;
        case 'orders.monthly':
          this.currentComponent = OrdersPane;
          this.componentHeader = header || 'Orders By Month';
          this.componentProps = { mode: 'monthly' };
          break;
        case 'orders.create':
          this.currentComponent = CreateOrderPane;
          this.componentHeader = header || 'Create Invoice';
          break;
        case 'orders.transactions':
          this.currentComponent = TransactionList;
          this.componentHeader = header || 'Transactions';
          break;
        case 'orders.subscriptions':
          this.currentComponent = SubscriptionsList;
          this.componentHeader = header || 'Subscriptions';
          break;
        case 'affiliates':
          this.currentComponent = AffiliateList;
          this.componentHeader = header || 'Affiliates';
          break;
        case 'affiliates.landingpages':
          this.currentComponent = LandingPageList;
          this.componentHeader = header || 'Landing Pages';
          break;
        case 'affiliates.storefronts':
          this.currentComponent = StorefrontsList;
          this.componentHeader = header || 'Storefronts';
          break;
        case 'customers':
          this.currentComponent = CustomerList;
          this.componentHeader = header || 'Customers';
          break;
        case 'contacts':
          this.currentComponent = ContactList;
          this.componentHeader = header || 'Contacts';
          break;
        case 'products':
          this.currentComponent = ProductsList;
          this.componentHeader = header || 'Products List';
          break;
        case 'coupons':
          this.currentComponent = CouponsList;
          this.componentHeader = header || 'Coupon Codes';
          break;
        case 'salesreps':
          this.currentComponent = SalesRepresentativeList;
          this.componentHeader = header || 'Sales Reps';
          break;
        case 'proxy':
          this.currentComponent = ProxyPane;
          this.componentHeader = header || 'Proxy';
          break;
        case 'demos':
          this.currentComponent = DemoPane;
          this.componentHeader = header || "Demo Requests";
          break;
        case 'sys.logs':
          this.currentComponent = EventLog;
          this.componentHeader = header || 'System Events';
          break;
        case 'sys.systemStatus':
          this.currentComponent = MetricsDashboard;
          this.componentHeader = header || 'System Status';
          break;
        case 'sys.tools':
          this.currentComponent = ToolsPane;
          this.componentHeader = header || 'System Tools';
          break;
        case 'sys.screens':
          this.currentComponent = ScreensList;
          this.componentHeader = header || 'Login Screens';
          break;
        case 'sys.quotes':
          this.currentComponent = QuotesList;
          this.componentHeader = header || 'Quotes';
          break;
        case 'sys.colleges':
          this.currentComponent = CollegeSettings;
          this.componentHeader = header || 'College Settings';
          break;
        case 'sys.roles':
          this.currentComponent = RolesList;
          this.componentHeader = header || 'Roles';
          break;
        case 'sys.features':
          this.currentComponent = FeaturesList;
          this.componentHeader = header || 'Features';
          break;
      }
    }

  }



}
</script>

<style scoped>
#Page {
  flex-grow: 1;
  position: relative;
  max-height: 100vh;
  box-shadow: 0 0 1em 0 rgba( 0,15,50, 0.25 );
}
#Header {
  margin: 0;
  padding: 0.5em 0.5em 0.5em 1em;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* flex-grow: 1;
  min-width: 100%; */
}
#Header h1 {
  margin: 0;
}
#Body {
  padding: 0rem 1rem;
  overflow: auto;
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 55px);
  box-sizing: border-box;
}
/* #Body::-webkit-scrollbar {
  display: none;
} */

.home {
  height: 100vh;
  max-height: 100vh;
  overflow: auto;
}

</style>
