<template>
<section class="flex-column flex-align-center flex-gap-1 mx-1">

	<!-- E-Mail -->
	<div v-if="message.deliveryMethod == 'email'" class="emailMessage">

		<div v-if="message.from" class="mt-1 flex-row flex-gap-05">
			<b>From:</b>
			<MessageRecipient :recipient="message.from" />
		</div>

		<div v-if="message.replyTo && message.replyTo.length" class="mt-1 flex-row flex-align-center flex-gap-05">
			<div class="bold">Reply To:</div>
			<div style="max-height: 5em; overflow: auto;"><MessageRecipient v-for="rt in message.replyTo" :key="rt.address + rt.name" :recipient="rt" /></div>
		</div>

		<div v-if="message.to && message.to.length" class="mt-1 flex-row flex-align-center flex-gap-05">
			<div class="bold">To:</div>
			<div style="max-height: 5em; overflow: auto;">
				<div v-for="to in message.to" :key="to.address" class="flex-row flex-align-center flex-gap-1">
					<MessageRecipient :recipient="to" />
					<div class="tag statusTag font-size-0-8" :class="{ 'goodStatus' : to.status == 'delivered' }">{{ to.status || 'delivery status unknown' }}</div>
					<div v-if="to.complaint" class="tag complaintTag font-size-0-8">complaint: {{ to.complaint }}</div>
					<div v-if="to.opens && to.opens.length > 0" class="icon-folder-open color-green" @mouseenter="t.s($event)" @mouseleave="t.h($event)" :tooltip="`Opened ${to.opens.length} times`" />
					<div v-else-if="to.status == 'delivered'" class="icon-folder color-orange-75" @mouseenter="t.s($event)" @mouseleave="t.h($event)" tooltip="Message is un-opened" />
				</div>
			</div>
		</div>

		<div v-if="message.cc && message.cc.length" class="mt-1 flex-row flex-align-center flex-gap-05">
			<div class="bold">cc:</div>
			<div style="max-height: 5em; overflow: auto;">
				<div v-for="cc in message.cc" :key="cc.address" class="flex-row flex-align-center flex-gap-1">
					<MessageRecipient :recipient="cc" />
					<div class="tag statusTag font-size-0-8" :class="{ 'goodStatus' : cc.status == 'delivered' }">{{ cc.status || 'delivery status unknown' }}</div>
					<div v-if="cc.complaint" class="tag complaintTag font-size-0-8">complaint: {{ cc.complaint }}</div>
					<div v-if="cc.opens && cc.opens.length > 0" class="icon-folder-open color-green" @mouseenter="t.s($event)" @mouseleave="t.h($event)" :tooltip="`Opened ${cc.opens.length} times`" />
					<div v-else-if="cc.status == 'delivered'" class="icon-folder color-orange-75" @mouseenter="t.s($event)" @mouseleave="t.h($event)" tooltip="Message is un-opened" />
				</div>
			</div>
		</div>

		<div v-if="message.bcc && message.bcc.length" class="mt-1 flex-row flex-align-center flex-gap-05">
			<div class="bold">bcc:</div>
			<div style="max-height: 5em; overflow: auto;">
				<div v-for="bcc in message.bcc" :key="bcc.address" class="flex-row flex-align-center flex-gap-1">
					<MessageRecipient :recipient="bcc" />
					<div class="tag statusTag font-size-0-8" :class="{ 'goodStatus' : bcc.status == 'delivered' }">{{ bcc.status || 'delivery status unknown' }}</div>
					<div v-if="bcc.complaint" class="tag complaintTag font-size-0-8">complaint: {{ bcc.complaint }}</div>
					<div v-if="bcc.opens && bcc.opens.length > 0" class="icon-folder-open color-green" @mouseenter="t.s($event)" @mouseleave="t.h($event)" :tooltip="`Opened ${bcc.opens.length} times`" />
					<div v-else-if="bcc.status == 'delivered'" class="icon-folder color-orange-75" @mouseenter="t.s($event)" @mouseleave="t.h($event)" tooltip="Message is un-opened" />
				</div>
			</div>
		</div>

		<div class="bold my-2 border-bottom">Subject: {{ message.subject }}</div>
		<div class="mb-1" v-if="message.body" v-html="message.body" />
		<div class="mb-1" v-else-if="message.altBody" style="max-height: 70vh; overflow: auto;">{{ message.altBody }}</div>

		<div class="mt-2 border-top pt-05 color-gray-50">
			Message Information:
			<div>AWS Message ID: {{ message.messageTransmissionID }}</div>
			<div>Delivery Time: {{ message.deliveryTime }}</div>
		</div>
	</div>


	<!-- SMS -->
	<div v-else-if="message.deliveryMethod == 'sms'" class="border round-1 pa-1">
		<div class="previewTitle">
			To: {{ message.toName || '' }}
			<span v-if="message.toName && message.toPhoneNumber">(</span>
			<span v-if="message.toPhoneNumber">{{ message.toPhoneNumber }}</span>
			<span v-if="message.toName && message.toPhoneNumber">)</span>
		</div>
		<div class="smsBubble mx-1 mb-1" v-html="message.message.replace(/(?:\r\n|\r|\n)/g, '<br>')" />
	</div>


	<!-- In-App -->
	<section v-else-if="message.deliveryMethod == 'app'">
		<div class="border round-1 pa-1 mb-1">
			<div class="previewTitle">
				To:
				<span v-if="message.to.name">{{ message.to.name }}</span>
				<span v-if="message.to.name && (message.to.address || message.to.userID)"> - </span>
				<span v-if="message.to.address">@{{ message.to.address }}</span>
				<span v-if="!message.to.address && message.to.userID">#{{ message.to.userID }}</span>
			</div>
			<div class="smsBubble mx-1 mb-1" v-html="message.message.replace(/(?:\r\n|\r|\n)/g, '<br>')" />
		</div>

		<div class="tag">Sent: {{ message.sendTime.toLocaleString() }}</div>
		<div v-if="message.readTime" class="tag statusTag goodStatus">Opened {{ message.readTime.toLocaleString() }}</div>
		<div v-else class="tag statusTag">Not Opened</div>

	</section>

</section>
</template>



<script>
import MessageRecipient from './MessageRecipient.vue'
import Tooltips from '@/libraries/Tooltips/Tooltips.js'

export default {
	name: 'MessageDetails',


	components: {
		MessageRecipient,
	},


	props: {
		message: {
			type: Object,
			default: null
		},
	},



	computed: {
		t() { return Tooltips },
	},

	
}
</script>



<style scoped>
.previewTitle {
  background-color: var(--pp10-purple);
  color: white;
  font-weight: bold;
  padding: 0.5em 1em;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
}

.emailMessage {
  min-width: min( 600px, 90vw );
  max-width: 1000px;
}

.tag {
	padding: 0.25em 0.5em;
	border-radius: 1em;
}

.statusTag {
	background-color: var(--pp10-red-95);
	color: var(--pp10-red);
	border: 1px solid var(--pp10-red);
}
.statusTag.goodStatus {
	background-color: var(--pp10-green-95);
	color: var(--pp10-green);
	border: 1px solid var(--pp10-green);
}

.complaintTag {
	background-color: var(--pp10-orange-95);
	color: var(--pp10-orange-35);
	border: 1px solid var(--pp10-orange);
}

.smsBubble {
  background: var(--ekno-blue);
  color: white;
  padding: 0.5em;
  margin: 3em 1em 1em 3.5em;
  border-radius: 0.5em;
  min-width: 200px;
  max-width: 250px;
}
</style>