export default class User {
  constructor() {
    this.userID = "";
    this.customerID = null
    this.createdByUserID = null
    this.emailAddress = "";
    this.fullName = "";
    this.firstName = "";
    this.lastName = "";
    this.alias = "";
    this.graduationYear = null;
    this.phone = null;
    this.changePassword = false;
    this.accountLocked = false;
    this.emailVerified = false;
    this.verificationEmailSent = false;
    this.registerEmailTimestamp = null;
    this.newPassword = null; // used only if the admin sets the user's password.
    this.roles = [];

    // used only for users list
    this.hasPassword = false;
    this.isLoggedIn = false;
    this.isBetaTester = false;
    this.timeInProgram = 0;
    this.isMasterAccount = false;
    this.creationDate = null;
    this.lastProgramLogin = null;
    this.numLicenses = 0;

    // Other read-only
    this.registrationNonce = null
    this.passwordResetNonce = null
  }


  // MessageCenter (Users, POCs, etc. implement this)
  get primaryContact() { return this.emailAddress || null }
	get stringValue() { return `${ this.firstName } ${ this.lastName } <${ this.primaryContact }>`}


  get registerEmailDate() {
    if(this.registerEmailTimestamp === null) return null
    let date = new Date(this.registerEmailTimestamp*1000)
    return date
  }



  isSame(user) {
    if (this.userID == user.userID &&
        this.emailAddress == user.emailAddress &&
        this.fullName == user.fullName &&
        this.firstName == user.firstName &&
        this.lastName == user.lastName &&
        this.alias == user.alias &&
        this.graduationYear == user.graduationYear &&
        this.phone == user.phone &&
        this.customerID == user.customerID &&
        ((this.changePassword && user.changePassword) ||
        (!this.changePassword && !user.changePassword)) &&
        ((this.accountLocked && user.accountLocked) ||
        (!this.accountLocked && !user.accountLocked)) &&
        ((this.emailVerified && user.emailVerified) ||
        (!this.emailVerified && !user.emailVerified)) &&
        this.verificationEmailSent === user.verificationEmailSent &&
        this.registerEmailTimestamp === user.registerEmailTimestamp
    ) return true;
    else return false;
  }

  static import(json) {
    let user = new User();
    user.userID = json.user_id || json.userID;
    user.customerID = json.customerID || null
    user.createdByUserID = json.createdByUserID || null

    user.emailAddress = json.email_address || json.emailAddress || json.email;
    user.fullName = json.full_name !== undefined ? json.full_name : json.fullName;
    user.firstName = json.first_name !== undefined ? json.first_name : json.firstName;
    user.lastName = json.last_name !== undefined ? json.last_name : json.lastName;
    user.alias = json.alias;
    user.graduationYear = json.graduationYear || null;
    user.phone = json.phone || null;
    user.changePassword = json.change_password;
    user.accountLocked = json.account_locked;
    user.emailVerified = json.email_verified;
    user.verificationEmailSent = json.verification_email_sent;
    user.registerEmailTimestamp = json.registerEmailTimestamp || null;
    user.hasPassword = json.has_password;
    user.isLoggedIn = json.is_logged_in;
    user.isBetaTester = json.is_beta_tester;
    user.timeInProgram = parseInt(json.time_in_program) || 0;
    user.isMasterAccount = json.is_master_account;
    user.lastProgramLogin = json.lastProgramLogin ? new Date(json.lastProgramLogin * 1000) : null;
    user.creationDate = new Date(json.creation_date * 1000);
    user.numLicenses = json.num_licenses;

    return user;
  }

  export() {
    let user = {};
    user.user_id = this.userID;
    user.customerID = this.customerID
    user.createdByUserID = this.createdByUserID

    user.email_address = this.emailAddress;
    user.full_name = this.fullName;
    user.first_name = this.firstName;
    user.last_name = this.lastName;
    user.alias = this.alias;
    user.graduationYear = this.graduationYear;
    user.phone = this.phone;
    user.change_password = this.changePassword;
    user.account_locked = this.accountLocked;
    user.email_verified = this.emailVerified;
    user.verification_email_sent = this.verificationEmailSent;
    user.registerEmailTimestamp = this.registerEmailTimestamp;

    return user;
  }
}
